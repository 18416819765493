import React from "react";
import {
    Table, Button, Popover, Input, Modal,
    Divider, Radio, Popconfirm, Select,
} from 'antd';
import {DeleteOutlined, LoadingOutlined,} from '@ant-design/icons';
import {UpdateTableLine} from "./table-upate-line";
import {MyModal} from "./modal";
import {UpdateTablePart} from "./table-upate-part";
import {CreateTablePart} from "./table-create-part";
import {CreateTable} from "./table-create";
import {CreateCopyTable} from "./table-create-copy";
import {CreateBatchTable} from "./table-create-batch";
import {DetailLine} from "./table-detail-line";
import {Download} from "./head/DownLoad";
import {Help} from "./head/help/help";
import {Filter} from "./head/filters/filters";
import {DynamicFilter} from "./head/dynamic_filter_type/dynamic_filter";
import {DynamicFilters} from "./head/dynamic_filters/dynamic_filters";
import {Count} from "./head/count/count";
import {funcMapping} from "../../setting";
import file from './help.png';

import {connect} from 'react-redux';
import {tool} from "../tools/tools";
import {getColumnRender} from "./columns/main";
import {
    RedoOutlined,
} from '@ant-design/icons';

import './power-table.css';
import {Jump} from "./head/jump/jump";
import {isMobile} from "../assistant/album/album_tool";

let lastSign = 0;


//智能表格 only触发器 'react'
class PowerTable extends React.Component {
    constructor(props) {
        super(props);
        this.addSubColumns = this.addSubColumns.bind(this);
        this.addTrigger = this.addTrigger.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getDataTable = this.getDataTable.bind(this);
        this.getTableHead = this.getTableHead.bind(this);
        this.getSubTableReact = this.getSubTableReact.bind(this);
        this.getPagination = this.getPagination.bind(this);
        this.getSearchBorder = this.getSearchBorder.bind(this);
        this.getFatherId = this.getFatherId.bind(this);
    }

    // 生命周期: 组件生成
    componentDidMount() {
        this.updateData();
    }

    // 生命周期: state更新
    shouldComponentUpdate(state, _1, _2) {
        this.updateData();
        return state;
    }

    // 更新表格数据
    updateData() {
        setTimeout(() => {
            //公共空间路径及空间
            let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);


            // 私有空间路径
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
            let spacePrivate = tool.getStateSpace(spacePrivatePath);

            // 加载状态
            let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
            let stateTableOnload = tool.getStateSpace(stateTableOnloadPath);

            if (!stateTableOnload) return null;

            let page, page_size, search, filter;
            let isObject = Object.prototype.toString.call(stateTableOnload) === '[object Object]';
            if (stateTableOnload === 'page') { // 携带原来的page参数
                page = tool.getAttribute(spacePrivate, 'page');
                page_size = tool.getAttribute(spacePrivate, 'page_size');

                // 携带过滤器的值
                search = tool.getAttribute(spacePrivate, 'searchWord');

            } else if (stateTableOnload === 'search') {// 搜索式更新
                page = 1;
                page_size = tool.getAttribute(spacePrivate, 'page_size');
                search = tool.getAttribute(spacePrivate, 'searchWord');
            } else if (stateTableOnload === 'dynamic') {// 动态过滤器更新
                page = 1;
                page_size = tool.getAttribute(spacePrivate, 'page_size');
            } else if (stateTableOnload === 'progress') {// 进度条更新
                page = tool.getAttribute(spacePrivate, 'page');
                page_size = tool.getAttribute(spacePrivate, 'page_size');
                search = tool.getAttribute(spacePrivate, 'searchWord');
            } else if (stateTableOnload === 'filter') {// 过滤器更新
                page = 1;
            } else if (isObject) { // 参数附加在stateTableOnload里
                page = tool.getAttribute(stateTableOnload, 'page');
                page_size = tool.getAttribute(stateTableOnload, 'page_size');
            }

            // 获取请求路径
            let requestUrlPath = spacePublicPath.concat(['path']);
            let requestUrl = tool.getStateSpace(requestUrlPath);

            // 获取上级表的id father_id
            let urlParam = {};
            let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
            if (fatherRecord) urlParam['father_id'] = fatherRecord.id;

            // 全部附加过滤器参数
            let spacePublic = tool.getStateSpace(spacePublicPath);
            filter = tool.getAttribute(spacePrivate, 'filterValue');
            if (!filter) {
                filter = tool.getAttribute(spacePublic, ['filters', 'default',])
            }

            if (page) urlParam['page'] = page;
            if (page_size) urlParam['page_size'] = page_size;
            if (search) urlParam['search'] = search;
            if (filter) {
                // let params = tool.getAttribute(spacePrivate, 'params', {});
                urlParam['filter'] = filter;
                // for (const param in params) {
                //     urlParam[param] = params[param];
                // }
            }

            // 携带多过滤器的值
            let params = tool.getAttribute(spacePrivate, 'params', {});
            for (const param in params) {
                urlParam[param] = params[param];
            }

            let sign = 'filter__';
            const signLength = sign.length;
            for (const key in spacePublic) {
                if (!key.startsWith(sign)) continue;
                let valueDefault = tool.getAttribute(spacePublic, [key, 'default']);
                const filterKey = key.substring(signLength);
                if (!urlParam[filterKey]) urlParam[filterKey] = valueDefault;
            }


            // 携带动态过滤器的值
            let searchKey = tool.getAttribute(spacePrivate, 'searchKey',);
            if (searchKey) {
                let searchKey = tool.getAttribute(spacePrivate, 'searchKey');
                search = tool.getAttribute(spacePrivate, 'dynamicSearchWord');
                urlParam[searchKey] = search;
            }

            // 最后一次请求的刷新请求总是覆盖旧的刷新请求
            let mySign = lastSign + 1;
            lastSign = mySign;

            let url = tool.get_url(requestUrl, false, urlParam ? urlParam : {},);
            let requestParam = {
                url: url,
                success: responseData => {
                    // 在返回数据的这段时间,当前请求是不是依然是最后一次,如果不是就没必要刷新了
                    if (mySign < lastSign) return null;

                    let textSuccess = '数据已加载';

                    if (stateTableOnload === 'page') textSuccess = '数据已刷新';
                    let isObject = Object.prototype.toString.call(stateTableOnload) === '[object Object]';
                    if (isObject) textSuccess = `加载于第${page}页`;

                    // 最后一次更新的时间戳
                    let timestamp = new Date().getTime();

                    let spacePrivate = tool.getStateSpace(spacePrivatePath);
                    spacePrivate = Object.assign({}, spacePrivate, {
                        responseData: responseData,
                        stateTableOnload: false,
                        searchWaiting: false,
                        lastUpdateTime: timestamp,
                    });
                    if (page) spacePrivate['page'] = page;
                    if (page_size) spacePrivate['page_size'] = page_size;
                    if (stateTableOnload === 'progress') spacePrivate['selfProgress'] = false;
                    spacePrivate['selfProgress'] = false;
                    tool.setStateSpace(spacePrivatePath, spacePrivate);
                    if (stateTableOnload !== 'progress') {
                        tool.show_success(textSuccess);
                    }

                    // 右键使用提醒
                    let messageRight = tool.getStateSpace(['messageRightIsShow', 'messageRight']);
                    if (!messageRight) {
                        tool.show_info('温馨提醒: 在数据行上使用右键可以唤出操作菜单!');
                        tool.setStateSpace(['messageRightIsShow', 'messageRight'], true);
                    }

                },
                error: error => {
                    tool.show_error(error);
                    let spacePrivate = tool.getStateSpace(spacePrivatePath);
                    spacePrivate = Object.assign({}, spacePrivate, {
                        stateTableOnload: false,
                    });
                    tool.setStateSpace(spacePrivatePath, spacePrivate);
                }
            };


            // 上次更新时间
            spacePrivate = tool.getStateSpace(spacePrivatePath);
            let lastUpdateTime = tool.getAttribute(spacePrivate, 'lastUpdateTime');
            let nowTime = new Date().getTime();
            let stateUpdate = true;
            if (lastUpdateTime) {
                let diff = nowTime - lastUpdateTime;
                if (diff < 1000) stateUpdate = false;
            }
            if (stateUpdate) {
                tool.get(requestParam);
            } else {
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: false,
                });
                tool.setStateSpace(spacePrivatePath, spacePrivate);
            }
        }, 100);
    }

    // 得到列信息
    getColumns() {
        //公共空间路径及空间
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        let columnsInfo = tool.getAttribute(spacePublic, 'columns');

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumns = tool.getAttribute(filterNow, 'columns');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumns) {
            columnsInfo = filterColumns;
        }

        let columns;

        if (!columnsInfo) {
            let tableData = tool.getAttribute(spacePrivate, ['responseData', 'results',],);
            if (!tableData || tableData.length < 1) return [];
            columns = [];
            let oneLine = tableData[0];

            for (let fieldName in oneLine) {
                if (!oneLine.hasOwnProperty(fieldName)) continue;
                let column = {
                    title: fieldName,
                    dataIndex: fieldName,
                    key: fieldName,
                    // ellipsis: true,
                    render: (value, record, index) => {
                        return getColumnRender({
                            value: value,
                            record: record,
                            index: index,
                            spacePublicPath: spacePublicPath,
                            spacePublic: spacePublic,
                            columnsInfo: null,
                            columnInfo: null,
                            columnKey: fieldName,
                        });
                    },
                };
                columns.push(column);
            }
        } else {
            columns = [];

            for (let columnKey in columnsInfo) {
                if (!columnsInfo.hasOwnProperty(columnKey)) continue;
                let columnInfo = columnsInfo[columnKey];

                // 是否隐藏
                let hidden = tool.getAttribute(columnInfo, 'hidden', false,);
                if (hidden) continue;

                let ellipsis = tool.getAttribute(columnInfo, 'ellipsis', true);
                let column = {
                    title: columnInfo.title,
                    rate: columnInfo.rate,
                    dataIndex: columnInfo.dataIndex ? columnInfo.dataIndex : columnKey,
                    key: columnKey,
                    ellipsis: ellipsis,
                    render: (value, record, index) => {
                        return getColumnRender({
                            value: value,
                            record: record,
                            index: index,
                            spacePublicPath: spacePublicPath,
                            spacePublic: spacePublic,
                            spacePrivatePath: spacePrivatePath,
                            columnsInfo: columnsInfo,
                            columnInfo: columnsInfo[columnKey],
                            columnKey: columnKey,
                        });
                    },
                };

                columns.push(column);
            }
        }
        columns = this.addSubColumns(columns);
        columns = this.addTrigger(columns);


        // 计算总宽度
        let totalWidth = 0;
        for (let column of columns) {
            // 隐藏的跳过
            let hidden = tool.getAttribute(column, 'hidden', false,);
            if (hidden) continue;

            let rate = parseFloat(tool.getAttribute(column, 'rate', 1,));
            totalWidth += rate;
        }

        // 计算宽度
        for (let column of columns) {
            // 隐藏的跳过
            let hidden = tool.getAttribute(column, 'hidden', false,);
            if (hidden) continue;
            let rate = parseFloat(tool.getAttribute(column, 'rate', 1,));
            column['width'] = `${rate / totalWidth * 100}%`;
        }

        return columns;
    }

    // 增加子表列
    addSubColumns(columns) {
        //公共空间路径及空间
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);

        //得到此列的内容
        let getSubColumn = columnName => {
            //点击列中的 展开(或关闭)子表按钮
            let onClickSubColumn = (recordId, columnName) => {

                //私有空间 展开的行有哪些
                let expandedRowKeysPath = spacePrivatePath.concat(['expandedRowKeys']);
                let expandedRowKeys = tool.getStateSpace(expandedRowKeysPath);
                if (!expandedRowKeys) expandedRowKeys = [];

                // 行空间--选中的列
                let spaceRowKey = `spaceRow__${recordId}`;
                let spaceRowPath = spacePrivatePath.concat([spaceRowKey,]);
                let nowSelectedColumnPath = spaceRowPath.concat(['nowSelectedColumn']);
                let nowSelectedColumn = tool.getStateSpace(nowSelectedColumnPath);

                //决定是展开还是关闭
                let state = (nowSelectedColumn === columnName) ? !tool.isInArray(expandedRowKeys, recordId) : true;

                //得到更新后的expandedRowKeys
                let newExpandedRowKeys = [];
                for (let expandedRowKey of expandedRowKeys) if (expandedRowKey !== recordId) newExpandedRowKeys.push(expandedRowKey);
                if (state) {
                    newExpandedRowKeys.push(recordId);

                    nowSelectedColumn = state ? columnName : null;
                    if (nowSelectedColumn) {
                        //子表的公共空间路径
                        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
                        let spaceSubPublicPath = spacePublicPath.concat(nowSelectedColumn);

                        //子表的私有空间路径
                        let spaceSubPrivateKey = `spacePrivate__${recordId}`;
                        let spaceSubPrivatePath = spaceSubPublicPath.concat(spaceSubPrivateKey);

                        //更新子表的加载状态为true  stateTableOnload
                        let spaceSubPrivate = tool.getStateSpace(spaceSubPrivatePath);

                        //子表的私有空间
                        spaceSubPrivate = Object.assign({}, spaceSubPrivate, {
                            stateTableOnload: true,
                        });

                        //更新子表的私有空间
                        tool.setStateSpace(spaceSubPrivatePath, spaceSubPrivate);
                    }

                }

                // 获取新的行空间
                let spaceRow = tool.getStateSpace(spaceRowPath);

                spaceRow = Object.assign({}, spaceRow, {
                    nowSelectedColumn: nowSelectedColumn,
                });

                //得到新的私有空间 更新行空间
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    expandedRowKeys: newExpandedRowKeys,
                    onlyRecordId: recordId, // 通知子表渲染函数仅渲染本行的子表格就行
                });
                spacePrivate[spaceRowKey] = spaceRow;

                tool.setStateSpace(spacePrivatePath, spacePrivate);
            };

            let columnTitle = tool.getAttribute(spacePublic, [columnName, 'columnTitle']);
            let columnRate = tool.getAttribute(spacePublic, [columnName, 'rate']);
            let columnHidden = tool.getAttribute(spacePublic, [columnName, 'hidden']);

            return {
                title: columnTitle,
                key: columnName,
                rate: columnRate,
                hidden: columnHidden,
                render: record => {

                    // 渲染is_show
                    let is_show = tool.getAttribute(spacePublic, [columnName, 'is_show',]);
                    if (is_show) {
                        let param = {
                            record: record,
                        };
                        let content = is_show(param);
                        if (content) return content;
                    }

                    let recordId = record.id;

                    //私有空间 展开的行有哪些
                    let expandedRowKeysPath = spacePrivatePath.concat(['expandedRowKeys']);
                    let expandedRowKeys = tool.getStateSpace(expandedRowKeysPath);
                    if (!expandedRowKeys) expandedRowKeys = [];

                    // 行空间--选中的列
                    let spaceRowKey = `spaceRow__${recordId}`;
                    let nowSelectedColumnPath = spacePrivatePath.concat([spaceRowKey, 'nowSelectedColumn']);
                    let nowSelectedColumn = tool.getStateSpace(nowSelectedColumnPath);


                    //是展开还是关闭
                    let text = '展开';
                    let type = null;
                    let state = (nowSelectedColumn === columnName) ? !tool.isInArray(expandedRowKeys, recordId) : true;
                    if (!state) {
                        text = '关闭';
                        type = 'primary';
                    }
                    return <Button type={type} onClick={() => onClickSubColumn(recordId, columnName)}>{text}</Button>;
                },
            }
        };

        columns = Object.assign([], columns);

        for (let key in spacePublic) {

            if (!spacePublic.hasOwnProperty(key)) continue;
            if (key.slice(0, 5) === 'sub__') columns.push(getSubColumn(key));
        }

        return columns;
    }

    // 增加触发器
    addTrigger(columns) {
        //公共空间路径及空间
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //得到此列的内容
        let getTriggerColumn = (columnName, func) => {
            const triggerPublicSpacePath = spacePublicPath.concat([columnName,]);

            let columnTitle = tool.getAttribute(spacePublic, [columnName, 'columnTitle']);
            let textButton = tool.getAttribute(spacePublic, [columnName, 'textButton'], '触发器',);
            let buttonType = tool.getAttribute(spacePublic, [columnName, 'buttonType'], 'link',);
            let rate = tool.getAttribute(spacePublic, [columnName, 'rate'],);
            let hidden = tool.getAttribute(spacePublic, [columnName, 'hidden'],);


            return {
                title: columnTitle,
                key: columnName,
                rate: rate,
                hidden: hidden,
                render: (text, record) => {
                    // 私有属性
                    const triggerPrivateSpacePath = triggerPublicSpacePath.concat([`private_${record.id}`]);
                    textButton = tool.getStateSpace(triggerPrivateSpacePath.concat(['textButton',]), textButton,);
                    buttonType = tool.getStateSpace(triggerPrivateSpacePath.concat(['buttonType',]), buttonType,);
                    const disabled = tool.getStateSpace(triggerPrivateSpacePath.concat(['disabled',]), false,);
                    // 触发器参数
                    const param = {
                        record: record,
                        columnName: columnName,
                        triggerPublicSpacePath: triggerPublicSpacePath,
                        spacePrivatePath: spacePrivatePath,
                        triggerPrivateSpacePath: triggerPrivateSpacePath,
                    };

                    return <Button
                        type={buttonType}
                        onClick={() => func(param)}
                        disabled={disabled}
                    >
                        {textButton}
                    </Button>;
                },
            }
        };

        columns = Object.assign([], columns);

        for (let key in spacePublic) {
            if (!spacePublic.hasOwnProperty(key)) continue;

            if (key.slice(0, 9) === 'trigger__') {
                let func = tool.getAttribute(spacePublic, [key, 'func']);
                if (typeof func === 'string') func = funcMapping[func];
                if (!func) continue;
                columns.push(getTriggerColumn(key, func));
            }
        }

        return columns;
    }

    // 得到表格数据
    getDataTable() {
        //私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        return tool.getAttribute(spacePrivate, ['responseData', 'results',],);
    }

    // 得到子表的React
    getSubTableReact(record) {
        // 当前行空间 选中的列
        let recordId = tool.getAttribute(record, 'id');
        let spaceRowKey = `spaceRow__${recordId}`;
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let onlyRecordId = tool.getAttribute(spacePrivate, 'onlyRecordId',);
        let spaceRowPath = spacePrivatePath.concat([spaceRowKey]);
        let nowSelectedColumnPath = spaceRowPath.concat(['nowSelectedColumn']);
        let nowSelectedColumn = tool.getStateSpace(nowSelectedColumnPath);
        if (!nowSelectedColumn) return '空的';

        //子表的公共空间路径
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spaceSubPublicPath = spacePublicPath.concat(nowSelectedColumn);

        //子表的私有空间路径
        let spaceSubPrivateKey = `spacePrivate__${recordId}`;
        let spaceSubPrivatePath = spaceSubPublicPath.concat(spaceSubPrivateKey);

        // 是否自定义
        let spaceSubPublic = tool.getStateSpace(spaceSubPublicPath);
        let react = tool.getAttribute(spaceSubPublic, 'react');
        if (typeof react === "string") react = funcMapping[react];
        if (typeof react === "function") {
            let param = {
                spacePublicPath: spaceSubPublicPath,
                spacePrivatePath: spaceSubPrivatePath,
                spacePrivateKey: spaceSubPrivateKey,
                fatherRecord: record,
            };
            return react(param);
        }


        return <PowerTable param={{
            spacePublicPath: spaceSubPublicPath,
            spacePrivatePath: spaceSubPrivatePath,
            spacePrivateKey: spaceSubPrivateKey,
            fatherRecord: record,
            fatherLastClickRecordId: onlyRecordId, // 最后点击的行
        }}/>
    }

    // 右键点击行的触发
    getOnRow() {
        let onContextMenu = (event, record, _) => {
            // 本表的公共空间路径
            let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
            let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
            // 本表的私有空间路径
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);

            // 本行的行空间路径
            let recordId = record.id;
            let spaceRowKey = `spaceRow__${recordId}`;
            let spaceRowPath = spacePrivatePath.concat([spaceRowKey,]);

            // 获取新的右键空间
            let rightClickPath = ['rightClick'];
            let rightClickSpace = tool.getStateSpace(rightClickPath);
            rightClickSpace = Object.assign({}, rightClickSpace, {
                x: event.clientX,
                y: event.clientY,
                record: record,
                fatherRecord: fatherRecord,
                show: true,
                // sign: true,
                spacePublicPath: spacePublicPath,
                spacePrivatePath: spacePrivatePath,
                spaceRowPath: spaceRowPath,
            });
            tool.setStateSpace(rightClickPath, rightClickSpace);
        };

        return (record, index) => {
            return {
                // onClick: event => {},
                // onDoubleClick: event => {},
                onContextMenu: event => onContextMenu(event, record, index,),
                // onMouseEnter: () => onMouseEnter(record, index,),
                // onMouseLeave: () => onMouseLeave(),
            };
        }
    }

    // 创建按钮
    getCreateButtonReact() {
        // 当前行空间 选中的列
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spaceRowPath = tool.getAttribute(this, ['props', 'param', 'spaceRowPath',]);
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);

        // 是否显示
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        if (!columnsCreate) return null;


        let textPath = spacePublicPath.concat(['text__create_button']);
        let text = tool.getStateSpace(textPath);
        if (!text) text = '创建数据';

        let onClickCreate = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'Create',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let useReact = () => {
            // let react__ = tool.getAttribute(columnsCreate, 'react__');
        };

        let useMenu = () => {
            let menu__ = tool.getAttribute(columnsCreate, 'menu__');
            let onClickMenu = tool.getStateSpace(['setting', 'onClickMenu',]);
            if (!onClickMenu) return null;
            onClickMenu(menu__, true);
        };

        let click = onClickCreate;
        let react__ = tool.getAttribute(columnsCreate, 'react__');
        if (react__) click = useReact;
        let menu__ = tool.getAttribute(columnsCreate, 'menu__');
        if (menu__) click = useMenu;

        return <Button onClick={click} type={'primary'}>{text}</Button>
    }

    // 批量创建按钮
    getCreateBatchButtonReact() {


        // 当前行空间 选中的列
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);

        let columnsCreateBatchPath = spacePublicPath.concat(['columnsCreateBatch']);
        let columnsCreateBatch = tool.getStateSpace(columnsCreateBatchPath);
        if (!columnsCreateBatch) return null;
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        if (!columnsCreate) return null;


        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spaceRowPath = tool.getAttribute(this, ['props', 'param', 'spaceRowPath',]);
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);


        let textPath = spacePublicPath.concat(['text__create_batch-button']);
        let text = tool.getStateSpace(textPath);
        if (!text) text = '批量创建数据';
        let onClickCreate = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'CreateBatch',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };
        return <Button onClick={() => onClickCreate()} type={'primary'}>{text}</Button>
    }

    // 刷新按钮
    getUpdateDataButton() {
        // let text = '刷新数据';
        let onUpdateData = () => {
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
            let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
            tool.setStateSpace(stateTableOnloadPath, 'page');
        };
        // return <Button onClick={() => onUpdateData()} type={'primary'}>{text}</Button>
        return <RedoOutlined onClick={() => onUpdateData()}/>;
    }

    // 搜索框
    getSearchBorder() {

        // 得到公共空间路径
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        let searchHiddenPath = spacePublicPath.concat(['text__hidden']);
        let searchHidden = tool.getStateSpace(searchHiddenPath, false,);
        if (searchHidden) return null;


        // 搜索提示文字为
        let textSearchHelp = tool.getAttribute(spacePublic, 'text__search_help');
        // if (!textSearchHelp) textSearchHelp = '输入关键字进行搜索';
        if (!textSearchHelp) return null;

        // 得到私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 是否搜索等待中
        let searchWaiting = tool.getAttribute(spacePrivate, 'searchWaiting');

        // 关键词
        let searchWord = tool.getAttribute(spacePrivate, 'searchWord');

        // 点击搜索的触发
        let onclick = () => {
            let spacePrivate = tool.getStateSpace(spacePrivatePath);
            spacePrivate = Object.assign({}, spacePrivate, {
                stateTableOnload: 'search',
                searchWaiting: true,
            });
            tool.setStateSpace(spacePrivatePath, spacePrivate);
        };

        return (<div style={{display: 'inline-block', verticalAlign: 'super', marginLeft: '5px',}}>
            <Popover content={textSearchHelp}>
                <Input
                    addonBefore={"输入关键字"}
                    addonAfter={<div
                        style={{width: '40px', height: '100%',}}
                        onClick={onclick}
                    >
                        {searchWaiting ? <LoadingOutlined/> : '搜索'}
                    </div>}
                    style={{width: '250px',}}
                    onChange={
                        event => {
                            let value = event.target.value;
                            let searchWordPath = spacePrivatePath.concat(['searchWord']);
                            tool.setStateSpace(searchWordPath, value);
                        }
                    }
                    placeholder={textSearchHelp}
                    value={searchWord}
                />
            </Popover>
        </div>);
    }

    getFatherId() {
        let fatherId;
        fatherId = tool.getAttribute(this, ['props', 'param', 'fatherRecord', 'id',]);
        return fatherId;
    }

    //  开发模式 表格行 编辑搜索框
    getReactSearchEditButton() {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let searchPath = spacePublicPath.concat(['text__search_help']);
        let searchHiddenPath = spacePublicPath.concat(['text__hidden']);
        let searchHelp = tool.getStateSpace(searchPath);
        let searchHidden = tool.getStateSpace(searchHiddenPath, false,);
        if (!searchHidden) searchHidden = false;

        let reacts = [];
        reacts.push(<div>
            <Divider>自定义搜索框</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            tool.setStateSpace(searchHiddenPath, event.target.value,);
                        }}
                        value={searchHidden}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 修改帮助信息*/}
                <tr>
                    <th>
                        修改帮助信息:
                    </th>
                    <th>

                        <input
                            onBlur={event => {
                                tool.setStateSpace(searchPath, event.target.value,);
                            }}
                            defaultValue={searchHelp}
                        />
                    </th>
                </tr>
                </thead>
            </table>
        </div>)

        // 搜索框
        let isSetSearchShowPath = spacePrivatePath.concat(['isSetSearchShow']);
        let isSetSearchShow = tool.getStateSpace(isSetSearchShowPath, false);

        let content = <Modal
            visible={isSetSearchShow}
            open={isSetSearchShow}
            onCancel={() => tool.setStateSpace(isSetSearchShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        let style = {
            display: "inline-block",
        };
        let setLine;
        setLine = <div style={style}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetSearchShowPath, true,)}
            >搜索框</Button>
        </div>;
        return setLine;
    }

    //  开发模式 表格行 编辑过滤器
    getReactFilterEditButton() {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 编辑框
        let isSetFilterShowPath = spacePrivatePath.concat(['isSetFilterShow']);
        let isSetFilterShow = tool.getStateSpace(isSetFilterShowPath, false);

        // 过滤器列表
        let reacts = [];
        let sign = 'filter__';
        const signLength = sign.length;
        for (const key in spacePublic) {
            if (!key.startsWith(sign)) continue;
            let filterInfoPath = spacePublicPath.concat([key]);
            let filterInfo = tool.getAttribute(spacePublic, key);
            // let type = tool.getAttribute(filterInfo, 'type');
            const filterKey = key.substring(signLength);
            let keyPath = spacePublicPath.concat([key]);

            // 是否隐藏
            let hidden = tool.getAttribute(filterInfo, 'hidden', false,);

            // 方向
            let type = tool.getAttribute(filterInfo, 'type', false,);


            // 选项
            let reactChoices = [];
            const choices = tool.getAttribute(filterInfo, 'choices', []);
            const defaultValue = tool.getAttribute(filterInfo, 'default',);
            for (const choiceKey in choices) {
                reactChoices.push(<div
                    key={choiceKey}
                    style={{marginBottom: '10px',}}>

                    {/*值*/}
                    值:<input
                    onBlur={event => {
                        let value = event.target.value;
                        let newChoices = {};
                        for (const _choiceKey in choices) {
                            let useKey = _choiceKey;
                            if (_choiceKey === choiceKey) useKey = value;
                            newChoices[useKey] = choices[_choiceKey];
                        }
                        let newFilterInfo = Object.assign(filterInfo, {choices: newChoices,});
                        tool.setStateSpace(filterInfoPath, newFilterInfo);
                    }}
                    style={{width: '50px',}}
                    defaultValue={choiceKey}/>
                    {'    '}

                    {/*读*/}
                    读:<input
                    onBlur={event => {
                        let value = event.target.value;
                        let newChoices = {};
                        for (const _choiceKey in choices) {
                            let _value = choices[_choiceKey];
                            if (_choiceKey === choiceKey) _value['text'] = value;
                            newChoices[_choiceKey] = _value;
                        }
                        let newFilterInfo = Object.assign(filterInfo, {choices: newChoices,});
                        tool.setStateSpace(filterInfoPath, newFilterInfo);
                    }}
                    style={{width: '50px',}}
                    defaultValue={choices[choiceKey]['text']}/>

                    {/*默认值*/}
                    <Radio.Group
                        onChange={event => {
                            let value = event.target.value;
                            let newFilterInfo = Object.assign(filterInfo, {default: value,});
                            tool.setStateSpace(filterInfoPath, newFilterInfo);
                        }}
                        value={defaultValue}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={choiceKey}>设为缺省值</Radio>
                    </Radio.Group>

                    {/*删除选项*/}
                    <Popconfirm
                        title="删除这个选项吗?"
                        description="删除这个选项吗?"
                        onConfirm={() => {
                            let newChoices = {};
                            for (const _choiceKey in choices) {
                                if (_choiceKey === choiceKey) continue;
                                newChoices[_choiceKey] = choices[_choiceKey];
                            }
                            let newFilterInfo = Object.assign(filterInfo, {choices: newChoices,});
                            tool.setStateSpace(filterInfoPath, newFilterInfo);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm>
                </div>)
            }

            // 增加选项
            reactChoices.push(<div
                style={{}}
                key={'create'}>
                <Button
                    onClick={() => {
                        let newChoices = {};
                        for (const _choiceKey in choices) newChoices[_choiceKey] = choices[_choiceKey];
                        newChoices['新选项:修改值'] = {text: '新选项:修改读'}

                        let newFilterInfo = Object.assign(filterInfo, {choices: newChoices,});
                        tool.setStateSpace(filterInfoPath, newFilterInfo);
                    }}
                    style={{
                        width: '100%',
                    }}>增加选项</Button>
            </div>)

            reacts.push(<div>
                <Divider>参数:{filterKey}</Divider>
                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*第1行 是否显示*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(keyPath, Object.assign(filterInfo, {hidden: event.target.value,}));
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第2行 方向*/}
                    <tr>
                        <th>方向:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(keyPath, Object.assign(filterInfo, {type: event.target.value,}));
                            }}
                            value={type}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={'row'}>横向</Radio>
                            <Radio value={'col'}>纵向</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第3行 修改参数名*/}
                    <tr>
                        <th>
                            修改参数名:
                        </th>
                        <th>

                            <input
                                onBlur={event => {
                                    let newSpacePublic = {};
                                    let spacePublic = tool.getStateSpace(spacePublicPath);
                                    for (const _key in spacePublic) {
                                        let useKey = _key;
                                        if (_key === key) useKey = `${sign}${event.target.value}`;
                                        newSpacePublic[useKey] = spacePublic[_key];
                                    }
                                    tool.setStateSpace(spacePublicPath, newSpacePublic);
                                }}
                                defaultValue={filterKey}
                            />
                        </th>
                    </tr>

                    {/*第4行 修改选项*/}
                    <tr>
                        <th>
                            选项:
                        </th>
                        <th>
                            {reactChoices}
                        </th>
                    </tr>


                    </thead>
                </table>

            </div>);
        }

        // 增加过滤器
        reacts.push(<div key={'createFilter'}>
            <Divider/>
            <Button
                onClick={() => {
                    let newData = {};
                    let random = Math.random() * 1000000
                    random = Math.floor(random);
                    newData[`filter__my_filter_${random}`] = {
                        choices: {
                            1: {
                                text: '选项1',
                            },
                            2: {
                                text: '选项2',
                            }
                        },
                        default: 1,
                        type: 'col',
                    };

                    let newSpacePublic = Object.assign(spacePublic, newData);
                    tool.setStateSpace(spacePublicPath, newSpacePublic);
                }}
                style={{
                    width: '100%',
                }}>增加过滤器</Button>
        </div>)


        let content = <Modal
            // closeIcon={' '}
            visible={isSetFilterShow}
            open={isSetFilterShow}
            onCancel={() => tool.setStateSpace(isSetFilterShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        let style = {
            display: "inline-block",
        };
        let setLine;
        setLine = <div style={style}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetFilterShowPath, true,)}
            >过滤器</Button>
        </div>;
        return setLine;
    }

    // 字段附加
    getReactFieldAppend(keyPath) {
        let react = null;
        let typePath = keyPath.concat(['type',]);
        let type = tool.getStateSpace(typePath, 'char');

        // 布尔类型
        if (type === 'bool') {
            let textBoolPath = keyPath.concat(['textBool',]);
            let textBool = tool.getStateSpace(textBoolPath, ['真值', '假值',]);
            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>

                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            真值映射:
                        </th>
                        <th>
                            <div>
                                <input
                                    style={{width: '100px',}}
                                    onChange={event => {
                                        tool.setStateSpace(textBoolPath, [
                                            event.target.value,
                                            textBool[1],
                                        ]);
                                    }}
                                    value={textBool[0]}
                                />
                            </div>
                        </th>
                    </tr>

                    {/*第2行 假值映射*/}
                    <tr>
                        <th>
                            假值映射:
                        </th>
                        <th>
                            <div>
                                <input
                                    style={{width: '100px',}}
                                    onChange={event => {
                                        tool.setStateSpace(textBoolPath, [
                                            textBool[0],
                                            event.target.value,
                                        ]);
                                    }}
                                    value={textBool[1]}
                                />
                            </div>
                        </th>
                    </tr>

                    </thead>
                </table>
            </div>
        }

        // 映射类型
        if (type === 'map') {

            let mapPath = keyPath.concat(['map',]);
            let map = tool.getStateSpace(mapPath, {});

            let lines = [];

            // 列表
            for (const mapKey in map) {
                let value = map[mapKey];
                lines.push(
                    <tr key={mapKey}>
                        {/*key*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let value = event.target.value;
                                    let newMap = {};
                                    for (const _key in map) {
                                        let userKey = _key;
                                        if (_key === mapKey) userKey = value;
                                        newMap[userKey] = map[_key];
                                    }
                                    tool.setStateSpace(mapPath, newMap);
                                }}
                                style={{width: '40px',}}
                                defaultValue={mapKey}
                            />
                        </th>

                        {/*箭头*/}
                        <th>
                            --->
                        </th>

                        {/*value*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let value = event.target.value;
                                    let newMap = {};
                                    for (const _key in map) {
                                        let userValue = map[_key];
                                        if (_key === mapKey) userValue = value;
                                        newMap[_key] = userValue;
                                    }
                                    tool.setStateSpace(mapPath, newMap);
                                }}
                                style={{width: '80px',}}
                                defaultValue={value}
                            />
                        </th>

                        {/*删除*/}
                        <th>
                            <Popconfirm
                                title="确定删除吗?"
                                description="确定删除吗?"
                                onConfirm={() => {
                                    let newMap = {};
                                    for (const _key in map) {
                                        if (_key !== mapKey) newMap[_key] = map[_key];
                                    }
                                    tool.setStateSpace(mapPath, newMap);
                                }}
                                onCancel={() => {
                                }}
                                okText="删除"
                                cancelText="不要"
                            >
                                <DeleteOutlined style={{color: 'red', marginLeft: '5px',}}/>
                            </Popconfirm>

                        </th>
                    </tr>
                );

            }

            // 增加映射
            lines.push(<div key={'create'} style={{width: '180px',}}>
                <button
                    onClick={() => {
                        let newMap = Object.assign({}, map,);
                        let random = Math.random() * 100000;
                        random = Math.floor(random);
                        newMap[`key_${random}`] = '新映射';
                        tool.setStateSpace(mapPath, newMap);
                    }}
                    style={{width: '100%',}}
                >增加映射
                </button>
            </div>)


            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                    // border:'red 1px solid',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>

                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            {/*真值映射:*/}
                        </th>
                        <th>
                            {lines}
                        </th>
                    </tr>


                    </thead>
                </table>
            </div>
        }

        // 渲染类型
        if (type === 'render') {

            let renderPath = keyPath.concat(['render',]);
            let defaultFunc = '(value, param)=>{return "返回值";}';
            let render = tool.getStateSpace(renderPath, defaultFunc);

            let errorInfo = '';
            try {
                // eslint-disable-next-line no-eval
                let func = eval(`(${render})`);
                if (typeof func !== 'function') {
                    errorInfo = '字符串无法识别为函数';
                }
            } catch (error) {
                errorInfo = '语法错误';
            }

            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>
                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            <Input.TextArea
                                value={render}
                                onChange={event => {
                                    let value = event.target.value;
                                    tool.setStateSpace(renderPath, value);
                                }}
                                row={10}
                            />
                            <button
                                style={{width: '100%',}}
                                onClick={() => {
                                    let string = '(value, param)=>{return "返回值(演示)";}';
                                    let func;
                                    try {
                                        // eslint-disable-next-line no-eval
                                        func = eval(`(${render})`);
                                    } catch (error) {
                                    }
                                    if (func) tool.setStateSpace(renderPath, func);
                                    if (!func) tool.setStateSpace(renderPath, string);
                                }}
                            >快速输入
                            </button>
                            <div style={{color: 'red',}}>
                                {errorInfo}
                            </div>
                            <div style={{color: 'grey', fontSize: '80%',}}>
                                本功能存在<span style={{color: 'red',}}>注入攻击</span>的风险, 请在开发完成后及时关闭开发模式.<br/>
                                提示:在IDE中写好代码然后直接粘贴.<br/>
                            </div>
                        </th>
                    </tr>


                    </thead>
                </table>
            </div>
        }

        return react;
    }

    //  开发模式 表格行 编辑列
    getReactColEditButton() {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 编辑框
        let isSetColShowPath = spacePrivatePath.concat(['isSetColShow']);
        let isSetColShow = tool.getStateSpace(isSetColShowPath, false);

        // 编辑组件
        let reactColumns = [];

        // 字段位置移动
        const movePos = (columnKey, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let columns = tool.getStateSpace(columnsPath);
            let columnsArray = Object.keys(columns);
            let index = columnsArray.indexOf(columnKey);

            let indexTarget = index + direction;
            if (indexTarget < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexTarget > columnsArray.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }


            let temp = columnsArray[indexTarget];
            columnsArray[indexTarget] = columnsArray[index];
            columnsArray[index] = temp;
            let newColumns = {};
            for (const _columnKey of columnsArray) newColumns[_columnKey] = columns[_columnKey];
            tool.setStateSpace(columnsPath, newColumns);
        };

        // 子列位置移动
        const movePosSub = (key, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let subArray = [];
            let spacePublic = tool.getStateSpace(spacePublicPath);
            const sign = 'sub__';
            for (let _key in spacePublic) {
                if (!spacePublic.hasOwnProperty(_key)) continue;
                if (_key.slice(0, sign.length) !== sign) continue;
                subArray.push(_key);
            }
            let index = subArray.indexOf(key);

            let indexSub = index + direction;
            if (indexSub < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexSub > subArray.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }
            let temp = subArray[indexSub];
            subArray[indexSub] = subArray[index];
            subArray[index] = temp;

            let newSpacePublic = {};
            for (const _key of subArray) newSpacePublic[_key] = spacePublic[_key];

            for (let _key in spacePublic) {
                if (!spacePublic.hasOwnProperty(_key)) continue;
                if (_key.slice(0, sign.length) === sign) continue;
                newSpacePublic[_key] = spacePublic[_key];
            }
            tool.setStateSpace(spacePublicPath, newSpacePublic);
        };

        // 触发器位置移动
        const movePosTrigger = (key, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let triggerArray = [];
            let spacePublic = tool.getStateSpace(spacePublicPath);
            for (let _key in spacePublic) {
                if (!spacePublic.hasOwnProperty(_key)) continue;
                if (_key.slice(0, 9) !== 'trigger__') continue;
                triggerArray.push(_key);
            }
            let index = triggerArray.indexOf(key);

            let indexTarget = index + direction;
            if (indexTarget < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexTarget > triggerArray.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }
            let temp = triggerArray[indexTarget];
            triggerArray[indexTarget] = triggerArray[index];
            triggerArray[index] = temp;

            let newSpacePublic = {};
            for (const _key of triggerArray) newSpacePublic[_key] = spacePublic[_key];

            for (let _key in spacePublic) {
                if (!spacePublic.hasOwnProperty(_key)) continue;
                if (_key.slice(0, 9) === 'trigger__') continue;
                newSpacePublic[_key] = spacePublic[_key];
            }
            tool.setStateSpace(spacePublicPath, newSpacePublic);
        };

        // 字段
        let columnsPath = spacePublicPath.concat(['columns']);
        let columnsInfo = tool.getAttribute(spacePublic, 'columns');
        for (const columnKey in columnsInfo) {
            let column = columnsInfo[columnKey];
            let keyPath = spacePublicPath.concat(['columns', columnKey]);

            let typePath = keyPath.concat(['type',]);
            let type = tool.getStateSpace(typePath, 'char');


            // 是否隐藏
            let hidden = tool.getAttribute(column, 'hidden', false,);

            // 宽度
            let rate = tool.getAttribute(column, 'rate', 1,);

            // 字段附加
            let reactFieldAppend = this.getReactFieldAppend(keyPath);
            let border = reactFieldAppend ? '#BEBEBE 1px solid' : '';

            reactColumns.push(<div
                key={columnKey}
            >

                {/*分割线*/}
                <Divider>字段名:{column.title}</Divider>

                {/*删除字段*/}
                <div
                    style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个字段吗?"
                        description="删除这个字段吗?"
                        onConfirm={() => {
                            let newColumns = {};
                            let columns = tool.getStateSpace(columnsPath);
                            for (const _columnKey in columns) {
                                if (_columnKey === columnKey) continue;
                                newColumns[_columnKey] = columns[_columnKey];
                            }

                            tool.setStateSpace(columnsPath, newColumns);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm>
                </div>

                {/*列表*/}
                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*第1行 是否显示*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(keyPath, Object.assign(column, {hidden: event.target.value,}));
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第2行 修改字段名*/}
                    <tr>
                        <th>
                            修改字段名:
                        </th>
                        <th>

                            <input
                                value={column.title}
                                onChange={event => {
                                    tool.setStateSpace(keyPath, Object.assign(column, {title: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第3行 字段类型*/}
                    <tr style={{border: border,}}>
                        <th>
                            字段类型:
                        </th>
                        <th>
                            <div>
                                <Select
                                    style={{width: '100%',}}
                                    // defaultValue={type}
                                    value={type}
                                    onChange={value => {
                                        tool.setStateSpace(keyPath, Object.assign(column, {type: value,}));
                                    }}
                                    options={[
                                        {
                                            value: 'emptyArray',
                                            label: '下划线转空类型',
                                        },
                                        {
                                            value: 'helpArray',
                                            label: 'help数组',
                                        },
                                        {
                                            value: 'bool',
                                            label: '布尔类型',
                                        },
                                        {
                                            value: 'char',
                                            label: '字符串',
                                        },
                                        {
                                            value: 'money',
                                            label: '人民币',
                                        },
                                        {
                                            value: 'chinaTime',
                                            label: '时间类型(中国)',
                                        },
                                        {
                                            value: 'yearTime',
                                            label: '时间类型(年份)',
                                        },
                                        {
                                            value: 'render',
                                            label: '渲染类型',
                                        },
                                        {
                                            value: 'map',
                                            label: '映射类型',
                                        },
                                        {
                                            value: 'imgPath',
                                            label: '图片类型(路径)',
                                        },
                                        {
                                            value: 'imgShow',
                                            label: '图片类型(展示)',
                                        },
                                        {
                                            value: 'imgHead',
                                            label: '图片类型(头像)',
                                        },
                                        {
                                            value: 'video',
                                            label: '视频类型',
                                        },
                                        {
                                            value: 'label',
                                            label: '标签类型',
                                        },
                                        {
                                            value: 'file',
                                            label: '文件类型',
                                        },
                                        {
                                            value: 'progress',
                                            label: '进度条类型',
                                        },
                                        {
                                            value: 'link',
                                            label: '链接类型',
                                        },
                                        {
                                            value: 'utcTime',
                                            label: '时间类型(UTC)',
                                        },
                                        {
                                            value: 'userInfo',
                                            label: '用户信息类型',
                                        },
                                    ]}
                                />
                                <div style={{width: '100%',}}>
                                    {reactFieldAppend}
                                </div>

                            </div>
                        </th>
                    </tr>

                    {/*第4行 映射字段*/}
                    <tr>
                        <th>
                            修改映射字段:
                        </th>
                        <th>

                            <input
                                defaultValue={columnKey}
                                // value={columnKey}
                                onBlur={event => {
                                    let newColumns = {};
                                    for (const _columnKey in columnsInfo) {
                                        let useKey = _columnKey;
                                        if (_columnKey === columnKey) useKey = event.target.value;
                                        newColumns[useKey] = columnsInfo[_columnKey];
                                    }
                                    tool.setStateSpace(columnsPath, newColumns);
                                }}
                            />
                        </th>
                    </tr>

                    {/*第5行 修改列宽*/}
                    <tr>
                        <th>
                            列宽:
                        </th>
                        <th>
                            <input
                                value={rate}
                                onChange={event => {
                                    let value = event.target.value;
                                    tool.setStateSpace(keyPath, Object.assign(column, {rate: value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第6行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => movePos(columnKey, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => movePos(columnKey, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>

                    </thead>
                </table>


            </div>);
        }

        // 增加字段
        reactColumns.push(<div key={'create_column'} style={{width: '100%',}}>
            <Divider/>
            <Button
                onClick={() => {
                    let columns = tool.getStateSpace(columnsPath);
                    let newData = {};
                    let random = Math.random() * 100000;
                    random = Math.floor(random);
                    newData[`field_${random}`] = {title: '新字段',};
                    let newColumns = Object.assign(columns, newData);
                    tool.setStateSpace(columnsPath, newColumns);
                }}
                style={{width: '100%',}}>增加字段</Button>
            <Divider/>
        </div>);

        // 子列
        for (let key in spacePublic) {
            if (!spacePublic.hasOwnProperty(key)) continue;
            if (key.slice(0, 5) !== 'sub__') continue;

            let column = spacePublic[key];
            let keyPath = spacePublicPath.concat([key,]);

            // 是否隐藏
            let hidden = tool.getAttribute(column, 'hidden', false,);

            // 宽度
            let rate = tool.getAttribute(column, 'rate', 1,);

            reactColumns.push(<div
                key={key}
                style={{
                    backgroundColor: 'lightcyan',
                }}
            >

                {/*分割线*/}
                <Divider>字段名:{column.columnTitle}</Divider>

                {/*删除字段*/}
                <div
                    style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个字段吗?"
                        description="删除这个字段吗?"
                        onConfirm={() => {
                            let newSpacePublic = {};
                            for (let _key in spacePublic) {
                                if (_key === key) continue;
                                newSpacePublic[_key] = spacePublic[_key];
                            }
                            tool.setStateSpace(spacePublicPath, newSpacePublic);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm></div>


                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*    /!*第1行 是否显示*!/*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(keyPath, Object.assign(column, {hidden: event.target.value,}));
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*    /!*第2行 修改字段名*!/*/}
                    <tr>
                        <th>
                            修改字段名:
                        </th>
                        <th>

                            <input
                                value={column.columnTitle}
                                onChange={event => {
                                    tool.setStateSpace(keyPath, Object.assign(column, {columnTitle: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*    /!*第3行 修改列宽*!/*/}
                    <tr>
                        <th>
                            列宽:
                        </th>
                        <th>
                            <input
                                value={rate}
                                onChange={event => {
                                    let value = event.target.value;
                                    tool.setStateSpace(keyPath, Object.assign(column, {rate: value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第4行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => movePosSub(key, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => movePosSub(key, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>


                    </thead>
                </table>


            </div>);
        }

        // 触发器
        for (let key in spacePublic) {
            if (!spacePublic.hasOwnProperty(key)) continue;
            if (key.slice(0, 9) !== 'trigger__') continue;

            let column = spacePublic[key];
            let keyPath = spacePublicPath.concat([key,]);

            // 是否隐藏
            let hidden = tool.getAttribute(column, 'hidden', false,);

            // 宽度
            let rate = tool.getAttribute(column, 'rate', 1,);

            reactColumns.push(<div
                key={key}
                style={{
                    backgroundColor: 'lightpink',
                }}
            >

                {/*分割线*/}
                <Divider>字段名:{column.columnTitle}</Divider>

                {/*删除触发器*/}
                <div
                    style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个触发器吗?"
                        description="删除这个触发器吗?"
                        onConfirm={() => {
                            let newSpacePublic = {};
                            for (let _key in spacePublic) {
                                if (_key === key) continue;
                                newSpacePublic[_key] = spacePublic[_key];
                            }
                            tool.setStateSpace(spacePublicPath, newSpacePublic);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm></div>

                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*    /!*第1行 是否显示*!/*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(keyPath, Object.assign(column, {hidden: event.target.value,}));
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*    /!*第2行 修改字段名*!/*/}
                    <tr>
                        <th>
                            修改字段名:
                        </th>
                        <th>

                            <input
                                value={column.columnTitle}
                                onChange={event => {
                                    tool.setStateSpace(keyPath, Object.assign(column, {columnTitle: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*    /!*第3行 修改列宽*!/*/}
                    <tr>
                        <th>
                            列宽:
                        </th>
                        <th>
                            <input
                                value={rate}
                                onChange={event => {
                                    let value = event.target.value;
                                    tool.setStateSpace(keyPath, Object.assign(column, {rate: value,}));
                                }}
                            />
                        </th>
                    </tr>


                    {/*第4行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => movePosTrigger(key, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => movePosTrigger(key, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>


                    </thead>
                </table>


            </div>);
        }

        let content = <Modal
            // closeIcon={' '}
            visible={isSetColShow}
            open={isSetColShow}
            onCancel={() => tool.setStateSpace(isSetColShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reactColumns}
        </Modal>


        let style = {
            display: "inline-block",
        };
        let setLine;
        setLine = <div style={style}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetColShowPath, true,)}
            >字段</Button>
        </div>;
        return setLine;
    }

    //  开发模式 表格行 编辑帮助
    getReactHelpEditButton() {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let helpTextPath = spacePublicPath.concat(['help', 'text',]);
        let helpHiddenPath = spacePublicPath.concat(['help', 'hidden',]);
        let helpHidden = tool.getStateSpace(helpHiddenPath);
        if (!helpHidden) helpHidden = false;
        let helpText = tool.getStateSpace(helpTextPath);

        let reacts = [];
        reacts.push(<div key={'xx'}>
            <Divider>自定义帮助</Divider>

            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            tool.setStateSpace(helpHiddenPath, event.target.value,);
                        }}
                        value={helpHidden}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 修改帮助内容*/}
                <tr>
                    <th>
                        修改帮助内容:
                    </th>
                    <th>
                        <Input.TextArea
                            rows={8}
                            onBlur={event => {
                                tool.setStateSpace(helpTextPath, event.target.value,);
                            }}
                            defaultValue={helpText}
                        />
                        <div
                            style={{
                                fontSize: '90%',
                                color: 'lightgrey',
                                // textAlign: 'center',
                            }}
                        >接受一串html字符串.提示:可在IDE编辑完直接粘贴到这里.
                        </div>
                    </th>
                </tr>

                </thead>
            </table>

        </div>)

        // 帮助编辑
        let isSetHelpShowPath = spacePrivatePath.concat(['isSetHelpShow']);
        let isSetHelpShow = tool.getStateSpace(isSetHelpShowPath, false);

        let content = <Modal
            visible={isSetHelpShow}
            open={isSetHelpShow}
            onCancel={() => tool.setStateSpace(isSetHelpShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        let style = {
            display: "inline-block",
        };
        let setLine;
        setLine = <div style={style}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetHelpShowPath, true,)}
            >帮助</Button>
        </div>;
        return setLine;
    }

    //  开发模式 表格行 编辑下载
    getReactDownloadEditButton() {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);

        // 是否隐藏
        let downloadHiddenPath = spacePublicPath.concat(['disableDownload']);
        let downloadHidden = tool.getStateSpace(downloadHiddenPath);
        if (!downloadHidden) downloadHidden = false;


        let reacts = [];
        reacts.push(<div key={'xx'}>
            <Divider>下载功能</Divider>

            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            tool.setStateSpace(downloadHiddenPath, event.target.value,);
                        }}
                        value={downloadHidden}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>
                </thead>
            </table>

        </div>)

        // 下载编辑
        let isSetDownloadShowPath = spacePrivatePath.concat(['isSetDownloadShow']);
        let isSetDownloadShow = tool.getStateSpace(isSetDownloadShowPath, false);

        let content = <Modal
            visible={isSetDownloadShow}
            open={isSetDownloadShow}
            onCancel={() => tool.setStateSpace(isSetDownloadShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        let style = {
            display: "inline-block",
        };
        let setLine;
        setLine = <div style={style}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetDownloadShowPath, true,)}
            >下载</Button>
        </div>;
        return setLine;
    }

    // 开发模式 表格行
    getSetLine() {
        let open_edit = tool.getStateSpace(['open_edit']);
        if (!open_edit) return null;

        let setLine;
        let style = {
            backgroundColor: "#fffffff",
            borderRadius: "5px",
            overflow: "auto",
            border: "rgba(130, 50, 21, 0.16) solid 1px",
        };
        setLine = <div style={style}>
            {/*列*/}
            {this.getReactColEditButton()}

            {/*搜索框*/}
            {this.getReactSearchEditButton()}

            {/*过滤器*/}
            {this.getReactFilterEditButton()}

            {/*帮助*/}
            {this.getReactHelpEditButton()}

            {/*下载*/}
            {this.getReactDownloadEditButton()}
        </div>;
        return setLine;
    }

    // 表格头
    getTableHead() {
        // 创建按钮
        let createButtonReact = this.getCreateButtonReact();
        // 批量创建按钮
        let createBatchButtonReact = this.getCreateBatchButtonReact();
        // 刷新数据按钮
        let updateDataButton = this.getUpdateDataButton();
        // 搜索框
        let searchBorder = this.getSearchBorder();

        return (<div>
            <div className={'powerTableHead'}>
                <div className={'powerTableHeadButton'}>{createButtonReact}</div>
                <div className={'powerTableHeadButton'}>{createBatchButtonReact}</div>
                <div className={'powerTableHeadButton'}>{updateDataButton}</div>
                <div className={'powerTableHeadButton'}>{searchBorder}</div>

                {/*下载组件*/}
                <div className={'powerTableHeadButton'}><Download param={
                    this.props.param
                }/></div>

                {/*过滤器*/}
                <div className={'powerTableHeadButton'}><Filter param={
                    this.props.param
                }/></div>

                {/*动态过滤器*/}
                <div className={'powerTableHeadButton'}><DynamicFilter
                    param={this.props.param}
                    getFatherId={this.getFatherId}
                /></div>

                {/*多动态过滤器*/}
                <div className={'powerTableHeadButton'}><DynamicFilters
                    param={this.props.param}
                    getFatherId={this.getFatherId}
                /></div>

                {/*跳转器*/}
                <div className={'powerTableHeadButton'}><Jump param={
                    this.props.param
                }/></div>

                {/*帮助*/}
                <div className={'powerTableHeadButton'} style={{
                    float: 'right',
                    marginRight: '20px',
                }}><Help param={
                    this.props.param
                }/></div>

                {/*数量*/}
                <div className={'powerTableHeadButton'} style={{
                    float: 'right',
                    marginRight: '20px',
                }}><Count param={
                    this.props.param
                }/></div>

            </div>
            {this.getSetLine()}
        </div>);
    }

    // 分页器
    getPagination() {
        // 私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);

        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let count = tool.getAttribute(spacePrivate, ['responseData', 'count']);
        if (!count) count = 0;
        let page = tool.getAttribute(spacePrivate, ['page']);
        if (!page) page = 1;

        return {
            // defaultCurrent: this.state.defaultCurrent,
            // defaultCurrent: this.props.list.page,
            total: count,
            defaultPageSize: 10,
            onChange: (page, page_size) => {
                let onloadInfo = {
                    page: page,
                    page_size: page_size,
                };
                tool.setStateSpace(stateTableOnloadPath, onloadInfo);
            },
            current: page,
        };

    };

    // 表格尾巴
    getFooter() {
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
        if (fatherRecord) return null;
        return this.getTableHead();
        // let buttonCreate = this.getCreateButtonReact();
        // let buttonCreateBatch = this.getCreateBatchButtonReact();
        // return (<div>
        //     <div className={'powerTableFooterButton'}>{buttonCreate}</div>
        //     <div className={'powerTableFooterButton'}>{buttonCreateBatch}</div>
        // </div>);
    }


    render() {
        let columns = this.getColumns();
        let tableData = this.getDataTable();

        // 私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
        let stateTableOnload = tool.getStateSpace(stateTableOnloadPath);
        let loading = false;
        if (stateTableOnload === null) loading = false;
        if (stateTableOnload) loading = true;
        if (stateTableOnload === 'progress') loading = false;

        // 获取展开的行 spacePrivate.expandedRowKeys
        let expandedRowKeysPath = spacePrivatePath.concat(['expandedRowKeys']);
        let expandedRowKeys = tool.getStateSpace(expandedRowKeysPath);

        // 鼠标与行交互的相关触发
        let onRow = this.getOnRow();

        // 表格头
        let tableHead = this.getTableHead();

        // 分页器
        let pagination = this.getPagination();

        return (<div className={isMobile ? 'powerTableMobil' : 'powerTable'}>
            <Table
                // scroll={{ x: 1500, y: 300 }}
                loading={loading}//加载中 预留
                // summary={(currentData) =><div>xxx</div>} //总结 预留
                title={(_) => tableHead}//标题 预留
                showHeader={true}
                onRow={onRow}
                // scroll={true}
                // expandedRowClassName={() => 'subTable'}
                dataSource={tableData}
                columns={columns}
                rowKey={'id'}
                expandedRowKeys={expandedRowKeys}
                expandIcon={() => null}
                expandable={{
                    expandedRowRender: record => this.getSubTableReact(record),
                }}
                pagination={pagination}
                footer={() => this.getFooter()}
                // footer={() => tableHead}
            />
        </div>);
    }
}

function mapStateToTable(state) {
    return {
        setting: state.setting,
        rightClick: state.rightClick,
    }
}

PowerTable = connect(mapStateToTable)(PowerTable);


// 右键提醒
class RightMessage extends React.Component {
    constructor(props) {
        super(props);
        this.setClose = this.setClose.bind(this);
    }

    setClose() {
        tool.setStateSpace(['messageRightIsShow',], {
            messageRight: true,
            messageRightIsShow: '1',
        });
    }

    render() {
        let state = true;
        let messageRightIsShow = tool.getStateSpace(['messageRightIsShow', 'messageRightIsShow',]);
        if (messageRightIsShow === '1') state = false;

        return (<div>
            <Modal
                title={<p style={{fontSize: '240%',}}>温馨提醒</p>}
                open={state}
                visible={state}
                okText={<p style={{fontSize: '140%',}}>知道了!</p>}
                cancelText={<p style={{fontSize: '140%',}}>下次不再提醒</p>}
                onOk={() => this.setClose()}
                onCancel={() => {
                    this.setClose();
                    localStorage.setItem('messageRightIsShow', '1');
                }}
                width={isMobile ? '80%' : '40%'}
            >
                表格点击右键可以唤出菜单!
                <img src={file} alt={'图片加载失败:表格点击右键可以唤出菜单.'}/>
            </Modal>
        </div>);
    }
}

function mapStateToMessage(state) {
    return {
        messageRightIsShow: state.messageRightIsShow,
    }
}

RightMessage = connect(mapStateToMessage)(RightMessage);

//根表格
class RootTable extends React.Component {


    render() {
        let spaceMenuPathStore = ['setting', 'selectedMenu', 'selectedSpacePath',];


        let spaceMenuPath = tool.getStateSpace(spaceMenuPathStore);
        let spaceMenu = tool.getStateSpace(spaceMenuPath);
        let react = tool.getAttribute(spaceMenu, 'react');
        if (typeof react === "string") react = funcMapping[react];


        let spacePublicPath = spaceMenuPath.concat(['table']);

        let spacePrivateKey = `spacePrivate__root`;
        let spacePrivatePath = spacePublicPath.concat([spacePrivateKey]);

        if (typeof react === "function") {
            let param = {
                spacePublicPath: spacePublicPath,
                spacePrivatePath: spacePrivatePath,
                spacePrivateKey: spacePrivateKey,
            };
            return react(param);
        }
        if (react) return react;

        return (<div className={'rootTableBack'}>
            <RightMessage/>
            <PowerTable
                param={{
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spacePrivateKey: spacePrivateKey,
                }}
            />
            <DetailLine/>
            <UpdateTableLine/>
            <UpdateTablePart/>
            <CreateTablePart/>
            <CreateTable/>
            <CreateCopyTable/>
            <CreateBatchTable/>
            <MyModal/>
        </div>);
    }
}

function mapStateToRoot(state) {
    return {
        setting: state.setting,
    }
}

RootTable = connect(mapStateToRoot)(RootTable);

export {PowerTable, RootTable,};
