import React, {Component} from 'react';
import {Modal} from "antd";
import Cropper from 'react-cropper';

import "cropperjs/dist/cropper.css";
import './img-head.css'


class CropperComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            src: null,
        }
    }

    componentDidMount() {
        const fileReader = new FileReader();
        fileReader.onload = event => {
            const dataURL = event.target.result
            this.setState({src: dataURL})
        };
        fileReader.readAsDataURL(this.props.file);
    }

    handleSubmit = () => {
        let canvas;
        canvas = this.cropper['getCroppedCanvas']();
        canvas.toBlob(async blob => {
            this.props.onSubmit(blob);
            this.props.onClose()
        })
    }

    render() {
        let ratio = this.props.ratio;
        if (!ratio) ratio = 1;
        ratio = Number(ratio);

        return (<Modal
            width={'40%'}
            title={'图像裁剪器'}
            open={this.props.show}
            visible={this.props.show}
            okText={'点击裁剪'}
            cancelText={'关闭'}
            onOk={this.handleSubmit}
            onCancel={this.props.onClose}
        >
            <div className="cropper-container">
                <Cropper
                    src={this.state.src}
                    ref={cropper => this.cropper = cropper}
                    viewMode={1}
                    zoomable={false}
                    aspectRatio={ratio}
                />
            </div>
        </Modal>)
    }
}

export default CropperComponent;