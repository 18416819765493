import React from "react";
import {Modal, Divider, Button, Radio, Popconfirm, Select, Input} from "antd";

import {tool} from "../tools/tools";
import {connect} from "react-redux";
import {DeleteOutlined, ToolOutlined} from "@ant-design/icons";


//数据详情
class DetailLine extends React.Component {
    constructor(props) {
        super(props);
        this.getFieldReact = this.getFieldReact.bind(this);
        this.getFieldType = this.getFieldType.bind(this);
        this.getContent = this.getContent.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.getColumnsUpdate = this.getColumnsUpdate.bind(this);
    }

    updateData() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Detail') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let detailOnload = tool.getAttribute(paramModal, 'detailOnload');
        let record = tool.getAttribute(paramModal, 'record');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let recordId = record.id;
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let filter = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filter) {
            filter = tool.getAttribute(spacePublic, ['filters', 'default',])
        }

        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);
        if (!detailOnload) return null;
        let startUpdate = () => {

            //请求数据
            let paramUrl = {};
            if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;
            if (filter) paramUrl['filter'] = filter;


            let url = tool.get_url(requestPath, false, paramUrl, recordId);
            let requestParam = {
                url: url,
                success: data => {
                    //更新数据到Modal参数区的特定区域
                    let dataKey = `detail__${recordId}`;
                    let paramModalPath = ['infoModal', 'paramModal',];
                    // paramModal = tool.getStateSpace(paramModalPath);
                    paramModal = Object.assign({}, paramModal, {
                        detailOnload: false,
                    });
                    paramModal[dataKey] = data;
                    tool.setStateSpace(paramModalPath, paramModal);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);

        };
        setTimeout(startUpdate, 100);
    }

    getFieldType(param) {
        let columnKey = param.columnKey;
        let data = param.data ? param.data : {};
        let columnsDetailLine = param.columnsDetailLine;
        let columnInfo = columnsDetailLine[columnKey];

        //字段类型
        let fieldType = columnInfo.type;
        if (fieldType) return fieldType;

        //根据数据进行推定
        if (data) {
            let isHelpArray = () => {
                let isArray = Array.isArray(data);
                if (!isArray) return false;

                if (data.length < 1) return 'emptyArray';

                let first = data[0];
                if (!first) return false;
                if (first.id && first.help) return 'helpArray';

                return false;

            };
            let type = isHelpArray();
            if (type) fieldType = type;
        }

        if (!fieldType) fieldType = 'char';
        return fieldType;
    }

    getFieldReact(param) {
        let columnKey = param.columnKey;

        //字段类型
        let fieldType = this.getFieldType(param);
        let fieldMapping = tool.getStateSpace(['setting', 'fieldDetailMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);
        if (!func) func = tool.getAttribute(fieldMapping, 'char');

        return (
            <div key={columnKey}>
                {func(param)}
                <Divider/>
            </div>
        );
    }

    getColumnsUpdate() {
        let infoModal = tool.getAttribute(this, ['props', 'infoModal',]);

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');

        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        //更新字段
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate',]);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdate) {
            columnsUpdate = filterColumnsUpdate;
        }
        return columnsUpdate;
    }

    // 字段附加
    getReactFieldAppend(columnPath) {
        // return 'xx'
        let react = null;
        let typePath = columnPath.concat(['type',]);
        let type = tool.getStateSpace(typePath, 'char');

        // 布尔类型
        if (type === 'bool') {
            let textBoolPath = columnPath.concat(['textBool',]);
            let textBool = tool.getStateSpace(textBoolPath, ['真值', '假值',]);
            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>

                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            真值映射:
                        </th>
                        <th>
                            <div>
                                <input
                                    style={{width: '100px',}}
                                    onChange={event => {
                                        tool.setStateSpace(textBoolPath, [
                                            event.target.value,
                                            textBool[1],
                                        ]);
                                    }}
                                    value={textBool[0]}
                                />
                            </div>
                        </th>
                    </tr>

                    {/*第2行 假值映射*/}
                    <tr>
                        <th>
                            假值映射:
                        </th>
                        <th>
                            <div>
                                <input
                                    style={{width: '100px',}}
                                    onChange={event => {
                                        tool.setStateSpace(textBoolPath, [
                                            textBool[0],
                                            event.target.value,
                                        ]);
                                    }}
                                    value={textBool[1]}
                                />
                            </div>
                        </th>
                    </tr>

                    </thead>
                </table>
            </div>
        }

        // 映射类型
        if (type === 'map') {

            let mapPath = columnPath.concat(['map',]);
            let map = tool.getStateSpace(mapPath, {});

            let lines = [];

            // 列表
            for (const mapKey in map) {
                let value = map[mapKey];
                lines.push(
                    <tr key={mapKey}>
                        {/*key*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let value = event.target.value;
                                    let newMap = {};
                                    for (const _key in map) {
                                        let userKey = _key;
                                        if (_key === mapKey) userKey = value;
                                        newMap[userKey] = map[_key];
                                    }
                                    tool.setStateSpace(mapPath, newMap);
                                }}
                                style={{width: '40px',}}
                                // value={mapKey}
                                defaultValue={mapKey}
                            />
                        </th>

                        {/*箭头*/}
                        <th>
                            --->
                        </th>

                        {/*value*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let value = event.target.value;
                                    let newMap = {};
                                    for (const _key in map) {
                                        let userValue = map[_key];
                                        if (_key === mapKey) userValue = value;
                                        newMap[_key] = userValue;
                                    }
                                    tool.setStateSpace(mapPath, newMap);
                                }}
                                style={{width: '80px',}}
                                // value={value}
                                defaultValue={value}
                            />
                        </th>

                        {/*删除*/}
                        <th>
                            <Popconfirm
                                title="确定删除吗?"
                                description="确定删除吗?"
                                onConfirm={() => {
                                    let newMap = {};
                                    for (const _key in map) {
                                        if (_key !== mapKey) newMap[_key] = map[_key];
                                    }
                                    tool.setStateSpace(mapPath, newMap);
                                }}
                                onCancel={() => {
                                }}
                                okText="删除"
                                cancelText="不要"
                            >
                                <DeleteOutlined style={{color: 'red', marginLeft: '5px',}}/>
                            </Popconfirm>

                        </th>

                    </tr>
                );

            }

            // 增加映射
            lines.push(<div key={'create'} style={{width: '180px',}}>
                <button
                    onClick={() => {
                        let newMap = Object.assign({}, map,);
                        let random = Math.random() * 100000;
                        random = Math.floor(random);
                        newMap[`key_${random}`] = '新映射';
                        tool.setStateSpace(mapPath, newMap);
                    }}
                    style={{width: '100%',}}
                >增加映射
                </button>
            </div>)


            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                    // border:'red 1px solid',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>

                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            {/*真值映射:*/}
                        </th>
                        <th>
                            {lines}
                        </th>
                    </tr>


                    </thead>
                </table>
            </div>
        }

        // 渲染类型
        if (type === 'render') {

            let renderPath = columnPath.concat(['render',]);
            let defaultFunc = '(value, param)=>{return "返回值";}';
            let render = tool.getStateSpace(renderPath, defaultFunc);

            let errorInfo = '';
            try {
                // eslint-disable-next-line no-eval
                let func = eval(`(${render})`);
                if (typeof func !== 'function') {
                    errorInfo = '字符串无法识别为函数';
                }
            } catch (error) {
                errorInfo = '语法错误';
            }

            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>
                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            <Input.TextArea
                                value={render}
                                onChange={event => {
                                    let value = event.target.value;
                                    tool.setStateSpace(renderPath, value);
                                }}
                                row={10}
                            />
                            <button
                                style={{width: '100%',}}
                                onClick={() => {
                                    let string = '(value, param)=>{return "返回值(演示)";}';
                                    let func;
                                    try {
                                        // eslint-disable-next-line no-eval
                                        func = eval(`(${render})`);
                                    } catch (error) {
                                    }
                                    if (func) tool.setStateSpace(renderPath, func);
                                    if (!func) tool.setStateSpace(renderPath, string);
                                }}
                            >快速输入
                            </button>
                            <div style={{color: 'red',}}>
                                {errorInfo}
                            </div>
                            <div style={{color: 'grey', fontSize: '80%',}}>
                                本功能存在<span style={{color: 'red',}}>注入攻击</span>的风险, 请在开发完成后及时关闭开发模式.<br/>
                                提示:在IDE中写好代码然后直接粘贴.<br/>
                            </div>
                        </th>
                    </tr>


                    </thead>
                </table>
            </div>
        }

        return react;
    }

    getSetDetail() {
        let infoModalPath = ['props', 'infoModal',];
        let infoModal = tool.getAttribute(this, infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        // let spacePublic = tool.getStateSpace(spacePublicPath);
        let spaceDetailPath = spacePublicPath.concat(['columnsDetailLine']);
        let spaceDetail = tool.getStateSpace(spaceDetailPath);

        // 字段位置移动
        const moveDetailPos = (columnKey, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let columns = tool.getStateSpace(spaceDetailPath);
            let columnsArray = Object.keys(columns);
            let index = columnsArray.indexOf(columnKey);

            let indexTarget = index + direction;
            if (indexTarget < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexTarget > columnsArray.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }


            let temp = columnsArray[indexTarget];
            columnsArray[indexTarget] = columnsArray[index];
            columnsArray[index] = temp;
            let newColumns = {};
            for (const _columnKey of columnsArray) newColumns[_columnKey] = columns[_columnKey];
            tool.setStateSpace(spaceDetailPath, newColumns);
        };

        // 编辑组件
        let reactColumns = [];

        // 字段列表
        for (const columnKey in spaceDetail) {
            let column = spaceDetail[columnKey];
            if (column === true || !column) continue;
            let columnPath = spaceDetailPath.concat([columnKey]);

            let typePath = columnPath.concat(['type',]);
            let type = tool.getStateSpace(typePath, 'char');


            // 是否隐藏
            let hidden = tool.getAttribute(column, 'hidden',);

            // 字段附加
            let reactFieldAppend = this.getReactFieldAppend(columnPath);
            let border = reactFieldAppend ? '#BEBEBE 1px solid' : '';
            // let border = '';

            reactColumns.push(<div
                key={columnKey}
            >

                {/*分割线*/}
                <Divider>字段名:{column.title}</Divider>

                {/*删除字段*/}
                <div style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个字段吗?"
                        description="删除这个字段吗?"
                        onConfirm={() => {
                            let newSpaceDetail = {};
                            let spaceDetail = tool.getStateSpace(spaceDetailPath);
                            for (const _columnKey in spaceDetail) {
                                if (_columnKey === columnKey) continue;
                                newSpaceDetail[_columnKey] = spaceDetail[_columnKey];
                            }
                            tool.setStateSpace(spaceDetailPath, newSpaceDetail);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm>
                </div>

                {/*列表*/}
                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*第1行 是否显示*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(
                                    columnPath,
                                    Object.assign(column, {hidden: event.target.value,}));
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第2行 修改字段名*/}
                    <tr>
                        <th>
                            修改字段名:
                        </th>
                        <th>

                            <input
                                value={column.title}
                                onChange={event => {
                                    tool.setStateSpace(
                                        columnPath,
                                        Object.assign(column, {title: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第3行 字段类型*/}
                    <tr style={{border: border,}}>
                        <th>
                            字段类型:
                        </th>
                        <th>
                            <div>
                                <Select
                                    style={{width: '100%',}}
                                    // defaultValue={type}
                                    value={type}
                                    onChange={value => {
                                        tool.setStateSpace(
                                            columnPath,
                                            Object.assign(column, {type: value,}));
                                    }}
                                    options={[
                                        {
                                            value: 'char',
                                            label: '字符串',
                                        },
                                        {
                                            value: 'money',
                                            label: '人民币',
                                        },
                                        {
                                            value: 'map',
                                            label: '映射类型',
                                        },
                                        {
                                            value: 'render',
                                            label: '渲染类型',
                                        },
                                        {
                                            value: 'chinaTime',
                                            label: '时间类型(中国)',
                                        },
                                        {
                                            value: 'yearTime',
                                            label: '时间类型(年份)',
                                        },
                                        {
                                            value: 'utcTime',
                                            label: '时间类型(UTC)',
                                        },
                                        {
                                            value: 'helpArray',
                                            label: 'help数组',
                                        },
                                        {
                                            value: 'bool',
                                            label: '布尔类型',
                                        },
                                        {
                                            value: 'imgPath',
                                            label: '图片类型(路径)',
                                        },
                                        {
                                            value: 'imgShow',
                                            label: '图片类型(展示)',
                                        },
                                        {
                                            value: 'imgHead',
                                            label: '图片类型(头像)',
                                        },
                                        {
                                            value: 'label',
                                            label: '标签类型',
                                        },
                                        {
                                            value: 'labelHelp',
                                            label: '标签类型(帮助)',
                                        },
                                        {
                                            value: 'dynamic',
                                            label: '动态类型',
                                        },
                                        {
                                            value: 'file',
                                            label: '文件类型',
                                        },
                                        {
                                            value: 'userInfo',
                                            label: '用户信息类型',
                                        },
                                    ]}
                                />
                                <div style={{width: '100%',}}>
                                    {reactFieldAppend}
                                </div>

                            </div>
                        </th>
                    </tr>

                    {/*第4行 映射字段*/}
                    <tr>
                        <th>
                            修改映射字段:
                        </th>
                        <th>

                            <input
                                defaultValue={columnKey}
                                // value={columnKey}
                                onBlur={event => {
                                    let newColumns = {};
                                    for (const _columnKey in spaceDetail) {
                                        let useKey = _columnKey;
                                        if (_columnKey === columnKey) useKey = event.target.value;
                                        newColumns[useKey] = spaceDetail[_columnKey];
                                    }
                                    tool.setStateSpace(spaceDetailPath, newColumns);
                                }}
                            />
                        </th>
                    </tr>

                    {/*第6行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => moveDetailPos(columnKey, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => moveDetailPos(columnKey, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>

                    </thead>
                </table>


            </div>);
        }

        // 增加字段
        reactColumns.push(<div key={'create_field'} style={{width: '100%',}}>
            <Divider/>
            <Button
                onClick={() => {
                    let columns = tool.getStateSpace(spaceDetailPath);
                    let newData = {};
                    let random = Math.random() * 100000;
                    random = Math.floor(random);
                    newData[`field_${random}`] = {title: '新字段',};
                    let newColumns = Object.assign(columns, newData);
                    tool.setStateSpace(spaceDetailPath, newColumns);
                }}
                style={{width: '100%',}}>增加字段</Button>
            <Divider/>
        </div>);


        let reacts = [];
        reacts.push(<div key={'x'}>
            <Divider>编辑字段</Divider>
        </div>)

        // 详情设置
        let isSetDetailColShowPath = spacePrivatePath.concat(['isSetDetailColShow']);
        let isSetDetailColShow = tool.getStateSpace(isSetDetailColShowPath, false);


        let content = <Modal
            visible={isSetDetailColShow}
            open={isSetDetailColShow}
            onCancel={() => tool.setStateSpace(isSetDetailColShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
            {reactColumns}
        </Modal>

        let react;
        react = <div key={'x'}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetDetailColShowPath, true,)}
                style={{width: '100%',}}
            ><ToolOutlined/>编辑</Button>
        </div>;
        return react;
    }

    getContent() {
        let infoModalPath = ['props', 'infoModal',];
        let infoModal = tool.getAttribute(this, infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Detail') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let onload = tool.getAttribute(paramModal, 'onload');
        let record = tool.getAttribute(paramModal, 'record');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let spaceRowPath = tool.getAttribute(paramModal, 'spaceRowPath');

        //显示字段
        // let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine',]);
        // let columnsDetailLine = tool.getStateSpace(columnsDetailLinePath);

        let columnsDetailLine = tool.getAttribute(spacePublic, 'columnsDetailLine');

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsDetailLine = tool.getAttribute(filterNow, 'columnsDetailLine');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsDetailLine) {
            columnsDetailLine = filterColumnsDetailLine;
        }


        if (!columnsDetailLine) return null;

        let fieldReacts = [];
        fieldReacts.push(<div key={'x'}>
            {this.getSetDetail()}
        </div>);
        for (let columnKey in columnsDetailLine) {
            //得到具体的字段
            if (!columnsDetailLine.hasOwnProperty(columnKey)) continue;

            // 是否显示这个字段
            let state = true;
            let columnDetailLine = columnsDetailLine[columnKey];
            let fun_is_show = tool.getAttribute(columnDetailLine, 'is_show');

            // 是否隐藏这个字段
            let hidden = tool.getAttribute(columnDetailLine, 'hidden');
            // console.log('hid----', columnDetailLine['title'], hidden)
            if (hidden) continue;

            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath, infoModal: infoModal,};
                state = fun_is_show(param);
            }

            if (state) {
                fieldReacts.push(this.getFieldReact({
                    infoModal: infoModal,
                    paramModal: paramModal,
                    onload: onload,
                    record: record,
                    spacePrivatePath: spacePrivatePath,
                    spacePublicPath: spacePublicPath,
                    spaceRowPath: spaceRowPath,

                    columnsDetailLine: columnsDetailLine,
                    columnKey: columnKey, // 详情列提交时的key
                    action: 'detail',
                }));
            }
        }

        return fieldReacts;
    }

    onOk() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: 'Update',
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    onCancel() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    render() {
        this.updateData();
        let infoModal = tool.getAttribute(this, ['props', 'infoModal',]);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Detail') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let detailOnload = tool.getAttribute(paramModal, 'detailOnload');
        if (detailOnload) return '加载中...';

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        let content = this.getContent();

        let textTitlePath = spacePublicPath.concat(['text__detail_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据详情';

        // 是否允许编辑
        let columnsUpdate = this.getColumnsUpdate();
        let okText = '好的';
        let onOk = () => this.onCancel();
        if (columnsUpdate) {
            okText = '切换到编辑模式';
            onOk = () => this.onOk();
        }
        return (
            <div>
                <Modal
                    title={textTitle}
                    open={true}
                    visible={true}
                    okText={okText}
                    cancelText={'关闭'}
                    onOk={onOk}
                    onCancel={() => this.onCancel()}
                    // footer={null}
                >
                    {content}
                </Modal>
            </div>
        );
    }
}

function mapStateToDetail(state) {
    return {
        infoModal: state.infoModal,
        setting: state.setting,
    }
}

DetailLine = connect(mapStateToDetail)(DetailLine);

export {DetailLine};