import React from "react";
import {Modal} from "antd";
import {tool} from "../tools/tools";
import {connect} from "react-redux";


// 备用显示框
class MyModal extends React.Component {
    render() {
        let getContent = tool.getStateSpace(['my_modal_param', 'get_content',],);
        let isShow = tool.getStateSpace(['my_modal_param', 'is_show',], false,);
        let title = tool.getStateSpace(['my_modal_param', 'title',], '无标题',);
        let okText = tool.getStateSpace(['my_modal_param', 'okText',], '提交',);
        let onOk = tool.getStateSpace(['my_modal_param', 'onOk',], null,);
        let onCancel = tool.getStateSpace(['my_modal_param', 'onCancel',], null,);
        if (!onCancel) {
            onCancel = () => {
                tool.setStateSpace(['my_modal_param', 'is_show',], false,);
            };
        }
        let cancelText = tool.getStateSpace(['my_modal_param', 'cancelText',], '关闭');
        if (!isShow) return null;
        if (!getContent) return null;

        let content = getContent(this);
        return <Modal
            title={title}
            open={true}
            visible={true}
            okText={okText}
            cancelText={cancelText}
            onOk={onOk}
            onCancel={onCancel}
        >
            {content}
        </Modal>;
    }
}

function mapStateToMyModal(state) {
    let my_modal_param = tool.getAttribute(state, ['my_modal_param']);
    return {
        my_modal_param: my_modal_param,
    }
}

MyModal = connect(mapStateToMyModal)(MyModal);

export {MyModal};