import React from 'react';
import logo from './logo.ico';
import {tool} from "../../tools/tools";

const emailCodePath = 'api_v1/email_code/'; // 申请验证码
const userRegPath = 'api_v1/user/'; // 申请账号


class ChatRegComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            verificationCode: '',
            isCountdown: false,
            countdownTime: 60,
            emailError: ''
        };
    }

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    handleEmailChange = (event) => {
        this.setState({email: event.target.value, emailError: ''});
    }

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    }

    handleVerificationCodeChange = (event) => {
        this.setState({verificationCode: event.target.value});
    }

    handleRequestVerificationCode = () => {
        if (this.state.isCountdown) return;

        if (!this.validateEmail(this.state.email)) {
            this.setState({emailError: '请输入有效的邮箱地址！'});
            return;
        }

        console.log(`申请验证码，邮箱: ${this.state.email}`);

        this.startCountdown();

        tool.post({
            url: tool.path_join(emailCodePath),
            data: {email: this.state.email,},
            success: () => {
                tool.show_success('验证码申请成功！');
            },
            error: data => {
                tool.show_error('验证码申请失败！请联系站长');
            },
        });


    }

    startCountdown = () => {
        this.setState({isCountdown: true, countdownTime: 60});

        const timer = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.countdownTime <= 1) {
                    clearInterval(timer);
                    return {isCountdown: false, countdownTime: 0};
                }
                return {countdownTime: prevState.countdownTime - 1};
            });
        }, 1000);
    }

    handleSubmit = () => {
        tool.post({
            url: tool.path_join(userRegPath),
            data: {
                email: this.state.email,
                password: this.state.password,
                code: this.state.verificationCode,
            },
            success: data => {
                tool.show_success('注册成功！请去登录吧！');
            },
            error: data => {
              tool.show_error(data);
            },
        });
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: 'linear-gradient(to right, #eef2f3, #8e9eab)',
                fontFamily: 'Arial, sans-serif'
            }}>
                <div style={{
                    width: '450px',
                    padding: '30px',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    borderRadius: '15px',
                    position: 'relative'
                }}>
                    {/* 顶部信息窗 */}
                    <div style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        paddingBottom: '15px',
                        borderBottom: '1px solid #ccc',
                        opacity: 0.9
                    }}>
                        <img src={logo} alt="Logo" style={{width: '80px', marginBottom: '10px'}}/>
                        <h2 style={{margin: '0', fontSize: '24px', color: '#333'}}>圈圈聊天室</h2>
                        <p style={{margin: '5px 0 0', fontSize: '14px', color: '#555'}}>
                            欢迎来到圈圈聊天室，请注册或重置密码以继续。
                        </p>
                    </div>

                    {/* 表单内容 */}
                    <input
                        type="email"
                        placeholder="请输入邮箱"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                    />
                    {this.state.emailError && (
                        <p style={{color: 'red', fontSize: '12px', marginBottom: '15px'}}>
                            {this.state.emailError}
                        </p>
                    )}

                    <input
                        type="password"
                        placeholder="请输入密码"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)'
                        }}
                    />

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                        <input
                            type="text"
                            placeholder="请输入验证码"
                            value={this.state.verificationCode}
                            onChange={this.handleVerificationCodeChange}
                            style={{
                                flex: 1,
                                padding: '10px',
                                marginRight: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)'
                            }}
                        />
                        <button
                            onClick={this.handleRequestVerificationCode}
                            disabled={this.state.isCountdown}
                            style={{
                                padding: '10px',
                                backgroundColor: this.state.isCountdown ? '#ccc' : '#007bff',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: this.state.isCountdown ? 'not-allowed' : 'pointer'
                            }}
                        >
                            {this.state.isCountdown ? `${this.state.countdownTime}s` : '申请验证码'}
                        </button>
                    </div>

                    <button
                        onClick={this.handleSubmit}
                        style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#28a745',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        提交
                    </button>
                </div>
            </div>
        );
    }
}

export {
    ChatRegComponent
};
