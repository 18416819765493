import React from 'react';
import ReactDOM from 'react-dom';
import {Provider,} from 'react-redux';
import {ComponentLayout} from "./apps/layout/layout";
import {Login,} from "./apps/authenticate/login";
import {RightClick} from "./apps/right-click/right-click";

import {setting} from "./setting";
import {store} from "./apps/store/store";
import {tool} from "./apps/tools/tools";
import {connect} from 'react-redux';

import 'antd/dist/antd.css';
import {Button, Popconfirm} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";

let openEditPath = ['open_edit'];

//显示布局还是显示登录
class Root extends React.Component {
    getNowPage() {
        let nowPage = tool.getStateSpace(['nowPage',]);
        if (!nowPage) return null;
        let react = tool.getStateSpace(['setting', 'pageMap', nowPage, 'react',]);
        if (!react) return null;

        return react;
    }

    getRoot() {
        let nowPage = this.getNowPage();
        if (nowPage) return nowPage;


        let headers = tool.getStateSpace(['setting', 'authenticate', 'headers',]);
        let choiceRole = tool.getStateSpace(['setting', 'authenticate', 'choiceRole',]);
        if ((!headers) || (choiceRole)) {
            let loginReact;
            loginReact = tool.getStateSpace(
                ['setting', 'authenticate', 'loginReact',],
                <Login/>,
            );
            return loginReact;
        }
        return <ComponentLayout/>;
    }

    getPath() {
        let path = tool.getAttribute(window, ['location', 'pathname',]);
        path = path.split('/');
        return path[1];
    }

    getReactConfPush() {
        let open_edit = tool.getStateSpace(['open_edit']);
        if (!open_edit) return null;

        let reactOpenEdit;
        // tool.show_info(['开发模式已打开']);
        setTimeout(() => tool.show_info(['开发模式已打开']), 200);
        reactOpenEdit = <div style={{
            position: "fixed",
            top: "50px",
            left: "45%",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            zIndex: '200',
        }}>
            <Popconfirm
                title="你确定要提交吗？"
                description="你真的要提交吗？"
                onConfirm={() => {
                    let settings = tool.getStateSpace(['setting']);
                    let data = {}
                    for (const settingsKey in settings) {
                        if (tool.isInArray([
                            'authenticate',
                            'selectedMenu',
                            'fieldMapping',
                            'fieldDetailMapping',
                            'bottom',
                            'pageMap',
                            'code',

                            // 'user',
                            // 'programmer',
                        ], settingsKey)) continue;
                        data[settingsKey] = settings[settingsKey];
                    }
                    data = JSON.stringify(data);
                    console.log('下载云端1')
                    let url = tool.path_join(tool.getAttribute(settings, ['confUrl']));
                    let code = tool.getAttribute(settings, ['code']);
                    tool.post({
                        url: url,
                        data: {
                            code: code,
                            data: data,
                        },
                        success: _ => {
                            tool.show_success('提交成功');
                        },
                        error: data => {
                            tool.show_error(data);
                        },
                    });
                }}
                onCancel={() => {
                    tool.show_info('取消提交');
                }}
                okText="提交"
                cancelText="不要"
            >
                <Button><CloudUploadOutlined/>配置提交到服务器</Button>
            </Popconfirm>
        </div>;
        return reactOpenEdit;
    }

    render() {
        let root = this.getRoot();
        let path = this.getPath();
        if (!path) path = 'defaultRoot';
        let reactOpenEdit = this.getReactConfPush();
        let router = {
            defaultRoot: (<div>
                {reactOpenEdit}
                {root}
                <RightClick/>
            </div>),
        };

        let router_custom = tool.getAttribute(setting, 'router');
        let bottom = tool.getAttribute(setting, 'bottom', null,);
        router = Object.assign({}, router, router_custom);
        if (!router[path]) return router['defaultRoot'];

        return <div>
            {router[path]}
            <div>
                {bottom ? bottom : null}
            </div>
            {reactOpenEdit}
        </div>;
    }
}

function mapStateToRoot(state) {
    return {
        authenticate: state.setting.authenticate,
        nowPage: state.nowPage,
        open_edit: state.open_edit,
    }
}

Root = connect(mapStateToRoot)(Root);

ReactDOM.render(<Provider store={store}><Root/></Provider>, document.getElementById('root'));

let clickSign = {};
window.onload = function () {
    document.oncontextmenu = () => false;

    document.onmousedown = event => {
        // 标记
        let origin = tool.getAttribute(clickSign, event.button, 0);
        clickSign[event.button] = origin + 1;
        setTimeout(() => {
            let origin = tool.getAttribute(clickSign, event.button, 0);
            let now = origin - 1;
            if (now < 0) now = 0;
            clickSign[event.button] = now;

        }, 2000);
        if (clickSign[0] === 2 && clickSign[1] === 1 && clickSign[2] === 2) {
            let open_edit = tool.getStateSpace(['open_edit'], false);
            tool.setStateSpace(['open_edit'], !open_edit);
        }

        if (event.button === 2) return null;
        let rightClickPath = ['rightClick',];
        let rightClick = tool.getStateSpace(rightClickPath);

        let sign = tool.getAttribute(rightClick, 'sign');
        if (sign) return null;

        rightClick = Object.assign({}, rightClick, {
            show: false,
        });
        tool.setStateSpace(rightClickPath, rightClick);
        return null;
    };

    tool.setTitle(setting.title);

    // 下载云端配置
    let confUrl = tool.getAttribute(setting, 'confUrl', null);
    let code = tool.getAttribute(setting, 'code', null);
    if (confUrl && code) {
        let url = tool.path_join(confUrl,);
        tool.get({
            url: url,
            success: data => {
                if (data['close']) return;
                data = tool.getAttribute(data, 'data',);
                if (!data) return;
                data = JSON.parse(data);
                let newSetting = Object.assign(setting, data);
                tool.setStateSpace(['setting'], newSetting);

                // todo
                tool.setStateSpace(['open_edit'], true);
            },
            error: data => {
            },
        });
    }
};
export {openEditPath,};