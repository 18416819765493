import {tool} from "../../tools/tools";
import {funcMapping} from "../../../setting";

function render(param) {
    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        value = value.toString();
    }
    let render = tool.getAttribute(param, ['columnInfo', 'render',]);

    // 是个函数
    if (typeof render === 'function') {
        return render(value, param, param.value);
    }

    // 是字符串映射
    if (typeof render === 'string' && funcMapping[render]) {
        render = funcMapping[render];
        return render(value, param, param.value);
    }

    // 是字符串eval
    if (typeof render === 'string') {
        let string = render;

        let func = () => "渲染失败[0]";
        try {
            // eslint-disable-next-line no-eval
            let funcTemp = eval(`(${string})`);
            if (typeof funcTemp === 'function') func = funcTemp;
        } catch (error) {
        }

        return func(value, param, param.value);
    }
    return "渲染失败[1]";
}

export {render};

