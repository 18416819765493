import React from "react";
// import {tool} from "../../tools/tools";

class TestField extends React.Component {
    render() {
        // let onChange = tool.getAttribute(this, ['props', 'param', 'onChange',]);
        return '测试字段';
    }
}

export {TestField,};