import React from "react";
import {connect} from 'react-redux';
import {tool} from "../../tools/tools";
import {Button} from "antd";

const pathBaseFlow = 'api_v1/base_flow/'; // 流程模板
const pathFlow = 'api_v1/flow/'; // 流程
const pathStep = 'api_v1/step/'; // 步骤


const backGroundColorMap = {
    'xi': 'lightgreen',
    'han': 'lightblue',
    'zhao': 'lightyellow',
};
const nameMap = {
    'xi': '俊熙',
    'han': '珺涵',
    'zhao': '多多',
};

// 正在 yellow
class One extends React.PureComponent {
    constructor(props) {
        super(props);
        this.baseFlowResponse = {
            dataBaseFlowResponse: null, // 流程模板响应数据
            dataBaseFlowResults: [], // 流程模板响应数据

            dataFlowResponse: null, // 流程响应数据
            dataFlowResults: [], // 流程响应数据
        };
        this.state = Object.assign({
            page: 1, // 1-流程模板页面 2-流程页面
            stepIDRequestSign: {
                // 5:true, 例子
            },// 记录某个步骤是否正在请求中
        }, this.baseFlowResponse,);

        this.cssDiv0 = this.cssDiv0.bind(this);
        this.updateBaseFlow = this.updateBaseFlow.bind(this);
        this.updateFlow = this.updateFlow.bind(this);
        this.checkPage = this.checkPage.bind(this);

        this.reactHead = this.reactHead.bind(this);
        this.reactPage1 = this.reactPage1.bind(this);
        this.reactPage2 = this.reactPage2.bind(this);
    }

    componentDidMount() {
        this.updateBaseFlow();
        this.updateFlow();
    }

    checkPage(page) {
        this.setState({page: page,});
        if (page === 1) this.updateBaseFlow();
        if (page === 2) this.updateFlow();
    }

    updateBaseFlow() {
        const path = tool.path_join(pathBaseFlow, {user: this.props.username},);
        tool.get({
            url: path,
            success: data => {
                this.setState({
                    dataBaseFlowResponse: data,
                    dataBaseFlowResults: data['results'],
                });
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    updateFlow() {
        const path = tool.path_join(pathFlow, {user: this.props.username},);
        tool.get({
            url: path,
            success: data => {
                this.setState({
                    dataFlowResponse: data,
                    dataFlowResults: data['results'],
                });
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    cssDiv0() {
        const username = this.props.username;
        const topMap = {
            'xi': '0%',
            'han': '33%',
            'zhao': '66%',
        };

        let div0;
        div0 = {
            position: 'absolute',
            // border: 'red 1px solid',
            backgroundColor: backGroundColorMap[username],
            height: '30%',
            width: '100%',
            top: topMap[username],
            borderRadius: '18px',
            overflow: 'hidden',
            border: '#000 1px solid',
        };
        return div0;
    }

    // 头
    reactHead() {
        let buttonStyle = {
            width: '50%',
            display: 'inline-block',
            fontSize: '300%',
            textAlign: 'center',
            // borderRadius: '8px',
        };
        const username = this.props.username;
        let backGroundColor = backGroundColorMap[username];
        let button1 = Object.assign({}, buttonStyle, {
            backgroundColor: this.state.page === 1 ? backGroundColor : 'lightgray',
        });
        let button2 = Object.assign({}, buttonStyle, {
            backgroundColor: this.state.page === 2 ? backGroundColor : 'lightgray',
        });

        return <div style={{
            marginBottom: '10px',
        }}>
            <div style={{
                textAlign: 'center',
                fontSize: '300%',
                backgroundColor: 'white',
            }}>{nameMap[username]}的流程卡片
            </div>
            <div style={button1} onClick={() => {
                // this.setState({page: 1,});
                this.checkPage(1);
            }}>选择流程
            </div>
            <div style={button2} onClick={() => {
                // this.setState({page: 2,});
                this.checkPage(2);
            }}>正在进行
            </div>
        </div>;
    }

    // 流程模板页面
    reactPage1() {
        const baseFlowResults = tool.getAttribute(this, ['state', 'dataBaseFlowResults',], []);
        const reactBaseFlows = [];
        for (const baseFlow of baseFlowResults) {
            const flow_name = baseFlow.flow_name;
            const base_flow_id = baseFlow.base_flow_id;
            const task = baseFlow.task;
            reactBaseFlows.push(<Button
                key={base_flow_id}
                style={{
                    fontSize: '300%',
                    height: '100px',
                    backgroundColor: task > 0 ? 'lightpink' : '',
                }}
                onClick={() => {
                    const path = tool.path_join(pathFlow, {user: this.props.username},);
                    tool.post({
                        url: path,
                        data: {
                            base_flow_obj: base_flow_id,
                        },
                        success: _ => {
                            // this.updateBaseFlow();
                            // this.setState({page: 2,});
                            this.checkPage(2);
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >
                开始{flow_name}
            </Button>);
        }
        return <div>
            {reactBaseFlows}
        </div>;
    }

    // 流程页面
    reactPage2() {
        const div = {
            display: 'inline-block',
            borderRadius: '8px',
            marginRight: '10px',
            marginLeft: '4%',
            width: '92%',
            marginBottom: '10px',
        };
        const _getReactStep = _flow => {
            const stepInfo = _flow['step_info'];
            const reactSteps = [];

            const stepBackgroundColorMap = {
                // 1: 'lightpink',
                1: 'yellow',
                2: 'yellow',
                3: 'lightblue',
                4: 'green',
            };
            let count = 0;
            for (const step of stepInfo) {
                count++;
                if (count > 1) break;
                const stepStatus = step['status'];
                const divStep = Object.assign({}, div, {
                    backgroundColor: stepBackgroundColorMap[stepStatus],
                    fontSize: '200%',
                    width: '90%',
                    borderRadius: '18px',
                    textAlign: 'center',
                });


                const stepID = step['step_id'];
                const stepName = step['step_name'];

                // 请求中
                const requesting = tool.getAttribute(this, ['state', 'stepIDRequestSign', stepID,], false);
                if (requesting) divStep['backgroundColor'] = 'gray';


                reactSteps.push(<div
                    key={stepID}
                    style={divStep}
                    onClick={() => {
                        const requesting = tool.getAttribute(this, ['state', 'stepIDRequestSign', stepID,], false);
                        if (requesting) return;

                        let stepIDRequestSign = Object.assign({}, this.state.stepIDRequestSign);
                        stepIDRequestSign[stepID] = true;
                        this.setState({stepIDRequestSign: stepIDRequestSign,});

                        tool.patch({
                            url: tool.path_join(pathStep, {user: this.props.username}, stepID),
                            data: {status: 3,},
                            success: _ => {
                                this.updateFlow();

                                let stepIDRequestSign = Object.assign({}, this.state.stepIDRequestSign);
                                stepIDRequestSign[stepID] = false;
                                this.setState({stepIDRequestSign: stepIDRequestSign,});
                            },
                            error: data => tool.show_error(data),
                        });
                    }}
                >
                    {stepName}
                </div>)
            }
            return reactSteps;
        };

        // const _getReactStart = _flow => {
        //     const flowStatus = _flow['status'];
        //     const flowID = _flow['flow_id'];
        //     const startBackgroundColorMap = {
        //         1: 'lightpink',
        //         2: 'lightblue',
        //         3: 'lightblue',
        //         4: 'gray',
        //     };
        //     let reactStart;
        //     const divStep = Object.assign({}, div, {
        //         backgroundColor: startBackgroundColorMap[flowStatus],
        //     });
        //     reactStart = <div
        //         key={'start'}
        //         style={divStep}
        //         onClick={() => {
        //             tool.patch({
        //                 url: tool.path_join(pathFlow, {user: this.props.username}, flowID),
        //                 data: {status: 2,},
        //                 success: _ => {
        //                     this.updateFlow();
        //                 },
        //                 error: data => tool.show_error(data),
        //             });
        //         }}
        //     >
        //         开始
        //     </div>;
        //     return reactStart;
        // };

        const _getProgress = _flow => {
            const total_count = _flow['step_progress']['total_count'];
            const done_step_count = _flow['step_progress']['done_step_count'];
            const ing_step_count = _flow['step_progress']['ing_step_count'];
            return <div style={{
                backgroundColor: 'lightgray',
                borderRadius: '8px',
                overflow: 'hidden',
                width: '92%',
                marginLeft: '4%',
            }}>
                <div style={{
                    width: `${done_step_count / total_count * 100}%`,
                    backgroundColor: 'green',
                }}>
                    {done_step_count}/{ing_step_count}
                </div>
            </div>;
        }
        const dataFlowResults = tool.getAttribute(this, ['state', 'dataFlowResults',], []);
        const reactFlows = [];

        for (const flow of dataFlowResults) {

            const flowID = flow['id'];
            // const flowName = flow['base_flow_name'];
            reactFlows.push(
                <div key={flowID} style={{
                    fontSize: '300%',
                    marginBottom: '20px',
                    // borderBottom: '1px solid black',
                }}>
                    {/*<div style={{*/}
                    {/*    textAlign: 'center',*/}
                    {/*}}>*/}
                    {/*    {nameMap[this.props.username]}正在努力的完成{flowName}流程*/}
                    {/*</div>*/}
                    {/*{_getReactStart(flow)}*/}
                    {_getReactStep(flow)}
                    <br/>
                    {_getProgress(flow)}
                </div>);
        }

        return <div style={{
            // backgroundColor: 'white',
            // backgroundColor: 'red',
            borderRadius: '18px',
            marginTop: '20px',
            width: '90% ',
            marginLeft: '5%',
        }}>
            <br/> <br/>
            {reactFlows}
        </div>;
    }

    render() {
        let react;
        if (this.state.page === 1) {
            react = this.reactPage1();
        } else if (this.state.page === 2) {
            react = this.reactPage2();
        } else {
            react = '未处理';
        }

        const div0 = this.cssDiv0();
        return <div style={div0}>
            {this.reactHead()}
            {react}
        </div>;
    }
}


class Flow extends React.Component {
    render() {
        return <div style={{
            "touch-action": "manipulation",
            "-webkit-overflow-scrolling": "touch",
            "-ms-overflow-style": "none", /* 针对IE/Edge */
            "overflow": "-moz-scrollbars-none", /* 针对Firefox */
            "overflow-y": "scroll",
        }}>
            <div style={{
                position: 'absolute',
                height: '96%',
                top: '2%',
                width: '100%',
                overflow: 'auto',
                // overflow: 'hidden',


            }}>
                <One username={'xi'}/>
                <One username={'han'}/>
                <One username={'zhao'}/>
            </div>
        </div>
    }
}

function mapStateToFlow(state) {
    let username = tool.getAttribute(state, ['setting', 'authenticate', 'username',]);
    let setting = tool.getAttribute(state, ['setting',]);
    return {
        username: username,
        setting: setting,
    }
}

Flow = connect(mapStateToFlow)(Flow);

export {
    Flow
};

