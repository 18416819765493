import {fieldDetailMapping, fieldMapping,} from "./public_setting";
import {tool} from "./apps/tools/tools";
import {WeightEcharts,} from "./apps/assistant/weight/weight";
import {Bottom} from "./apps/assistant/footer/bottom";
import {TestField} from "./apps/assistant/fields/test_fields";
import {Album} from "./apps/assistant/album/album";
import {triggerDown, triggerSetCode, triggerUp} from "./apps/assistant/e-book/ebook";
import {WinHubComponent} from "./apps/assistant/win_hub/win_hub";
import {triggerStatusSet1, triggerStatusSet3} from "./apps/assistant/win_hub/trigger";
import {McComponent,} from "./apps/assistant/mc/mc";
import {MoneyComponent} from "./apps/assistant/money/money";
import {Flow} from "./apps/assistant/flow/flow";
import {ChatRegComponent} from "./apps/assistant/chat_reg/chat_reg";
import {ArtProgress} from "./apps/assistant/作品进度记录/作品进度记录";

const conf = [
    ['输入你的系统帐号', 'Enter your system account number.',],
    ['输入你的密码', 'Enter your password.',],
    ['登录', 'Login',],
];

function language() {
    return 1;
}

function conf2mapping() {
    // 仅运行一次, 转化为符合js语法并可以哈希查询的格式
    let mapping = {};
    for (const line of conf) {
        let key = encodeURIComponent(line[0]);
        mapping[key] = line[1];
    }
    return mapping;
}

const mapping = conf2mapping();

function Text(text) {
    if (language() === 1) return text;
    let key = encodeURIComponent(text);
    if (mapping[key]) return mapping[key];
    console.log('未翻译的汉语---', text);
    return text;
}

//个人助理系统后台 sub__
let RobotFooter = '©2024 Robot';
let funcMapping = {
    triggerStatusSet1: triggerStatusSet1,
    triggerStatusSet3: triggerStatusSet3,
    WeightEcharts: <WeightEcharts/>,
    mc: <McComponent/>,
    album: <Album/>,
    flow: <Flow/>,

};
let setting = {
    // stateAffixMenu: true,
    title: Text('Robot系统后台'),
    authenticate: {
        loginPath: '/programmer/jwt/',
        setPath: '/programmer/set_password/',
        headers: null,
        role: null,
        username: null,
        responseData: null,
        jwtToken: null,
        abstract_role_name: null,
        logoImage: '',
        english: false,
    },
    // programmer: {},
    programmer: {
        webTitle: 'Robot系统-programmer控制台',
        systemTitle: 'programmer控制台',
        footer: RobotFooter,
        menu__children: {
            name: '幼崽管理',
            sub: {
                menu__win_hub: {
                    name: '积分管理',
                    table: {
                        path: 'api_v1/back_action/',
                        columns: {
                            name: {title: '名字',},
                            date: {title: '日期',},
                            status: {
                                title: '状态', type: 'map', map: {
                                    1: '已生效',
                                    2: '审核中',
                                    3: '无效记录',
                                },
                            },
                            event: {
                                title: '事件类型', type: 'map', map: {
                                    1: '洗刷任务',
                                    2: '使用积分',
                                    3: '申请积分',
                                    4: '上课任务',
                                    5: '违反纪律',
                                    6: '获得星星',
                                },
                            },
                            start: {title: '开始', type: 'utcTime',},
                            end: {title: '结束', type: 'utcTime',},
                            request_score: {title: '请求分值',},
                            score: {title: '积分',},
                            remark: {title: '备注',},
                        },
                        columnsUpdate: {
                            remark: {title: '备注',},
                        },
                        disableDownload: true,
                        filters: {
                            choices: {
                                'all': {
                                    text: '全部',
                                },
                                'xi': {
                                    text: '俊熙',
                                },
                                'han': {
                                    text: '珺涵',
                                },
                                'zhao': {
                                    text: '多多',
                                },
                            },
                            default: 'all',
                        },
                        trigger__set_status_1: {
                            columnTitle: '操作',
                            textButton: '通过',
                            func: "triggerStatusSet1",
                        },
                        trigger__set_status_3: {
                            columnTitle: '操作',
                            textButton: '废弃',
                            func: "triggerStatusSet3",
                        },
                        filter__status: {
                            choices: {
                                "1": {
                                    text: '已生效',
                                },
                                "2": {
                                    text: '审核中',
                                },
                                "0": {
                                    text: '全部',
                                },
                            },
                            default: "2",
                            type: 'row', // row
                        },
                    },
                },
                menu__child: {
                    name: '幼崽列表',
                    table: {
                        path: 'api_v1/children/',
                        columns: {
                            name: {title: '名字',},
                            score_total: {title: '积分',},
                        },
                        disableDownload: true,
                    },
                },
                menu__base_flow: {
                    name: '流程模板',
                    table: {
                        path: 'api_back/base_flow/',
                        columns: {
                            username: {title: '谁的流程',},
                            flow_name: {title: '流程名称',},
                        },
                        columnsUpdate: {
                            user_obj: {title: '谁的流程', type: 'singleSmallChoice', path: 'api_back/choices_user/',},
                            flow_name: {title: '流程名称',},
                        },
                        columnsCreate: {
                            user_obj: {title: '谁的流程', type: 'singleSmallChoice', path: 'api_back/choices_user/',},
                            flow_name: {title: '流程名称',},
                        },
                        dynamicFilter: //为智能表格添加动态过滤器
                            {
                                path: 'api_back/choices_user/', // 选项加载于此路径
                                searchKey: 'user_id', //动态过滤器将使用这个关键字作为查询关键字 ?database=选中的值(choices.id)
                                text: '只看他', // 动态过滤器初始显示的文字
                            },
                        disableDownload: true,
                        text__create_button: '创建流程模板',
                        sub__step: {
                            columnTitle: '步骤模板',
                            path: 'api_back/sub_base_step/',
                            columns: {
                                step_name: {title: '步骤名称',},
                                index: {title: '序号',},
                            },
                            columnsCreate: {
                                step_name: {title: '步骤名称',},
                                index: {title: '序号',},
                            },
                            columnsUpdate: {
                                step_name: {title: '步骤名称',},
                                index: {title: '序号',},
                            },
                            disableDownload: true,
                            text__create_button: '创建步骤模板',
                        },
                    },
                },
                menu__flow: {
                    name: '流程',
                    table: {
                        path: 'api_back/flow/',
                        columns: {
                            base_flow_name: {title: '流程名称',},
                            start_obj: {title: '开始',},
                            end_obj: {title: '结束',},
                            status: {
                                title: '状态', type: 'map', map: {
                                    1: '未开始',
                                    2: '进行中',
                                    3: '已结束',
                                    4: '已关闭',
                                },
                            },
                        },
                        columnsUpdate: {
                            base_flow_obj: {
                                title: '所属流程',
                                type: 'singleSmallChoice',
                                path: 'api_back/choices_base_flow/',
                            },
                            status: {
                                title: '状态', type: 'singleSmallChoice', choices: [
                                    {id: 1, value: 1, help: '未开始',},
                                    {id: 2, value: 2, help: '进行中',},
                                    {id: 3, value: 3, help: '已结束',},
                                    {id: 4, value: 4, help: '已关闭',},
                                ],
                            },
                        },
                        columnsCreate: {
                            base_flow_obj: {
                                title: '所属流程',
                                type: 'singleSmallChoice',
                                path: 'api_back/choices_base_flow/',
                            },
                            status: {
                                title: '状态', type: 'singleSmallChoice', choices: [
                                    {id: 1, value: 1, help: '未开始',},
                                    {id: 2, value: 2, help: '进行中',},
                                    {id: 3, value: 3, help: '已结束',},
                                    {id: 4, value: 4, help: '已关闭',},
                                ],
                            },
                        },
                        dynamicFilter: //为智能表格添加动态过滤器
                            {
                                path: 'api_back/choices_user/', // 选项加载于此路径
                                searchKey: 'user_id', //动态过滤器将使用这个关键字作为查询关键字 ?database=选中的值(choices.id)
                                text: '只看他', // 动态过滤器初始显示的文字
                            },
                        disableDownload: true,
                        text__create_button: '增加流程',
                        sub__step: {
                            columnTitle: '步骤',
                            path: 'api_back/sub_step/',
                            columns: {
                                step_name: {title: '步骤名称',},
                                status: {
                                    title: '状态', type: 'map', map: {
                                        1: '未开始',
                                        2: '进行中',
                                        3: '已结束',
                                        4: '已关闭',
                                    },
                                },
                                start_obj: {title: '开始', type: 'utcTime',},
                                end_obj: {title: '结束', type: 'utcTime',},
                                delta: {title: '耗时(秒)',},
                                index: {title: '排序',},
                            },

                            disableDownload: true,
                            text__create_button: '创建步骤',
                        },
                    },
                },
                menu__star_hub: {
                    name: '满分周期',
                    table: {
                        path: 'api_v1/back_cycle/',
                        columns: {
                            index: {title: '周期',},
                            created_at: {title: '创建时间', type: 'utcTime',},
                        },
                        columnsCreate: {},
                        disableDownload: true,
                        sub__score: {
                            columnTitle: '满分记录',
                            path: 'api_v1/back_score/',
                            columns: {
                                name: {title: '名字',},
                                subject: {
                                    title: '科目', type: 'map', map: {
                                        1: '语文',
                                        2: '数学',
                                        3: '英语',
                                        4: '坚持之星',
                                    },
                                },
                                used: {title: '已经使用', type: 'bool', textBool: ['已用', '未用',],},
                            },
                            columnsCreate: {
                                user_obj: {title: '名字', type: 'singleSmallChoice', path: 'api_v1/choices_user/',},
                                subject: {
                                    title: '科目', type: 'singleSmallChoice',
                                    choices: [
                                        {value: 1, id: 1, help: '语文',},
                                        {value: 2, id: 2, help: '数学',},
                                        {value: 3, id: 3, help: '英语',},
                                        {value: 4, id: 4, help: '坚持之星',},
                                    ],
                                },
                            },
                            columnsUpdate: {
                                user_obj: {title: '名字', type: 'singleSmallChoice', path: 'api_v1/choices_user/',},
                                used: {title: '已经使用', type: 'bool',},
                                subject: {
                                    title: '科目', type: 'singleSmallChoice',
                                    choices: [
                                        {value: 1, id: 1, help: '语文',},
                                        {value: 2, id: 2, help: '数学',},
                                        {value: 3, id: 3, help: '英语',},
                                    ],
                                },
                            },
                            disableDownload: true,
                            text__create_button: '创建满分记录',
                        },
                        sub__star: {
                            columnTitle: '星星记录',
                            path: 'api_v1/back_star/',
                            columns: {
                                created_at: {title: '创建时间', type: 'utcTime',},
                            },
                            disableDownload: true,
                        },
                        text__create_button: '新建周期',
                    },
                },
            },
        },
        menu__movies: {
            name: '影音管理',
            table: {
                path: 'api_back/movies/',
                columns: {
                    name: {title: '影视名称', rate: 0.5,},
                    status: {
                        title: '状态', type: 'map', map: {
                            1: '未开始',
                            2: '进行中',
                            4: '待更新',
                            3: '已完结',
                        },
                    },
                    created_at: {title: '添加时间', type: 'utcTime',},
                },
                columnsUpdate: {
                    name: {title: '影视名称',},
                    status: {
                        title: '状态', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '未开始',},
                            {value: 2, help: '观看中',},
                            {value: 4, help: '待更新',},
                            {value: 3, help: '已完结',},
                        ],
                    },
                    movies_type: {
                        title: '类型', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '电影',},
                            {value: 2, help: '连续剧',},
                            {value: 3, help: '读物',},
                            {value: 4, help: '漫画',},
                        ],
                    },
                },
                columnsCreate: {
                    name: {title: '影视名称',},
                    status: {
                        title: '状态', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '未开始',},
                            {value: 2, help: '观看中',},
                            {value: 4, help: '待更新',},
                            {value: 3, help: '已完结',},
                        ],
                    },
                    movies_type: {
                        title: '类型', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '电影',},
                            {value: 2, help: '连续剧',},
                            {value: 3, help: '读物',},
                            {value: 4, help: '漫画',},
                        ],
                    },
                },
                disableDownload: true,
                text__create_button: '增加影视',
                sub__movies: {
                    columnTitle: '分段管理',
                    path: 'api_back/sub_movies/',
                    columns: {
                        name: {title: '分段名',},
                        status: {
                            title: '状态', type: 'map', map: {
                                1: '未开始',
                                2: '进行中',
                                3: '已完结',
                                4: '待更新',
                            },
                        },
                        remark: {title: '备注',},
                    },
                    columnsCreate: {
                        name: {title: '影视名称',},
                        status: {
                            title: '状态', type: 'singleSmallChoice', choices: [
                                {value: 1, help: '未开始',},
                                {value: 2, help: '进行中',},
                                {value: 3, help: '已完结',},
                                {value: 4, help: '待更新',},
                            ],
                        },
                        remark: {title: '备注',},
                    },
                    columnsUpdate: {
                        name: {title: '影视名称',},
                        status: {
                            title: '状态', type: 'singleSmallChoice', choices: [
                                {value: 1, help: '未开始',},
                                {value: 2, help: '进行中',},
                                {value: 3, help: '已完结',},
                                {value: 4, help: '待更新',},
                            ],
                        },
                        remark: {title: '备注',},
                    },
                    disableDownload: true,
                    text__create_button: '创建分段',
                },
                filter__status: {
                    choices: {
                        "1": {
                            text: '未开始',
                        },
                        "2": {
                            text: '观看中',
                        },
                        "4": {
                            text: '待更新',
                        },
                        "3": {
                            text: '已完成',
                        },
                        'all': {
                            text: '全部',
                        },
                    },
                    default: '2',
                    type: 'col',
                },
                filter__movies_type: {
                    choices: {
                        "1": {
                            text: '电影',
                        },
                        "2": {
                            text: '连续剧',
                        },
                        "3": {
                            text: '读物',
                        },
                        "4": {
                            text: '漫画',
                        },
                        'all': {
                            text: '全部',
                        },
                    },
                    default: '2',
                    type: 'row', // row
                },
                text__search_help: '通过名称搜索',
                help: {
                    "text": '<div style="color:red">\n' +
                        '    帮助测试\n' +
                        '</div>',
                },
            },
        },

        menu__sys: {
            name: '系统',
            sub: {
                menu__base_account: {
                    name: '账号',
                    table: {
                        path: 'programmer/user/',
                        columns: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            email: {title: 'email',},
                            role: {title: '角色信息',},
                            cover: {title: '头像', type: 'imgHead',},
                            age: {title: '年龄',},
                            sign: {
                                title: '标记', type: 'map', map: {
                                    0: "未标记",
                                    1: "家庭成员",
                                },
                            },
                        },
                        columnsUpdate: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            email: {title: 'email',},
                            password: {title: '密码',},
                            cover: {title: '头像', type: 'imgHead',},
                            age: {title: '年龄',},
                            test: {title: '测试字段', type: 'react', react: param => <TestField param={param}/>,},
                            sign: {
                                title: '标记', type: 'singleSmallChoice', choices: [
                                    {value: 0, help: "未标记",},
                                    {value: 1, help: "家庭成员",},
                                ],
                            },
                        },
                        text__update_title: '修改这个系统账号',
                        disableDownload: true,
                    },
                },
                menu__wechat_account: {
                    name: "微信帐号",
                    table: {
                        path: "api_back/wechat/",
                        columns: {
                            openid: {title: "openid",},
                            avatarUrl: {title: "微信头像", type: "imgPath"},
                            nickName: {title: "微信昵称"},
                            roles: {title: "系统角色"},
                            remark: {title: "备注"},
                            is_super: {title: "超级用户", type: 'bool', textBool: ["是", "否",],},
                        },
                        columnsUpdate: {
                            avatarUrl: {title: "微信头像"},
                            openid: {title: "openid"},
                            session_key: {title: "session_key"},
                            union_id: {title: "公众号ID"},
                            remark: {title: "备注"},
                            nickName: {title: "微信昵称"},
                            gender: {
                                title: "性别",
                                type: "singleSmallChoice",
                                choices: [
                                    {value: 0, help: "未知"},
                                    {value: 1, help: "男性"},
                                    {value: 2, help: "女性"},
                                ],
                            },
                            is_super: {title: "超级用户", type: 'bool',},
                        },
                        columnsCreate: {
                            avatarUrl: {title: "微信头像"},
                            openid: {title: "openid"},
                            session_key: {title: "session_key"},
                            union_id: {title: "公众号ID"},
                            remark: {title: "备注"},
                            nickName: {title: "微信昵称"},
                            gender: {
                                title: "性别",
                                type: "singleSmallChoice",
                                choices: [
                                    {value: 0, help: "未知"},
                                    {value: 1, help: "男性"},
                                    {value: 2, help: "女性"},
                                ],
                            },
                            is_super: {title: "超级用户", type: 'bool',},
                        },
                        text__update_title: "修改这个微信账号",
                        text__search_help: "通过昵称搜索",
                        disableCopy: true,
                        disableCreate: true,
                    },
                },
                menu__roles: {
                    name: '角色一览',
                    table: {
                        path: 'programmer/role/',
                        columns: {
                            role_name: {title: '角色名',},
                            code: {title: '系统识别名',},
                        },
                        disableDownload: true,
                    },
                },
                menu__setting: {
                    name: '系统设置',
                    table: {
                        path: 'programmer/setting/',
                        columns: {
                            info: {title: '项',},
                            key: {title: '项(机器友好)',},
                            value: {title: '值',},
                            follow: {
                                title: '位置', type: 'map', map: {
                                    1: '跟随数据库',
                                    2: '跟随代码',
                                },
                            },
                            updated_at: {title: '更新时间', type: 'utcTime',},
                        },
                        columnsUpdate: {
                            info: {title: '项',},
                            key: {title: '项(机器友好)',},
                            value: {
                                title: '值',
                                is_show: param => {
                                    let is_choice = tool.getAttribute(param, ['record', 'is_choice',],);
                                    return is_choice !== 1;
                                },
                            },
                            value_choice: {
                                title: '值',
                                type: 'singSearchChoice',
                                path: 'programmer/choices_setting_choices/',
                                is_show: param => {
                                    let is_choice = tool.getAttribute(param, ['record', 'is_choice',],);
                                    return is_choice === 1;
                                },
                            },
                            follow: {
                                title: '跟随', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '跟随数据库',},
                                    {value: 2, help: '跟随代码',},
                                ],
                            },
                        },
                        columnsCreate: {
                            info: {title: '项',},
                            key: {title: '项(机器友好)',},
                            value: {title: '值',},
                            follow: {
                                title: '跟随', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '跟随数据库',},
                                    {value: 2, help: '跟随代码',},
                                ],
                            },
                        },
                        disableDownload: true,
                        text__update_right: '设置',
                        sub__sub_setting_choices: {
                            columnTitle: '选项',
                            path: 'programmer/sub_setting_choices/',
                            columns: {
                                value: {title: '值',},
                                info: {title: '帮助',},
                                index: {title: '排序索引',},
                            },
                            columnsCreate: {
                                value: {title: '值',},
                                info: {title: '帮助',},
                                index: {title: '排序索引',},
                            },
                            columnsUpdate: {
                                value: {title: '值',},
                                info: {title: '帮助',},
                                index: {title: '排序索引',},
                            },
                            disableDownload: true,
                            disableCopy: true,
                            disableCreate: true,
                        },
                    },
                },
                menu__front: {
                    name: '前端静态文件',
                    table: {
                        path: 'programmer/front/',
                        columns: {
                            name: {title: '目录名',},
                            file_count: {title: '文件数量',},
                        },
                        columnsUpdate: {
                            order: {
                                title: '执行指令',
                                type: 'singleSmallChoice',
                                choices: [
                                    {value: 1, help: '发送到服务器',},
                                    {value: 2, help: '上传auto中js大文件到COS/定位index中此js路径修改为speed',},
                                ],
                                path: 'programmer/choices_build_setting/',
                            },
                        },
                        columnsUpdate_1: {
                            order: {
                                title: '执行指令',
                                type: 'singleSmallChoice',
                                choices: [
                                    {value: 2, help: '上传auto中js大文件到css/定位index中此js路径修改为static_speed',},
                                ],
                            },
                        },
                        disableDownload: true,
                        disableDelete: true,
                        text__update_right: '操作',
                    },
                },
                menu__backend: {
                    name: '数据库备份及恢复',
                    table: {
                        path: 'programmer/backend/',
                        columns: {
                            name: {title: '备份文件名', type: 'utcTime',},
                            filesize: {title: '文件大小',},
                            path: {title: '下载链接', type: 'file',},
                            name_origin: {title: '源文件名称',},
                        },
                        columnsCreate: {
                            order: {
                                title: '指令', type: 'singleSmallChoice', choices: [
                                    {value: '1', help: '备份数据库',},
                                    {value: '2', help: '恢复数据库',},
                                    {value: '3', help: '清理无效cloud文件',},
                                ], path: 'programmer/get_order_choices/',
                            },
                            file: {
                                title: '上传sql文件(or zip)',
                                type: 'file',
                                accept: ['.sql', '.zip',],
                                is_show: (param) => {
                                    let infoModalPath = tool.getAttribute(param, 'infoModalPath');
                                    let infoModal = tool.getStateSpace(infoModalPath);
                                    let order = tool.getAttribute(infoModal,
                                        ['paramModal', 'create__x', 'order',]
                                    )
                                    return (order === '2') || (order === '5');
                                },
                            },
                            password: {
                                title: '恢复密码', is_show: (param) => {
                                    let infoModalPath = tool.getAttribute(param, 'infoModalPath');
                                    let infoModal = tool.getStateSpace(infoModalPath);
                                    let order = tool.getAttribute(infoModal,
                                        ['paramModal', 'create__x', 'order',]
                                    )
                                    return (order === '2') || (order === '5');
                                },
                            },
                        },
                        text__create_button: '发送指令',
                        disableDownload: true,
                        disableCreate: true,
                        disableCopy: true,
                    },
                },
                menu__cloud: {
                    name: '抽象文件',
                    table: {
                        path: 'api_v1/cloud/',
                        columns: {
                            file_type: {title: '文件类型',},
                            path_local: {title: '本地路径',},
                            path_cloud: {title: '云端路径',},
                            use_path: {
                                title: '文件位置', type: 'map', map: {
                                    1: '本地',
                                    2: '云端',
                                },
                            },
                            relation_count: {title: '关联数量',},
                        },
                        columnsDetailLine: {
                            file_type: {title: '文件类型',},
                            path_local: {title: '本地路径',},
                            path_cloud: {title: '云端路径',},
                            use_path: {
                                title: '文件位置', type: 'map', map: {
                                    1: '本地',
                                    2: '云端',
                                },
                            },
                            relation_count: {title: '关联数量',},
                        },
                        columnsUpdate: {
                            order: {
                                title: '命令', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '此服务器端文件迁移到oss云',},
                                    {value: 2, help: '此oss云文件迁移到服务器',},
                                    // {value: 3, help: '删除此文件',},
                                ],
                            },
                        },
                        columnsCreate: {
                            order: {
                                title: '命令', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '清理所有孤儿文件',},
                                    {value: 2, help: '服务器端文件全部迁移到oss云',},
                                    {value: 3, help: 'oss云文件全部迁移到服务器',},
                                ],
                            },
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                        disableDelete: true,

                        text__update_right: '局部指令',
                        text__create_button: '全局指令',
                        text__detail_right: '详细信息',

                        filters: {
                            choices: {
                                'all': {
                                    text: '全部',
                                },
                                'clear': {
                                    text: '孤儿文件',
                                },
                            },
                            default: 'all',
                        },
                    },
                },
                menu__robot_info: {
                    name: '机器人消息',
                    table: {
                        path: 'programmer/robot_info/',
                        columns: {
                            created_at: {title: '创建时间', type: 'utcTime',},
                            info: {title: '消息',},
                            info_type: {title: '消息类型',},
                            // robot_obj: {title: '文件类型',},
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                    },
                },
                menu__robot_public: {
                    name: '机器人全局信息',
                    table: {
                        path: 'programmer/robot_public/',
                        columns: {
                            last_year: {title: '年级任务最后执行时间', type: 'utcTime',},
                            executing_year: {
                                title: '正在执行年级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_month: {title: '月级任务最后执行时间', type: 'utcTime',},
                            executing_month: {
                                title: '正在执行月级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_day: {title: '日级任务最后执行时间', type: 'utcTime',},
                            executing_day: {
                                title: '正在执行日级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_hour: {title: '时级任务最后执行时间', type: 'utcTime',},
                            executing_hour: {
                                title: '正在执行时级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_minute: {title: '分级任务最后执行时间', type: 'utcTime',},
                            executing_minute: {
                                title: '正在执行分级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },
                        },
                        columnsDetailLine: {
                            last_year: {title: '年级任务最后执行时间', type: 'utcTime',},
                            executing_year: {
                                title: '正在执行年级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_month: {title: '月级任务最后执行时间', type: 'utcTime',},
                            executing_month: {
                                title: '正在执行月级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_day: {title: '日级任务最后执行时间', type: 'utcTime',},
                            executing_day: {
                                title: '正在执行日级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_hour: {title: '时级任务最后执行时间', type: 'utcTime',},
                            executing_hour: {
                                title: '正在执行时级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_minute: {title: '分级任务最后执行时间', type: 'utcTime',},
                            executing_minute: {
                                title: '正在执行分级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },
                        },
                        columnsUpdate: {
                            last_year: {title: '年级任务最后执行时间', type: 'time',},
                            executing_year: {title: '正在执行年级任务', type: 'bool',},

                            last_month: {title: '月级任务最后执行时间', type: 'time',},
                            executing_month: {title: '正在执行月级任务', type: 'bool',},

                            last_day: {title: '日级任务最后执行时间', type: 'time',},
                            executing_day: {title: '正在执行日级任务', type: 'bool',},

                            last_hour: {title: '时级任务最后执行时间', type: 'time',},
                            executing_hour: {title: '正在执行时级任务', type: 'bool',},

                            last_minute: {title: '分级任务最后执行时间', type: 'time',},
                            executing_minute: {title: '正在执行分级任务', type: 'bool',},
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                    },
                },
                menu__weight_data: {
                    name: '录入体重',
                    table: {
                        path: 'api_back/weight/',
                        columns: {
                            point: {title: '日期',},
                            weight: {title: '实际体重',},
                            target: {title: '计划体重',},
                            jump: {title: '应跳绳数量',},
                        },
                        columnsUpdate: {
                            weight: {title: '实际体重',},
                        },
                        disableDownload: true,
                        // disableDelete: true,
                    },
                },
            },
        },
        menu__special: {
            name: '纪念日',
            table: {
                path: 'api_back/special_day/',
                columns: {
                    name: {title: '纪念日',},
                    day: {title: '阳历', rate: 0.5,},
                    lunar_str: {title: '农历',},
                    trigger: {
                        title: '触发方式', type: 'map', map: {
                            1: '阳历',
                            2: '农历',
                        },
                        rate: 0.5,
                    },
                    trigger_time: {title: '触发时间', type: 'chinaTime',},
                    time_remaining: {title: '剩余天数', render: value => `${value}天`, rate: 0.5,},
                },
                columnsUpdate: {
                    name: {title: '纪念日',},
                    day: {title: '日期', type: 'timeDay', help: '录入阳历日期',},
                    trigger: {
                        title: '触发方式', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '阳历',},
                            {value: 2, help: '农历',},
                        ],
                    },
                },
                columnsCreate: {
                    name: {title: '纪念日',},
                    day: {title: '日期', type: 'timeDay', help: '录入阳历日期',},
                    trigger: {
                        title: '触发方式', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '阳历',},
                            {value: 2, help: '农历',},
                        ],
                    },
                },
                disableDownload: true,
                text__create_button: '增加纪念日',
            },
        },
        menu__item: {
            name: '物品退役日管理',
            table: {
                path: 'api_back/item/',
                columns: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    total: {title: '合计花费', render: value => `${value}元`,},
                    budget_start_date: {title: '预算起始日', type: 'chinaTime',},
                    retirement_date: {title: '退役日', type: 'chinaTime',},
                    time_remaining: {
                        title: '剩余天数', render: value => {
                            if (!value) return value;
                            return `${value}天`;
                        },
                    },
                    // remark: {title: '备注',},
                    // sub: {title: '关联1',},
                    sub_help: {title: '关联物品',},
                },
                columnsDetailLine: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'chinaTime',},
                    retirement_date: {title: '退役日', type: 'chinaTime',},
                    time_remaining: {
                        title: '剩余天数', render: value => {
                            if (!value) return value;
                            return `${value}天`;
                        },
                    },
                    remark: {title: '备注',},
                    sub: {title: '关联物品ID',},
                    sub_help: {title: '关联物品',},
                },
                columnsUpdate: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'timeDay',},
                    remark: {title: '备注',},
                    sub: {title: '关联项目', type: 'multipleChoice', path: 'api_back/choices_item/',},
                },
                columnsCreate: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'timeDay',},
                    remark: {title: '备注',},
                },
                disableDownload: true,
                text__create_button: '增加退役计划',
                sub__expense: {
                    columnTitle: '消费记录',
                    path: 'api_back/sub_expense/',
                    columns: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'chinaTime',},
                        price: {
                            title: '消费金额', render: value => {
                                if (!value) return value;
                                return `${value}元`;
                            },
                        },
                        days: {
                            title: '贡献天数', render: value => {
                                if (!value) return value;
                                return `${value}天`;
                            },
                        },
                        remark: {title: '备注',},
                    },
                    columnsCreate: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'timeDay',},
                        price: {title: '消费金额',},
                        remark: {title: '备注',},
                    },
                    columnsUpdate: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'timeDay',},
                        price: {title: '消费金额',},
                        remark: {title: '备注',},
                    },
                    disableDownload: true,
                    text__create_button: '增加消费记录',
                },

            },
        },


        menu__ebook: {
            name: '电子书',
            sub: {
                menu__account: {
                    name: '账号',
                    table: {
                        path: 'api_v1/self/',
                        columns: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            cover_read: {title: '头像', type: 'imgHead',},
                            age: {title: '年龄',},
                            code: {title: '识别码',},
                        },
                        columnsUpdate: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            password: {title: '密码',},
                            cover: {title: '头像', type: 'imgHead',},
                            age: {title: '年龄',},
                        },
                        text__update_title: '修改这个系统账号',
                        disableDownload: true,
                        disableDelete: true,
                        trigger__set_code: {
                            columnTitle: '操作',
                            textButton: '重设code',
                            func: triggerSetCode,
                        },
                    },
                },
                menu__book: {
                    name: '电子书上传',
                    table: {
                        path: 'api_back/book/',
                        columns: {
                            book_name: {title: '书名',},
                            book_url: {title: '链接', type: 'file',},
                            read: {
                                title: '阅读状态', type: 'map', map: {
                                    'read': '已读',
                                    'un_read': '未读',
                                    'reading': '在读',
                                },
                            },
                            // url_name: {title: '链接简称', type: 'file',},
                            // ebook: {title: '供kindle访问', type: 'bool', textBool: ['kindle可访问', '非kindle',],},
                        },
                        columnsUpdate: {
                            book_obj: {title: '文件', type: 'file',},
                            // ebook: {title: '供kindle访问', type: 'bool',},
                            read: {
                                title: '阅读状态', type: 'singleSmallChoice', choices: [
                                    {value: 'read', help: '已读',},
                                    {value: 'un_read', help: '未读',},
                                    {value: 'reading', help: '在读',},
                                ],
                            },
                        },
                        columnsCreate: {
                            book_obj: {title: '文件', type: 'file',},
                            // url_name: {title: '链接简称',},
                        },
                        disableDownload: true,
                        text__create_button: '上传一本书',
                        trigger__up: {
                            columnTitle: '操作',
                            textButton: '上移',
                            func: triggerUp,
                        },
                        trigger__down: {
                            columnTitle: '操作',
                            textButton: '下移',
                            func: triggerDown,
                        },
                        filters: {
                            choices: {
                                'un_read': {
                                    text: '未读',
                                },
                                'read': {
                                    text: '已读',
                                },
                                'reading': {
                                    text: '在读',
                                },
                            },
                            default: 'reading',
                        },
                    },
                },
            },
        },
        menu__weight: {
            name: '体重管理',
            sub: {
                menu__weight_data: {
                    name: '录入体重',
                    table: {
                        path: 'api_back/weight/',
                        columns: {
                            point: {title: '日期',},
                            weight: {title: '实际体重',},
                            // target: {title: '计划体重',},
                            // jump: {title: '应跳绳数量',},
                            event: {title: '事件',},
                        },
                        columnsUpdate: {
                            weight: {title: '实际体重',},
                            event: {title: '事件',},
                        },
                        disableDownload: true,
                        // disableDelete: true,
                    },
                },
                menu__weight_echarts: {
                    // react: <WeightEcharts/>,
                    react: "WeightEcharts",
                    name: '体重折线图',
                },
            },
        },
        menu__cycle: {
            name: '吃什么',
            table: {
                path: 'api_v1/cycle/',
                columns: {
                    index: {title: '索引',},
                    finish: {title: '是否完成',},
                    dogs: {title: '成员',},
                },
                disableDownload: true,
                sub__dog: {
                    columnTitle: '成员',
                    path: 'api_v1/dog/',
                    columns: {
                        name: {title: '名字',},
                        count: {title: '选择数量',},
                    },
                    columnsUpdate: {
                        name: {title: '名字',},
                        count: {title: '选择数量',},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                },
            },
        },
        menu__statics: {
            name: '全局静态文件',
            table: {
                path: 'api_back/statics/',
                columns: {
                    name: {title: '文件名',},
                    link: {title: '链接',},
                },
                columnsCreate: {
                    name: {title: '文件名',},
                    file_obj: {title: '文件', type: 'file',},
                },
                columnsUpdate_1: {
                    name: {title: '文件名1',},
                },
                columnsUpdate_2: {
                    file_obj: {title: '文件', type: 'file',},
                },
                disableDownload: true,
            },
        },
        menu__conf: {
            name: '配置文件',
            table: {
                path: 'admin_a/conf_list/',
                columns: {
                    created_at: {title: '创建时间', type: 'utcTime',},
                    updated_at: {title: '更新时间', type: 'utcTime',},
                    data: {title: '数据',},
                    close: {title: '是否关闭', type: 'bool', textBool: ['关闭', '开启',],},
                },
            },
        },
    },
    user: {
        webTitle: 'assistant系统-用户后台',
        systemTitle: '用户后台',
        footer: RobotFooter,
        menu__base_account: {
            name: '我的账号',
            table: {
                path: 'programmer/user/',
                columns: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    role: {title: '角色信息',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                columnsUpdate: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    password: {title: '密码',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                text__update_title: '修改信息',
                disableDownload: true,
                disableDelete: true,
            },
        },
        menu__special: {
            name: '我的纪念日',
            table: {
                path: 'api_back/special_day/',
                columns: {
                    name: {title: '纪念日',},
                    day: {title: '阳历',},
                    lunar_str: {title: '农历',},
                    trigger: {
                        title: '触发方式', type: 'map', map: {
                            1: '阳历',
                            2: '农历',
                        },
                    },
                    trigger_time: {title: '触发时间', type: 'chinaTime',},
                    time_remaining: {title: '剩余天数', render: value => `${value}天`,},
                },
                columnsUpdate: {
                    name: {title: '纪念日',},
                    day: {title: '日期', type: 'timeDay', help: '录入阳历日期',},
                    trigger: {
                        title: '触发方式', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '阳历',},
                            {value: 2, help: '农历',},
                        ],
                    },
                },
                columnsCreate: {
                    name: {title: '纪念日',},
                    day: {title: '日期', type: 'timeDay', help: '录入阳历日期',},
                    trigger: {
                        title: '触发方式', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '阳历',},
                            {value: 2, help: '农历',},
                        ],
                    },
                },
                disableDownload: true,
                text__create_button: '增加纪念日',
            },
        },
        menu__item: {
            name: '物品退役日管理',
            table: {
                path: 'api_back/item/',
                columns: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'chinaTime',},
                    retirement_date: {title: '退役日', type: 'chinaTime',},
                    time_remaining: {
                        title: '剩余天数', render: value => {
                            if (!value) return value;
                            return `${value}天`;
                        },
                    },
                    // remark: {title: '备注',},
                    // sub: {title: '关联1',},
                    sub_help: {title: '关联物品',},
                },
                columnsDetailLine: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'chinaTime',},
                    retirement_date: {title: '退役日', type: 'chinaTime',},
                    time_remaining: {
                        title: '剩余天数', render: value => {
                            if (!value) return value;
                            return `${value}天`;
                        },
                    },
                    remark: {title: '备注',},
                    sub: {title: '关联物品ID',},
                    sub_help: {title: '关联物品',},
                },
                columnsUpdate: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'timeDay',},
                    remark: {title: '备注',},
                    sub: {title: '关联项目', type: 'multipleChoice', path: 'api_back/choices_item/',},
                },
                columnsCreate: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'timeDay',},
                    remark: {title: '备注',},
                },
                disableDownload: true,
                text__create_button: '增加退役计划',
                sub__expense: {
                    columnTitle: '消费记录',
                    path: 'api_back/sub_expense/',
                    columns: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'chinaTime',},
                        price: {
                            title: '消费金额', render: value => {
                                if (!value) return value;
                                return `${value}元`;
                            },
                        },
                        days: {
                            title: '贡献天数', render: value => {
                                if (!value) return value;
                                return `${value}天`;
                            },
                        },
                        remark: {title: '备注',},
                    },
                    columnsCreate: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'timeDay',},
                        price: {title: '消费金额',},
                        remark: {title: '备注',},
                    },
                    columnsUpdate: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'timeDay',},
                        price: {title: '消费金额',},
                        remark: {title: '备注',},
                    },
                    disableDownload: true,
                    text__create_button: '增加消费记录',
                },

            },
        },

        menu__movies: {
            name: '观影管理',
            table: {
                path: 'api_back/movies/',
                columns: {
                    name: {title: '影视名称',},
                    status: {
                        title: '状态', type: 'map', map: {
                            1: '未开始',
                            2: '进行中',
                            4: '待更新',
                            3: '已完结',
                        },
                    },
                    created_at: {title: '添加时间', type: 'utcTime',},
                },
                columnsUpdate: {
                    name: {title: '影视名称',},
                    status: {
                        title: '状态', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '未开始',},
                            {value: 2, help: '观看中',},
                            {value: 4, help: '待更新',},
                            {value: 3, help: '已完结',},
                        ],
                    },
                },
                columnsCreate: {
                    name: {title: '影视名称',},
                    status: {
                        title: '状态', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '未开始',},
                            {value: 2, help: '观看中',},
                            {value: 4, help: '待更新',},
                            {value: 3, help: '已完结',},
                        ],
                    },
                },
                disableDownload: true,
                text__create_button: '增加影视',
                sub__movies: {
                    columnTitle: '分段管理',
                    path: 'api_back/sub_movies/',
                    columns: {
                        name: {title: '分段名',},
                        status: {
                            title: '状态', type: 'map', map: {
                                1: '未开始',
                                2: '进行中',
                                3: '已完结',
                                4: '待更新',
                            },
                        },
                        remark: {title: '备注',},
                    },
                    columnsCreate: {
                        name: {title: '影视名称',},
                        status: {
                            title: '状态', type: 'singleSmallChoice', choices: [
                                {value: 1, help: '未开始',},
                                {value: 2, help: '进行中',},
                                {value: 3, help: '已完结',},
                                {value: 4, help: '待更新',},
                            ],
                        },
                        remark: {title: '备注',},
                    },
                    columnsUpdate: {
                        name: {title: '影视名称',},
                        status: {
                            title: '状态', type: 'singleSmallChoice', choices: [
                                {value: 1, help: '未开始',},
                                {value: 2, help: '进行中',},
                                {value: 3, help: '已完结',},
                                {value: 4, help: '待更新',},
                            ],
                        },
                        remark: {title: '备注',},
                    },
                    disableDownload: true,
                    text__create_button: '创建分段',
                },
                filters: {
                    choices: {
                        "1": {
                            text: '未开始',
                        },
                        "2": {
                            text: '观看中',
                        },
                        "4": {
                            text: '待更新',
                        },
                        "3": {
                            text: '已完结',
                        },
                        'all': {
                            text: '全部',
                        },
                    },
                    default: '2',
                },
                text__search_help: '通过名称搜索',
            },
        },
        // menu__weight_echarts: {
        //     react: <WeightEcharts/>,
        //     name: '体重图表',
        // },
        menu__book: {
            name: '电子书上传',
            table: {
                path: 'api_back/book/',
                columns: {
                    book_name: {title: '书名',},
                    book_url: {title: '链接',},
                    url_name: {title: '链接简称',},
                    ebook: {title: '供kindle访问', type: 'bool', textBool: ['kindle可访问', '非kindle',],},
                },
                columnsUpdate: {
                    book_obj: {title: '文件', type: 'file',},
                    url_name: {title: '链接简称',},
                    ebook: {title: '供kindle访问', type: 'bool',},
                },
                columnsCreate: {
                    book_obj: {title: '文件', type: 'file',},
                    url_name: {title: '链接简称',},
                },
                disableDownload: true,
            },
        },


        // menu__weight: {
        //     name: '体重管理',
        //     sub: {
        //         menu__weight_data: {
        //             name: '录入体重',
        //             table: {
        //                 path: 'api_back/weight/',
        //                 columns: {
        //                     point: {title: '日期',},
        //                     weight: {title: '实际体重',},
        //                     target: {title: '计划体重',},
        //                     jump: {title: '应跳绳数量',},
        //                 },
        //                 columnsUpdate: {
        //                     weight: {title: '实际体重',},
        //                 },
        //                 disableDownload: true,
        //                 // disableDelete: true,
        //             },
        //         },
        //     },
        // },
        menu1__weather: {
            name: '天气',
            table: {
                path: 'api_back/weather/',
                columns: {
                    id: {title: 'ID',},
                    reporttime: {title: '数据发布的时间', type: 'chinaTime',},
                    city: {title: '城市',},
                    humidity: {title: '空气湿度', render: value => `${value}%`,},
                    temperature: {title: '实时气温(摄氏度)', render: value => `${value}°C`,},
                    weather: {title: '天气现象',},
                },
                columnsDetailLine: {
                    reporttime: {title: '数据发布的时间', type: 'chinaTime',},
                    city: {title: '城市',},
                    humidity: {title: '空气湿度', render: value => `${value}%`,},
                    temperature: {title: '实时气温(摄氏度)', render: value => `${value}°C`,},
                    weather: {title: '天气现象',},
                    winddirection: {title: '风向',},
                    windpower: {title: '风力', render: value => `${value}级`,},
                },
                disableDownload: true,
                disableDelete: true,
            },
        },
    },
    user_test: {
        webTitle: 'assistant系统-测试用户后台',
        systemTitle: '测试用户后台(2秒内点击右键2次中键1次左键2次进入开发模式)',
        footer: RobotFooter,
        menu__base_account: {
            name: '我的账号',
            table: {
                path: 'programmer/user/',
                columns: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    role: {title: '角色信息',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                columnsDetailLine: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    role: {title: '角色信息',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                columnsUpdate: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    password: {title: '密码',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                columnsCreate: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    password: {title: '密码',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                columnsUpdate_1: {
                    name: {title: '名字',},
                    username: {title: '系统帐号',},
                    password: {title: '密码',},
                    cover: {title: '头像', type: 'imgHead',},
                },
                text__update_title: '修改信息',
                text__detail_right: '详细',
                text__update_part_right: 'xx',
                text__delete: '删',
                disableDownload: true,
                // disableDelete: true,
                disableCopy: true,
                // disableCreate
            },
        },
        menu__special: {
            name: '我的纪念日',
            table: {
                path: 'api_back/special_day/',
                columns: {
                    name: {title: '纪念日',},
                    day: {title: '阳历',},
                    lunar_str: {title: '农历',},
                    trigger: {
                        title: '触发方式', type: 'map', map: {
                            1: '阳历',
                            2: '农历',
                        },
                    },
                    trigger_time: {title: '触发时间', type: 'chinaTime',},
                    time_remaining: {title: '剩余天数', render: value => `${value}天`,},
                },
                columnsUpdate: {
                    name: {title: '纪念日',},
                    day: {title: '日期', type: 'timeDay', help: '录入阳历日期',},
                    trigger: {
                        title: '触发方式', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '阳历',},
                            {value: 2, help: '农历',},
                        ],
                    },
                },
                columnsCreate: {
                    name: {title: '纪念日',},
                    day: {title: '日期', type: 'timeDay', help: '录入阳历日期',},
                    trigger: {
                        title: '触发方式', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '阳历',},
                            {value: 2, help: '农历',},
                        ],
                    },
                },
                disableDownload: true,
                text__create_button: '增加纪念日',
            },
        },
        menu__item: {
            name: '物品退役日管理',
            table: {
                path: 'api_back/item/',
                columns: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'chinaTime',},
                    retirement_date: {title: '退役日', type: 'chinaTime',},
                    time_remaining: {
                        title: '剩余天数', render: value => {
                            if (!value) return value;
                            return `${value}天`;
                        },
                    },
                    // remark: {title: '备注',},
                    // sub: {title: '关联1',},
                    sub_help: {title: '关联物品',},
                },
                columnsDetailLine: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'chinaTime',},
                    retirement_date: {title: '退役日', type: 'chinaTime',},
                    time_remaining: {
                        title: '剩余天数', render: value => {
                            if (!value) return value;
                            return `${value}天`;
                        },
                    },
                    remark: {title: '备注',},
                    sub: {title: '关联物品ID',},
                    sub_help: {title: '关联物品',},
                },
                columnsUpdate: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'timeDay',},
                    remark: {title: '备注',},
                    sub: {title: '关联项目', type: 'multipleChoice', path: 'api_back/choices_item/',},
                },
                columnsCreate: {
                    main_item_name: {title: '主要物品名',},
                    annual_budget: {title: '每年预算',},
                    budget_start_date: {title: '预算起始日', type: 'timeDay',},
                    remark: {title: '备注',},
                },
                disableDownload: true,
                text__create_button: '增加退役计划',
                sub__expense: {
                    columnTitle: '消费记录',
                    path: 'api_back/sub_expense/',
                    columns: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'chinaTime',},
                        price: {
                            title: '消费金额', render: value => {
                                if (!value) return value;
                                return `${value}元`;
                            },
                        },
                        days: {
                            title: '贡献天数', render: value => {
                                if (!value) return value;
                                return `${value}天`;
                            },
                        },
                        remark: {title: '备注',},
                    },
                    columnsCreate: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'timeDay',},
                        price: {title: '消费金额',},
                        remark: {title: '备注',},
                    },
                    columnsUpdate: {
                        expense_name: {title: '消费名称',},
                        date: {title: '消费日期', type: 'timeDay',},
                        price: {title: '消费金额',},
                        remark: {title: '备注',},
                    },
                    disableDownload: true,
                    text__create_button: '增加消费记录',
                },

            },
        },

        menu__movies: {
            name: '观影管理',
            table: {
                path: 'api_back/movies/',
                columns: {
                    name: {title: '影视名称', rate: 0.5,},
                    status: {
                        title: '状态', type: 'map', map: {
                            1: '未开始',
                            2: '进行中',
                            4: '待更新',
                            3: '已完结',
                        },
                    },
                    created_at: {title: '添加时间', type: 'utcTime',},
                },
                columnsDetailLine: {
                    name: {title: '影视名称', rate: 0.5,},
                    status: {
                        title: '状态', type: 'map', map: {
                            1: '未开始',
                            2: '进行中',
                            4: '待更新',
                            3: '已完结',
                        },
                    },
                    created_at: {title: '添加时间', type: 'utcTime',},
                },
                columnsUpdate: {
                    // name: {title: '影视名称',},
                    // status: {
                    //     title: '状态', type: 'singleSmallChoice', choices: [
                    //         {value: 1, help: '未开始',},
                    //         {value: 2, help: '观看中',},
                    //         {value: 4, help: '待更新',},
                    //         {value: 3, help: '已完结',},
                    //     ],
                    // },
                },
                columnsCreate: {
                    name: {title: '影视名称',},
                    status: {
                        title: '状态', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '未开始',},
                            {value: 2, help: '观看中',},
                            {value: 4, help: '待更新',},
                            {value: 3, help: '已完结',},
                        ],
                    },
                },
                disableDownload: true,
                text__create_button: '增加影视',
                sub__movies: {
                    columnTitle: '分段管理',
                    path: 'api_back/sub_movies/',
                    columns: {
                        name: {title: '分段名',},
                        status: {
                            title: '状态', type: 'map', map: {
                                1: '未开始',
                                2: '进行中',
                                3: '已完结',
                                4: '待更新',
                            },
                        },
                        remark: {title: '备注',},
                    },
                    columnsCreate: {
                        name: {title: '影视名称',},
                        status: {
                            title: '状态', type: 'singleSmallChoice', choices: [
                                {value: 1, help: '未开始',},
                                {value: 2, help: '进行中',},
                                {value: 3, help: '已完结',},
                                {value: 4, help: '待更新',},
                            ],
                        },
                        remark: {title: '备注',},
                    },
                    columnsUpdate: {
                        name: {title: '影视名称',},
                        status: {
                            title: '状态', type: 'singleSmallChoice', choices: [
                                {value: 1, help: '未开始',},
                                {value: 2, help: '进行中',},
                                {value: 3, help: '已完结',},
                                {value: 4, help: '待更新',},
                            ],
                        },
                        remark: {title: '备注',},
                    },
                    disableDownload: true,
                    text__create_button: '创建分段',
                },
                filters: {
                    choices: {
                        "1": {
                            text: '未开始',
                        },
                        "2": {
                            text: '观看中',
                        },
                        "4": {
                            text: '待更新',
                        },
                        "3": {
                            text: '已完结',
                        },
                        'all': {
                            text: '全部',
                        },
                    },
                    default: '2',
                },
                text__search_help: '通过名称搜索',
                // text__detail_right:
            },
        },
        menu__weather: {
            name: '天气',
            table: {
                path: 'api_back/weather/',
                columns: {
                    id: {title: 'ID',},
                    reporttime: {title: '数据发布的时间', type: 'chinaTime',},
                    city: {title: '城市',},
                    humidity: {title: '空气湿度', render: value => `${value}%`,},
                    temperature: {title: '实时气温(摄氏度)', render: value => `${value}°C`,},
                    weather: {title: '天气现象',},
                },
                columnsDetailLine: {
                    reporttime: {title: '数据发布的时间', type: 'chinaTime',},
                    city: {title: '城市',},
                    humidity: {title: '空气湿度', render: value => `${value}%`,},
                    temperature: {title: '实时气温(摄氏度)', render: value => `${value}°C`,},
                    weather: {title: '天气现象',},
                    winddirection: {title: '风向',},
                    windpower: {title: '风力', render: value => `${value}级`,},
                },
                disableDownload: true,
                disableDelete: true,
            },
        },

        menu__sys: {
            name: '系统',
            sub: {
                _menu__base_account: {
                    name: '账号',
                    table: {
                        path: 'programmer/user/',
                        columns: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            role: {title: '角色信息',},
                            cover: {title: '头像', type: 'imgHead',},
                            age: {title: '年龄',},
                            sign: {
                                title: '标记', type: 'map', map: {
                                    0: "未标记",
                                    1: "家庭成员",
                                },
                            },
                        },
                        columnsUpdate: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            password: {title: '密码',},
                            cover: {title: '头像', type: 'imgHead',},
                            age: {title: '年龄',},
                            test: {title: '测试字段', type: 'react', react: param => <TestField param={param}/>,},
                            sign: {
                                title: '标记', type: 'singleSmallChoice', choices: [
                                    {value: 0, help: "未标记",},
                                    {value: 1, help: "家庭成员",},
                                ],
                            },
                        },
                        text__update_title: '修改这个系统账号',
                        disableDownload: true,
                        sub__wechat: {
                            columnTitle: '绑定的微信',
                            path: 'programmer/sub_wechat/',
                            columns: {
                                avatarUrl: {title: '微信头像', type: 'imgShow',},
                                openid: {title: 'openid',},
                                nickName: {title: '微信昵称',},
                                gender: {
                                    title: '性别', type: 'map', map: {
                                        0: '未知',
                                        1: '男性',
                                        2: '女性',
                                    },
                                },
                                country: {title: '国家',},
                                province: {title: '省份',},
                                city: {title: '城市',},
                                language: {
                                    title: '语言', type: 'map', map: {
                                        'en': '英文',
                                        'zh_CN': '简体中文',
                                        'zh_TW': '繁体中文',
                                    },
                                },
                            },
                            disableDownload: true,
                            disableCopy: true,
                            disableCreate: true,
                        },
                    },
                },
            },
        },
    },
    selectedMenu: {},
    fieldMapping: fieldMapping,
    fieldDetailMapping: fieldDetailMapping,
    bottom: <Bottom/>,
    pageMap: {
        'win_hub': {
            title: '积分小助手',
            react: <WinHubComponent/>,
            onTitle: false,
        },
        'mc': {
            title: '纸模',
            react: <McComponent/>,

        },
        'album': {
            title: '轨迹相册',
            react: <Album/>,

        },
        'money': {
            title: '转移支付',
            react: <MoneyComponent/>,
        },
        'flow': {
            title: '流程',
            react: <Flow/>,
        },
        'chat_reg': {
            title: '账号注册',
            react: <ChatRegComponent/>,
        },
        'progress': {
            title: '作品进度',
            react: <ArtProgress/>,
        },
    },
    code: 'assistant',
    // confUrl: window.location.href + 'conf/',
    // confUrl: 'programmer/auto_conf/',
    confUrl: '',
    open_edit: true,
};

// todo 开发模式下
setting['confUrl'] = setting['confUrl'].replace('3000', '8000',)


export {setting, Text, funcMapping,}
