import React from "react";

class Bottom extends React.Component {
    render() {

        const recordInfoStyle = {
            fontSize: "12px",
            color: "#fff",
            textAlign: "center",
            padding: "10px 0",
            background: "linear-gradient(to right, #7c7c7c, #595959, #7c7c7c)",
            border: "1px solid #000",
            borderRadius: "5px",
        };
        const recordLinkStyle = {
            color: "#fff",
            textDecoration: "none",
        };


        return (
            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    // border: 'blue solid 1px',
                    width: '100%',
                }}
            >
                <div style={recordInfoStyle}>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow noreferrer" style={recordLinkStyle}>
                        鲁ICP备2021037911号-1
                    </a>
                </div>

            </div>
        );
    }
}

export {Bottom};
