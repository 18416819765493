import {connect} from "react-redux";
import React from 'react';
import {tool} from "../../tools/tools";
import {CloudSyncOutlined, DeleteOutlined, PictureOutlined, TableOutlined} from "@ant-design/icons";
import {InputNumber, Switch} from "antd";
import html2canvas from 'html2canvas';

const mcPath = ['mc'];
const imageSrcPath = mcPath.concat(['imageSrc',]);// 选择的图片的src
const selectedColorsPath = mcPath.concat(['selectedColors']); // 颜色盒
const selectedColorPath = mcPath.concat(['selectedColor']); // 选中的颜色
const unitWidthPath = mcPath.concat(['unitWidth']); // 单元宽度
const unitHeightPath = mcPath.concat(['unitHeight']); // 单元高度
const dataColorsPath = mcPath.concat(['dataColors']); // 单元颜色
const hiddenLinePath = mcPath.concat(['hiddenLine']); // 是否隐藏标识线
const intelligentLoadingPath = mcPath.concat(['intelligentLoading']); // 是否正在智能提取
const lightRatePath = mcPath.concat(['lightRate']); // 亮度倍率

function rgba2string(rgbaArray) {
    const r = rgbaArray[0];
    const g = rgbaArray[1];
    const b = rgbaArray[2];
    const a = rgbaArray[3];
    return `rgba(${r},${g},${b},${a})`;
}

function string2rgba(string) {
    var match = string.match(/\d+/g);
    if (match && match.length === 4) {
        const red = parseInt(match[0]);
        const green = parseInt(match[1]);
        const blue = parseInt(match[2]);
        const alpha = parseInt(match[3]);
        return [red, green, blue, alpha];
    } else {
        // 如果匹配失败或者不是四个数字，返回空对象或者根据需要处理错误情况
        return {};
    }
}

// 图片颜色选择器 点我
class ImagePixelGetter extends React.Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.inputRef = React.createRef();
        this.canvasRef = React.createRef();

        this.inputOnChange = this.inputOnChange.bind(this);
    }

    // 点击像素
    imageOnClick(event,) {
        const imgObj = this.imageRef.current
        const canvasObj = document.createElement('canvas');
        const ctx = canvasObj.getContext('2d');
        canvasObj.width = imgObj.width;
        canvasObj.height = imgObj.height;
        ctx.drawImage(imgObj, 0, 0);


        const x = event.offsetX;
        const y = event.offsetY;
        const pixelData = ctx.getImageData(x, y, 1, 1).data;

        // 绘制红色div
        const div = document.createElement('div');
        div.style.width = '5px';
        div.style.height = '5px';
        div.style.borderRadius = '50%';
        div.style.backgroundColor = 'red';
        div.style.position = 'absolute';
        div.style.left = event.clientX + 'px';
        div.style.top = event.clientY + 'px';

        document.body.appendChild(div);


        // 记录
        let newSelectedColors = [pixelData];
        let selectedColors = tool.getStateSpace(selectedColorsPath, []);

        for (const selectedColor of selectedColors) {
            if (selectedColor.toString() === pixelData.toString()) continue;
            newSelectedColors.push(selectedColor);
        }
        // newSelectedColors.push(pixelData);

        tool.setStateSpace(selectedColorsPath, newSelectedColors);
        tool.setStateSpace(selectedColorPath, rgba2string(pixelData));
    }

    // 换图
    inputOnChange(event) {
        const files = event.target.files;
        if (files.length !== 1) return;
        const file = files[0];

        // 发送到服务器
        const path = 'programmer/resize_image/';
        const url = tool.path_join(path);

        let dataObj = new FormData();
        dataObj.append('img', file);
        dataObj.append('max_width', 500);
        dataObj.append('max_height', 500);

        tool.post({
            url: url,
            data: dataObj,

            contentType: false,
            processData: false,

            success: data => {
                const imgSrc = data['img'];
                const imgObj = this.imageRef.current
                tool.setStateSpace(imageSrcPath, imgSrc);
                imgObj.onmousedown = event => this.imageOnClick(event,);

            },
            error: data => {
            },
        });
    }


    render() {
        // 图片地址
        let imageSrc = tool.getStateSpace(imageSrcPath);

        return <div style={{
            border: 'black 1px solid',
            // width: '500px',
        }}>

            {/*图片显示*/}
            <div style={{
                // width: '500px',
                // minHeight: '200px',
                // border: 'black 1px solid',
            }}>
                <img
                    ref={this.imageRef}
                    src={imageSrc}
                    alt="请选择图片"
                    style={{
                        // width: '500px',
                        display: imageSrc ? '' : 'none',
                        // display: 'none',
                    }}
                />
                <div style={{
                    display: imageSrc ? 'none' : '',
                    textAlign: 'center',
                    fontSize: '150%',
                }}>
                    选择一张你电脑里的图片<br/>
                    在图片里点击像素得到你需要的颜色<br/>
                    超级方便呢
                </div>
            </div>


            {/*图片选择*/}
            <div>
                <button
                    style={{
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '200%',
                    }}
                    onClick={() => {
                        if (!this.inputRef.current) return;
                        this.inputRef.current.click();
                    }}
                >点我选择图片
                </button>

                <input
                    ref={this.inputRef}
                    type="file"
                    onChange={event => this.inputOnChange(event)}
                    style={{
                        display: 'none',
                    }}
                    accept={".png, .jpg, .jpeg, .bmp"}
                />
            </div>

        </div>;
    }
}

// 单元编辑器
class UnitEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.getReactUnit = this.getReactUnit.bind(this);
    }

    // 颜料盒
    getReactColors() {
        let selectedColors = tool.getStateSpace(selectedColorsPath, []);
        let react = [];
        for (const selectedColor of selectedColors) {
            const r = selectedColor[0];
            const g = selectedColor[1];
            const b = selectedColor[2];
            const a = selectedColor[3];
            let sign = `${r}-${g}-${b}-${a}`;
            let rgba = rgba2string(selectedColor);
            react.push(<div
                onClick={() => {
                    // let rgba = `rgba(${r},${g},${b},${a})`;
                    // let rgba = rgba2string(selectedColor);
                    tool.setStateSpace(selectedColorPath, rgba);
                }}
                key={sign}
                style={{
                    backgroundColor: rgba,
                    color: rgba,
                    width: '20px',
                    height: '20px',
                    display: 'inline-block',
                    margin: '2px',
                    borderRadius: '50%',
                    border: 'grey 1px solid',
                }}>
                {/*{`(${r},${g},${b},${a})`}*/}
                {'.'}
                <DeleteOutlined
                    style={{
                        float: 'right',
                        display: 'none',
                    }}
                    onClick={() => {
                        let newSelectedColors = [];
                        for (const _selectedColor of selectedColors) {
                            const _r = _selectedColor[0];
                            const _g = _selectedColor[1];
                            const _b = _selectedColor[2];
                            const _a = _selectedColor[3];
                            let _sign = `${_r}-${_g}-${_b}-${_a}`;
                            if (_sign === sign) continue;
                            newSelectedColors.push(_selectedColor);
                        }
                        tool.setStateSpace(selectedColorsPath, newSelectedColors);
                    }}
                />
            </div>);
        }

        // 选中的颜色
        let selectedColorRGBA = tool.getStateSpace(selectedColorPath, '');


        react = <div
        >

            {/*颜色列表*/}
            <div style={{
                border: 'grey 1px solid',
                textAlign: 'left',
                height: '150px',
                overflow: 'auto',
            }}>
            <span style={{
                width: '60px',
                height: '20px',
                display: 'inline-block',
                margin: '2px',
                borderRadius: '50%',
                // border: 'grey 1px solid',
            }}>
                颜料盒:
            </span>
                {react}
            </div>

            {/*选中的颜色*/}
            <div style={{
                textAlign: 'left',
            }}>
                选中的颜色:
                <div style={{
                    width: '200px',
                    height: '20px',
                    display: 'inline-block',
                    backgroundColor: selectedColorRGBA,
                    color: selectedColorRGBA,
                    borderRadius: '5px',
                    margin: '5px',
                }}>
                    {selectedColorRGBA ? '.' : ''}
                </div>
            </div>

        </div>;
        return react;
    }

    // 参数调整
    getReactParams() {
        // 高度宽度
        const unitWidth = tool.getStateSpace(unitWidthPath, 16);
        const unitHeight = tool.getStateSpace(unitHeightPath, 16);

        // 隐藏标识线
        const hiddenLine = tool.getStateSpace(hiddenLinePath);

        let react
        react = <div style={{
            border: 'grey 1px solid',
            textAlign: 'left',
        }}>
            <table>
                <tbody>
                <tr>

                    {/*设置宽度*/}
                    <th>
                        宽度:<InputNumber
                        max={1000}
                        min={1}
                        value={unitWidth}
                        style={{
                            width: '50px',
                        }}
                        onChange={value => {
                            tool.setStateSpace(unitWidthPath, value);
                        }}
                    />
                    </th>

                    {/*设置高度*/}
                    <th>
                        高度:<InputNumber
                        max={1000}
                        min={1}
                        value={unitHeight}
                        // defaultValue={16}
                        style={{
                            width: '50px',
                        }}
                        onChange={value => {
                            tool.setStateSpace(unitHeightPath, value);
                        }}
                    />
                    </th>

                    {/*设置亮度*/}
                    <th>
                        亮度%:<InputNumber
                        max={200}
                        min={50}
                        value={tool.getStateSpace(lightRatePath, 100)}
                        style={{
                            width: '70px',
                        }}
                        onChange={valueOrigin => {
                            tool.setStateSpace(lightRatePath, valueOrigin);
                        }}
                    />
                    </th>

                    {/*显示虚线*/}
                    <th>
                        显示标识线:
                        <Switch
                            checked={!hiddenLine}
                            onChange={value => {
                                tool.setStateSpace(hiddenLinePath, !value);
                            }}/>
                    </th>
                </tr>
                </tbody>
            </table>
        </div>;
        return react;
    }

    // 智能提取
    getReactIntelligent() {
        const intelligentLoading = tool.getStateSpace(intelligentLoadingPath);

        let content = intelligentLoading ? <CloudSyncOutlined/> : <div>
            智能提取 <PictureOutlined/> ---> <TableOutlined/>
        </div>;

        return <div style={{
            border: 'grey 1px solid',
            textAlign: 'left',
            marginTop: '5px',
        }}>
            <button
                style={{
                    width: '100%',
                    whiteSpace: 'pre',
                    border: 'none',
                    backgroundColor: 'lightblue',
                }}
                onClick={async () => {
                    tool.setStateSpace(intelligentLoadingPath, true);

                    const imageSrc = tool.getStateSpace(imageSrcPath);
                    const response = await fetch(imageSrc);
                    const blob = await response.blob();

                    const formData = new FormData();
                    formData.append('img', blob, '拜托AI大人帮我提取像素.png');

                    formData.append('width_amount', tool.getStateSpace(unitWidthPath, 16),);
                    formData.append('height_amount', tool.getStateSpace(unitHeightPath, 16),);


                    let path = 'api_v1/intelligent_color/';
                    let url = tool.path_join(path);
                    tool.post({
                        url: url,
                        data: formData,

                        contentType: false,
                        processData: false,

                        success: data => {
                            const colors = data['colors'];
                            tool.setStateSpace(imageSrcPath, data['img_path']);
                            let linesArray = [];
                            for (const y in colors) {
                                const line = colors[y];

                                const lineArray = [];
                                for (const x in line) {
                                    let color = colors[y][x];
                                    color.push(255);
                                    color = rgba2string(color);
                                    lineArray.push(color);
                                    console.log('col', color)

                                    // 填充动作
                                    // let dataColors = tool.getStateSpace(dataColorsPath, []);
                                    // const intY = parseInt(y);
                                    // const intX = parseInt(x);
                                    // dataColors[intY][intX] = color;
                                    // tool.setStateSpace(dataColorsPath, Object.assign([], dataColors));
                                    //
                                    // // 隐藏标识线
                                    // tool.setStateSpace(hiddenLinePath, true);
                                    // tool.setStateSpace(intelligentLoadingPath, false);
                                }
                                linesArray.push(lineArray);
                            }

                            tool.setStateSpace(dataColorsPath, linesArray);

                            // 隐藏标识线 取消加载
                            tool.setStateSpace(hiddenLinePath, true);
                            tool.setStateSpace(intelligentLoadingPath, false);
                        },
                        error: data => {
                            tool.show_error(data)
                            tool.setStateSpace(intelligentLoadingPath, false);
                        },
                    });
                }}
            >
                {content}
            </button>
        </div>;
    }

    // 单元编辑
    getReactUnit() {
        let update = false;


        // 高度宽度
        const unitWidth = tool.getStateSpace(unitWidthPath, 16);
        const unitHeight = tool.getStateSpace(unitHeightPath, 16);
        let dataColors = tool.getStateSpace(dataColorsPath, []);

        // 高度检测起点
        let length1 = dataColors.length;

        // 实际高度过低
        for (let i = dataColors.length; i < unitHeight; i++) {
            dataColors.push([]);
        }

        // 实际高度过高
        dataColors.splice(unitHeight);

        // 高度检测终点
        let length2 = dataColors.length;
        if (length1 !== length2) update = true;

        // 调整宽度
        let newDataColors = [];
        for (let dataColor of dataColors) {
            // 宽度检测起点
            let length1 = dataColor.length;

            // 实际宽度过低
            for (let i = dataColor.length; i < unitWidth; i++) {
                dataColor.push(null);
            }


            // 实际宽度过高
            let newDataColor = [];
            for (let i = 0; i < unitWidth; i++) {
                newDataColor.push(dataColor[i]);
            }
            dataColor = newDataColor;
            newDataColors.push(dataColor);

            // 宽度检测终点
            let length2 = dataColor.length;
            if (length1 !== length2) update = true;
        }
        dataColors = newDataColors;

        // 更新数据
        if (update) setTimeout(() => {
            tool.setStateSpace(dataColorsPath, dataColors);
        }, 100);

        // 单元格样式
        const hiddenLine = tool.getStateSpace(hiddenLinePath);
        let tableDataStyle = {
            border: hiddenLine ? '' : 'lightgray 1px dashed',
            width: '20px',
            height: '20px',
            padding: 0,
        };

        // 行
        let rows = [];
        let rowIndex = 0;
        for (const dataColor of dataColors) {
            // 列
            let cols = [];
            let colIndex = 0;
            for (let color of dataColor) {
                let _colIndex = colIndex;
                let _rowIndex = rowIndex;

                // 颜色
                let lightRate = tool.getStateSpace(lightRatePath, 100);
                lightRate = lightRate / 100;
                if (lightRate !== 1 && color) {
                    let colorArray = string2rgba(color);
                    colorArray[0] = Math.floor(colorArray[0] * lightRate);
                    colorArray[1] = Math.floor(colorArray[1] * lightRate);
                    colorArray[2] = Math.floor(colorArray[2] * lightRate);
                    color = rgba2string(colorArray);
                }


                cols.push(<td
                    style={tableDataStyle}
                    key={colIndex}
                >
                    <div
                        onClick={() => {
                            const selectedColor = tool.getStateSpace(selectedColorPath);
                            if (!selectedColor) {
                                tool.show_info('请选中一个颜色先!');
                                return;
                            }
                            dataColors[_rowIndex][_colIndex] = selectedColor;
                            tool.setStateSpace(dataColorsPath, Object.assign([], dataColors));
                        }}
                        color={dataColors[_rowIndex][_colIndex]}
                        style={{
                            width: '100%',
                            height: '100%',
                            // backgroundColor: dataColors[_rowIndex][_colIndex],
                            backgroundColor: color,
                        }}/>
                </td>)
                colIndex = colIndex + 1;
            }

            rows.push(<tr key={rowIndex}>
                {cols}
            </tr>)
            rowIndex++;
        }

        let react;
        react = <div>
            <table
                ref={this.tableRef}
                style={{
                    margin: '20px',
                }}>
                <tbody>
                {rows}
                </tbody>
            </table>
            <button
                style={{
                    width: '100%',
                }}
                onClick={() => {
                    const table = this.tableRef.current;

                    html2canvas(table).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const link = document.createElement('a');
                        link.href = imgData;
                        link.download = 'table.png';
                        link.click();
                    });


                }}
            >下载图像
            </button>
        </div>;
        return react;

    }

    render() {
        let reactColors = this.getReactColors();
        let reactParams = this.getReactParams();
        let reactUnit = this.getReactUnit();
        let reactIntelligent = this.getReactIntelligent();

        return <div>

            <div>
                单元编辑器
            </div>
            {reactColors}
            {reactParams}
            {reactIntelligent}
            {reactUnit}
        </div>;
    }
}


class McUnConnect extends React.Component {

    render() {
        // 单元格样式
        let thStyle = {
            width: '500px',
            height: '500px',
            border: 'grey 1px solid',
            margin: '10px',
        };

        return <div>
            <table>
                <tbody>
                <tr>

                    {/*颜色选择器*/}
                    <th style={thStyle}>
                        <ImagePixelGetter/>
                    </th>

                    {/*单元编辑器*/}
                    <th style={thStyle}>
                        <UnitEditComponent/>
                    </th>

                </tr>
                </tbody>
            </table>
        </div>;
    }
}

function mapStateToC(state) {
    return {
        imageSrc: state.mc,
    }
}

const McComponent = connect(mapStateToC)(McUnConnect);
export {McComponent,}
