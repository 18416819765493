import React from 'react';
import {tool} from "../../tools/tools";


function progress(param) {

    let value = param.value;
    if (value === undefined || value === null) {
        value = null;
    } else {
        value = value.toString();
    }

    let color = 'rgb(0,200,255)';
    if (value >= 100) color = 'rgb(0,255,0)';

    // 如果进度没有达到100% 进行表格数据的更新
    if (value < 100) {
        let spacePrivatePath = tool.getAttribute(param, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let selfProgress = tool.getAttribute(spacePrivate, 'selfProgress');
        if (!selfProgress) {
            setTimeout(() => {
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: 'progress',
                    selfProgress: true,
                });
                tool.setStateSpace(spacePrivatePath, spacePrivate);
            }, 3000);
        }
    }

    return <div style={{
        border: `${color} solid 1px`,
    }}>
        <div style={{
            background: color,
            width: `${value}%`,
        }}>
            {value}%
        </div>
    </div>;
}

export {progress};

