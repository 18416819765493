import React from 'react';
import {connect} from 'react-redux';
import {Modal, Tooltip} from "antd";
import {tool} from "../../../tools/tools";
import {QuestionCircleOutlined} from "@ant-design/icons";


//帮助组件
class HelpUnConnected extends React.Component {
    constructor(props) {
        super(props);
        this.getTextInfo = this.getTextInfo.bind(this);
        this.getStateShowPath = this.getStateShowPath.bind(this);
    }

    getTextInfo() {
        return {
            help: '点击获取帮助',
            okText: '好的',
            cancelText: '关闭',
            textTitle: '帮助信息',
        }
    }

    getStateShowPath() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        return spacePublicPath.concat(['stateShow']);
    }

    getHelp() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let reactHelpPath = spacePublicPath.concat(['help', 'react',]);
        let reactHelp = tool.getStateSpace(reactHelpPath);
        if (reactHelp) return reactHelp;

        let textHelpPath = spacePublicPath.concat(['help', 'text',]);
        let help;
        help = tool.getStateSpace(textHelpPath);
        if (typeof help === 'string') help = <div dangerouslySetInnerHTML={{__html: help}}/>;
        return help;
    }

    render() {
        // 是否显示
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let helpHiddenPath = spacePublicPath.concat(['help', 'hidden',]);
        let helpHidden = tool.getStateSpace(helpHiddenPath);
        if (helpHidden) return null;
        let helpPath = spacePublicPath.concat(['help']);
        let help = tool.getStateSpace(helpPath);
        if (!help) return null;

        let textInfo = this.getTextInfo();
        let stateShowPath = this.getStateShowPath();
        let stateShow = tool.getStateSpace(stateShowPath);

        return <div style={{
            marginTop: '4px',
        }}>
            <Tooltip placement="leftTop" title={textInfo.help}>
                <QuestionCircleOutlined onClick={() => tool.setStateSpace(this.getStateShowPath(), true)}/>
            </Tooltip>
            <Modal
                title={textInfo.textTitle}
                open={stateShow}
                visible={stateShow}
                okText={textInfo.okText}
                cancelText={textInfo.cancelText}
                onOk={
                    () => tool.setStateSpace(this.getStateShowPath(), false)
                }
                onCancel={
                    () => tool.setStateSpace(this.getStateShowPath(), false)
                }
            >
                {this.getHelp()}
            </Modal>
        </div>;
    }
}

function state_to_help(state) {

    return {};
}

function help_to_state(_) {
    return {};
}

let Help = connect(state_to_help, help_to_state)(HelpUnConnected);

export {Help}
