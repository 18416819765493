import React from "react";
import {connect} from 'react-redux';
import {tool} from "../../tools/tools";
import {Button, Card, Divider, Input, Modal, Popconfirm, Result, Upload} from "antd";
import {SmileOutlined, UploadOutlined} from "@ant-design/icons";
import {isMobile} from "./album_tool";
import {Login} from "../../authenticate/login";
// 删除照片删除卡片
class Album extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // 种类 ---------------------------------------------
            dataKind: [], // 数据:种类列表

            selectedKind: null, // 选中-id
            selectedKindObj: null, // 选中-obj

            showEditAddKind: false, // 增界
            editAddKindInput: null, // 增字-名称

            showEditEditKindName: false, // 更界
            inputKindName: null, // 更字-名称

            // 相册 ---------------------------------------------
            dataAlbum: [], // 数据:相册列表

            selectedAlbum: null, // 选中-id
            selectedAlbumObj: null, // 选中-obj

            showEditAddAlbum: false, // 增界
            editAddAlbumInput: null, // 增字-名称
            editCopyAlbumInput: null, // 增字-复制
            switchButton: 1, // 1-增 2-复制

            showEditEditAlbumName: false, // 更界
            inputAlbumName: null, // 更字-名称

            // 照片 ---------------------------------------------
            dataPhoto: [], // 数据:照片列表

            showEditAddPhoto: false, // 增界
            inputPhotoName: null, // 增字-名称
            inputPhotoDescribe: null, // 增字-描述

            showEditEditPhotoName: 0, // 更界
            inputUpdatePhotoName: null, // 更字-名称
        }
        this.updateDataKind = this.updateDataKind.bind(this);
        this.updateDataAlbum = this.updateDataAlbum.bind(this);
        this.updateDataPhoto = this.updateDataPhoto.bind(this);

        this.onAddKindSubmit = this.onAddKindSubmit.bind(this);
        this.onAddAlbumSubmit = this.onAddAlbumSubmit.bind(this);
        this.onAddPhotoSubmit = this.onAddPhotoSubmit.bind(this);

        this.getReactKindList = this.getReactKindList.bind(this);
        this.getReactAlbumList = this.getReactAlbumList.bind(this);
        this.getReactPhotoCard = this.getReactPhotoCard.bind(this);

        this.stateInit = false; // 完成初始化
    }

    componentDidMount() {
        if (this.props.username) this.init();
    }

    init() {
        this.stateInit = true;
        this.updateDataAlbum();
        this.updateDataKind();
    }

    // 查-种类 ----------------------------------------
    updateDataKind() {
        let path = 'api_v1/kind/';
        let param = {page_size: 10000,};
        let url = tool.path_join(path, param,);
        tool.get({
            url: url,
            success: data => {
                let results = data['results'];
                this.setState({
                    dataKind: results,
                    selectedKind: null,
                    selectedKindObj: null,
                });
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 增-种类
    onAddKindSubmit() {
        let path = 'api_v1/kind/';
        let url = tool.path_join(path);

        let data = {
            kind_name: this.state.editAddKindInput,
        };

        tool.post({
            url: url,
            data: data,
            success: _ => {
                this.updateDataKind();
                this.setState({showEditAddKind: false,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 改-种类
    onUpdateKindSubmit(data) {
        if (!data) data = {kind_name: this.state.inputKindName,};
        let path = 'api_v1/kind/';
        let url = tool.path_join(path, {}, this.state.selectedKind,);
        tool.patch({
            url: url,
            data: data,
            success: _ => {
                this.updateDataKind();
                this.setState({showEditEditKindName: false,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 组件-种类
    getReactKindList() {
        let backColor = 'slategrey'; // 按钮背景色

        // 样式表-总菜单
        let menuLineStyle = {};
        if (isMobile) menuLineStyle = {
            whiteSpace: 'nowrap',
            overflow: 'auto',
            marginTop: '5px',
        };

        // 样式表-菜单元素
        let menuStyle = {
            display: "inline-block",
            marginRight: '5px',
            borderRadius: '5px',
            width: '100px',
            height: "50px",
            textAlign: 'center',
            verticalAlign: 'top',
            cursor: 'pointer',
            overflow: 'hidden',
        };
        if (isMobile) menuStyle = {
            display: "inline-block",
            marginRight: '5px',
            borderRadius: '5px',
            width: '300px',
            height: "100px",
            textAlign: 'center',
            verticalAlign: 'top',
            cursor: 'pointer',
            overflow: 'hidden',
            fontSize: '400%',
        }

        // 样式表-增按钮
        let buttonStyle = {
            width: '100%',
            height: '100%',
            border: 0,
            backgroundColor: backColor,
        };
        if (isMobile) buttonStyle = {
            width: '100%',
            height: '100%',
            border: 0,
            backgroundColor: 'lightblue',
            fontSize: '100%',
        }

        // 组件-种类列表 内部-更
        let react = [];
        for (const item of this.state.dataKind) {
            let kindID = item['kind_id'];

            // 变量
            let isSelected = kindID === this.state.selectedKind;

            let lineHeight;
            if (isMobile) {
                if (isSelected) lineHeight = '50px';
                if (!isSelected) lineHeight = '100px';
            } else {
                if (isSelected) lineHeight = '25px';
                if (!isSelected) lineHeight = '50px';
            }


            // 设置单元背景颜色 内部-更
            let style = Object.assign({
                'backgroundColor': kindID === this.state.selectedKind ? 'lightblue' : backColor,
                'lineHeight': lineHeight,
            }, menuStyle);

            let kindName = item['kind_name'];
            react.push(<div
                key={kindID}
                style={style}
                onClick={() => {
                    // 更
                    if (kindID === this.state.selectedKind) {
                        this.setState({
                            inputKindName: kindName,
                            showEditEditKindName: true,
                        });
                        return;
                    }
                    this.setState({selectedKind: kindID, selectedKindObj: item,});
                    this.updateDataAlbum(kindID);
                }
                }
            >
                <div style={{whiteSpace: 'nowrap',}}>{kindName}</div>
                {this.state.selectedKind === kindID ? <div style={{fontSize: '80%',}}>ID:{kindID}</div> : null}
            </div>)
        }

        // 组件-增
        react.push(<div key={'add_button'} style={menuStyle}><Button
            onClick={() => this.setState({showEditAddKind: true,})}
            style={buttonStyle}
        >增加种类</Button></div>)

        // 组件-增界
        react.push(<div key={'edit_add_kind'}><Modal
            open={this.state.showEditAddKind}
            visible={this.state.showEditAddKind}
            closeIcon={" "}
            onOk={this.onAddKindSubmit}
            onCancel={() => this.setState({showEditAddKind: false,})}
            okText={"提交"}
            cancelText={"取消"}
        >
            <Input
                addonBefore={'种类名称'}
                onChange={event => this.setState({editAddKindInput: event.target.value})}
            />
        </Modal></div>)

        // 组件-更界
        let kindIndex = tool.getAttribute(this, ['state', 'selectedKindObj', 'index',], -100);
        react.push(<div
            key={'edit_update_kind'}
        ><Modal
            open={this.state.showEditEditKindName}
            visible={this.state.showEditEditKindName}
            closeIcon={" "}
            onOk={() => this.onUpdateKindSubmit()}
            onCancel={() => this.setState({showEditEditKindName: false,})}
            okText={"提交"}
            cancelText={"取消"}
            // width={'100%'}
        >
            <Input
                // style={{fontSize: '250%',}}
                addonBefore={'种类名称'}
                onChange={event => this.setState({inputKindName: event.target.value})}
                value={this.state.inputKindName}
            />
            <div style={{
                width: '100%',
                marginTop: '10px',
                textAlign: 'center',
            }}>
                <Button
                    style={{marginRight: '10px',}}
                    onClick={() => this.onUpdateKindSubmit({index: kindIndex - 3,})}
                >左移
                </Button>
                <Button
                    style={{margin: '10px',}}
                    onClick={() => this.onUpdateKindSubmit({index: kindIndex + 3,})}
                >右移</Button>

                <Popconfirm
                    title="删除这个种类"
                    description="你确定删除这张种类吗?"
                    onConfirm={() => {
                        let url = tool.path_join('api_v1/kind/', {}, this.state.selectedKind,);
                        tool.delete({
                            url: url,
                            success: data => {
                                this.updateDataKind();
                                tool.show_success('删除成功');
                                this.setState({showEditEditKindName: false,});
                            },
                            error: data => {
                                tool.show_error(data);
                            },
                        })
                    }}
                    onCancel={() => {
                    }}
                    okText="确认删除"
                    cancelText="取消"
                >
                    <Button danger={true}>删除种类</Button>
                </Popconfirm>
            </div>
        </Modal></div>)


        return <div style={menuLineStyle}>
            {react}
        </div>;
    }

    // 查-相册 ----------------------------------------
    updateDataAlbum(kind_id) {
        if (!kind_id) kind_id = this.state.selectedKind;

        let path = 'api_v1/album/';
        let param = {page_size: 10000,};
        if (kind_id) param['kind_id'] = kind_id;
        let url = tool.path_join(path, param,);
        tool.get({
            url: url,
            success: data => {
                let results = data['results'];
                this.setState({
                    dataAlbum: results,
                    selectedAlbum: null,
                    selectedAlbumObj: null,
                });
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 增-相册
    onAddAlbumSubmit() {
        let path = 'api_v1/album/';
        let url = tool.path_join(path);

        let data = {
            kind_obj: this.state.selectedKind,
        };
        // 增加
        if (this.state.switchButton === 1)
            data['album_name'] = this.state.editAddAlbumInput;

        // 复制
        if (this.state.switchButton === 2) data = {
            copy_id: this.state.editCopyAlbumInput,
        };
        data['album_name'] = this.state.editAddAlbumInput;

        tool.post({
            url: url,
            data: data,
            success: _ => {
                this.updateDataAlbum();
                this.setState({showEditAddAlbum: false,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 改-相册
    onUpdateAlbumSubmit(data) {
        if (!data) data = {album_name: this.state.inputAlbumName,};
        let path = 'api_v1/album/';
        let url = tool.path_join(path, {}, this.state.selectedAlbum,);
        tool.patch({
            url: url,
            data: data,
            success: _ => {
                this.updateDataAlbum();
                this.setState({showEditEditAlbumName: false,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 组件-相册
    getReactAlbumList() {
        // 样式表-全局菜单
        let menuLineStyle = {};
        if (isMobile) menuLineStyle = {
            whiteSpace: 'nowrap',
            overflow: 'auto',
            marginTop: '5px',
        };

        // 样式表-菜单元素
        let menuStyle = {
            display: "inline-block",
            // border: 'red solid 1px',
            marginRight: '5px',
            borderRadius: '5px',
            width: '100px',
            height: "50px",
            textAlign: 'center',
            verticalAlign: 'top',
            // lineHeight: '50px',
            // lineHeight: '25px',
            cursor: 'pointer',
            overflow: 'hidden',
        };
        if (isMobile) menuStyle = {
            display: "inline-block",
            // border: 'red solid 1px',
            marginRight: '5px',
            borderRadius: '5px',
            width: '300px',
            height: "100px",
            textAlign: 'center',
            verticalAlign: 'top',
            // lineHeight: '50px',
            // lineHeight: '25px',
            cursor: 'pointer',
            overflow: 'hidden',
            fontSize: '400%',
        }

        // 样式表-增按钮
        let buttonStyle = {
            width: '100%',
            height: '100%',
            border: 0,
            backgroundColor: 'blanchedalmond',
        };
        if (isMobile) buttonStyle = {
            width: '100%',
            height: '100%',
            border: 0,
            backgroundColor: 'blanchedalmond',
            fontSize: '100%',
        }

        // 组件-相册列表 内部-更
        let react = [];
        for (const item of this.state.dataAlbum) {
            let albumID = item['album_id'];

            // 变量
            let isSelected = albumID === this.state.selectedAlbum;

            let lineHeight;
            if (isMobile) {
                if (isSelected) lineHeight = '50px';
                if (!isSelected) lineHeight = '100px';
            } else {
                if (isSelected) lineHeight = '25px';
                if (!isSelected) lineHeight = '50px';
            }


            // 设置单元背景颜色
            let style = Object.assign({
                'backgroundColor': albumID === this.state.selectedAlbum ? 'cornflowerblue' : 'blanchedalmond',
                'lineHeight': lineHeight,
            }, menuStyle);

            // 内部-更
            let albumName = item['album_name'];
            react.push(<div
                key={albumID}
                style={style}
                onClick={() => {
                    // 如果当前是选中则开始编辑
                    if (albumID === this.state.selectedAlbum) {
                        this.setState({
                            inputAlbumName: albumName,
                            showEditEditAlbumName: true,
                        });
                        return;
                    }
                    this.setState({selectedAlbum: albumID, selectedAlbumObj: item,});
                    this.updateDataPhoto(albumID);
                }
                }
            >
                <div style={{whiteSpace: 'nowrap',}}>{albumName}</div>
                {this.state.selectedAlbum === albumID ? <div style={{fontSize: '80%',}}>ID:{albumID}</div> : null}
            </div>)
        }

        // 组件-增按钮
        react.push(<div key={'add_button'} style={menuStyle}><Button
            onClick={() => this.setState({showEditAddAlbum: true,})}
            style={buttonStyle}
        >增加相册</Button></div>)

        // 组件-增界
        let switchStyle = {
            width: '50%',
        };
        react.push(<div key={'edit_add_album'}><Modal
            visible={this.state.showEditAddAlbum}
            open={this.state.showEditAddAlbum}
            closeIcon={" "}
            onOk={this.onAddAlbumSubmit}
            onCancel={() => this.setState({showEditAddAlbum: false,})}
            okText={"提交"}
            cancelText={"取消"}
        >
            <div style={{
                width: '100%',
                marginBottom: '20px',
                fontSize: '100%',
            }}>
                <Button
                    style={switchStyle}
                    type={this.state.switchButton === 1 ? 'primary' : ''}
                    onClick={() => this.setState({switchButton: 1,})}
                >新建一个相册</Button>
                <Button
                    style={switchStyle}
                    type={this.state.switchButton === 2 ? 'primary' : ''}
                    onClick={() => this.setState({switchButton: 2,})}
                >复制一个相册</Button>
            </div>
            {this.state.switchButton === 1 ? <Input
                addonBefore={'相册名称'}
                onChange={event => this.setState({editAddAlbumInput: event.target.value})}
            /> : null}
            {this.state.switchButton === 2 ? <Input
                addonBefore={'通过id复制一个模板'}
                onChange={event => this.setState({editCopyAlbumInput: event.target.value})}
            /> : null}
        </Modal></div>)

        // 组件-更界
        let reactKind = [];
        let nowKindID = tool.getAttribute(this, ['state', 'selectedAlbumObj', 'kind_obj',],);
        let kindButtonStyle = {
            marginRight: '2px',
        };
        for (const kind of this.state.dataKind) {
            let kindID = kind['kind_id'];
            let kindName = kind['kind_name'];
            let type = nowKindID === kindID ? 'primary' : ''
            reactKind.push(<Button
                style={kindButtonStyle}
                type={type}
                key={kindID}
                onClick={() => {
                    this.onUpdateAlbumSubmit({kind_obj: kindID});
                }}
            >{kindName}</Button>);
        }

        let albumIndex = tool.getAttribute(this, ['state', 'selectedAlbumObj', 'index',], -100);
        react.push(<div
            key={'edit_update_album'}
        ><Modal
            open={this.state.showEditEditAlbumName}
            visible={this.state.showEditEditAlbumName}
            closeIcon={" "}
            onOk={() => this.onUpdateAlbumSubmit()}
            onCancel={() => this.setState({showEditEditAlbumName: false,})}
            okText={"提交"}
            cancelText={"取消"}
            // width={'100%'}
        >
            <div>
                分类:<br/><br/>
                {reactKind}
            </div>
            <Divider/>
            <Input
                addonBefore={'相册名称'}
                onChange={event => this.setState({inputAlbumName: event.target.value})}
                value={this.state.inputAlbumName}
            />
            <div style={{
                width: '100%',
                marginTop: '10px',
                textAlign: 'center',
            }}>
                <Button
                    style={{marginRight: '10px',}}
                    onClick={() => this.onUpdateAlbumSubmit({index: albumIndex - 3,})}
                >左移
                </Button>
                <Button
                    style={{margin: '10px',}}
                    onClick={() => this.onUpdateAlbumSubmit({index: albumIndex + 3,})}
                >右移</Button>

                <Popconfirm
                    title="删除这个相册"
                    description="你确定删除这张相册吗?"
                    onConfirm={() => {
                        let url = tool.path_join('api_v1/album/', {}, this.state.selectedAlbum,);
                        tool.delete({
                            url: url,
                            success: data => {
                                this.updateDataAlbum();
                                tool.show_success('删除成功');
                                this.setState({showEditEditAlbumName: false,});
                            },
                            error: data => {
                                tool.show_error(data);
                            },
                        })
                    }}
                    onCancel={() => {
                    }}
                    okText="确认删除"
                    cancelText="取消"
                >
                    <Button danger={true}>删除相册</Button>
                </Popconfirm>
            </div>
        </Modal></div>)


        return <div style={menuLineStyle}>
            {react}
        </div>;
    }

    // 查-照片 ----------------------------------------
    updateDataPhoto(album_id) {
        if (!album_id) album_id = this.state.selectedAlbum;
        let path = 'api_v1/photo/';
        let url = tool.path_join(path, {album_id: album_id, page_size: 10000,});
        tool.get({
            url: url,
            success: data => {
                let results = data['results'];
                this.setState({dataPhoto: results,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 增-照片
    onAddPhotoSubmit() {
        let path = 'api_v1/photo/';
        let url = tool.path_join(path);
        tool.post({
            url: url,
            data: {
                album_obj: this.state.selectedAlbum,
                photo_name: this.state.inputPhotoName,
                description: this.state.inputPhotoDescribe,
            },
            success: _ => {
                this.updateDataPhoto(this.state.selectedAlbum,);
                this.setState({showEditAddPhoto: false,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 改-照片
    onUpdatePhotoSubmit(photo_id, data) {
        if (!data) data = {
            photo_name: this.state.inputUpdatePhotoName,
        };
        let path = 'api_v1/photo/';
        let url = tool.path_join(path, {}, photo_id,);
        tool.patch({
            url: url,
            data: data,
            success: _ => {
                this.updateDataPhoto(this.state.selectedAlbum,);
                this.setState({showEditEditPhotoName: false,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 组件-照片
    getReactPhotoCard() {
        let deleteHeight = 30; // 删除按钮高度

        if (!this.state.selectedAlbum) return null;
        // 样式表
        let style = {
            // border: 'red solid 1px',
            border: 'lightblue solid 1px',
            width: '220px',
            height: '330px',
            display: 'inline-block',
            verticalAlign: 'top',
            textAlign: 'center',
            marginRight: '2px',
            position: 'relative',
            margin: '2px',
        };
        if (isMobile) style = {
            border: 'lightblue solid 1px',
            width: '48%',
            height: '330px',
            display: 'inline-block',
            verticalAlign: 'top',
            textAlign: 'center',
            marginLeft: '2px',
            marginRight: '2px',
            position: 'relative',
            margin: '2px',
        }

        // 照片列表 内部-更
        let react = [];
        for (const item of this.state.dataPhoto) {
            let photoID = item['photo_id'];
            let photoName = item['photo_name'];
            let photoIndex = item['index'];
            // let description = item['description'];
            let src = item['photo_read'];
            src = tool.path_join(src);


            // 删除卡片或照片
            let deleteCard = <div
                style={{
                    // border: 'red 1px solid',
                    // position: 'absolute',
                    // bottom: '0',
                    // width: '100%',
                    // height: `${src ? deleteHeight * 2 : deleteHeight}px`,
                }}
            >

                {/*删除照片*/}
                {src ? <Popconfirm
                    title="删除这个照片"
                    description="你确定删除这张照片吗?"
                    onConfirm={() => {
                        // photoID
                        let url = tool.path_join('api_v1/upload_photo/', {}, photoID);
                        tool.patch({
                            url: url,
                            data: {photo: null,},
                            success: data => {
                                tool.show_success('删除成功');
                                this.updateDataPhoto();
                            },
                            error: data => {
                                tool.show_error(data);
                            },
                        });
                    }}
                    onCancel={() => {
                    }}
                    okText="确认删除"
                    cancelText="取消"
                >
                    <Button
                        style={{
                            width: '100%',
                            height: `${deleteHeight}px`,
                        }}

                    >删除照片</Button>
                </Popconfirm> : null}


                {/*删除计划*/}
                <Popconfirm
                    title="删除这个计划"
                    description="你确定删除这个计划吗?"
                    onConfirm={() => {
                        let url = tool.path_join('api_v1/photo/', {}, photoID);
                        tool.delete({
                            url: url,
                            success: data => {
                                tool.show_success('卡片删除成功');
                                this.updateDataPhoto();
                            },
                            error: data => {
                                tool.show_error(data);
                            },
                        });
                    }}
                    onCancel={() => {
                    }}
                    okText="确认删除"
                    cancelText="取消"
                >
                    <div style={{
                        backgroundColor: 'lightcyan',
                        // height:'deleteHeight',
                        lineHeight:`${deleteHeight}px`,

                    }}>
                        {photoName}
                    </div>

                    <Button
                        style={{
                            width: '100%',
                            height: `${deleteHeight}px`,
                        }}
                    >删除卡片</Button>
                </Popconfirm>

            </div>

            // 底部3个按钮
            let bottomLines = <div
                style={{
                    // border: 'red 1px solid',
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    height: `${src ? deleteHeight * 3 : deleteHeight}px`,
                }}
            >

                <div style={{
                    backgroundColor: 'lightcyan',
                    // height:'deleteHeight',
                    lineHeight:`${deleteHeight}px`,

                }}>
                    {photoName}
                </div>
                {deleteCard}
            </div>;

            // 点击修改card名称 内部-更
            let reactEditCardName = <div
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => this.setState({
                    showEditEditPhotoName: photoID,
                    inputUpdatePhotoName: photoName,
                })}
            >
                {/*{photoName}*/}
                <Modal
                    open={this.state.showEditEditPhotoName === photoID}
                    visible={this.state.showEditEditPhotoName === photoID}
                    closeIcon={" "}
                    onOk={() => this.onUpdatePhotoSubmit(photoID)}
                    onCancel={event => {
                        event.stopPropagation();
                        this.setState({showEditEditPhotoName: false,})
                    }
                    }
                    okText={"提交"}
                    cancelText={"取消"}
                >
                    <Input
                        addonBefore={'照片名称'}
                        onChange={event => this.setState({inputUpdatePhotoName: event.target.value})}
                        value={this.state.inputUpdatePhotoName}
                    />

                    <div style={{
                        width: '100%',
                        marginTop: '10px',
                        textAlign: 'center',
                    }}>
                        <Button
                            style={{marginRight: '10px',}}
                            onClick={() => this.onUpdatePhotoSubmit(photoID, {index: photoIndex - 3,})}
                        >左移
                        </Button>
                        <Button
                            onClick={() => this.onUpdatePhotoSubmit(photoID, {index: photoIndex + 3,})}
                        >右移</Button>
                    </div>
                </Modal>
            </div>;

            // 有照片
            if (src) {
                let metaStyle = {
                    float: 'bottom',
                    border: 'lightblue solid 1px',
                    // position: 'absolute',
                    bottom: `${deleteHeight + 5}px`,
                    left: '0',
                    width: '100%',
                    borderRadius: '5px',
                    cursor: 'default',
                };
                react.push(<div
                    key={photoID}
                    style={style}
                >
                    <Card
                        hoverable
                        style={{
                            width: 220,
                            height: 330,
                            border: 'lightblue solid 1px',
                            marginRight: '5px',
                            overflow: 'hidden',
                            position: 'relative',
                            borderRadius: '5px',
                            cursor: 'default',
                        }}
                        cover={<img alt={photoName} src={src}/>}
                    >
                        <Card.Meta
                            title={reactEditCardName}
                            // description={description}
                            style={metaStyle}
                        />

                    </Card>
                    {/*{photoName}*/}
                    {bottomLines}
                    {/*{deleteCard}*/}
                </div>)
            }

            // 无照片
            if (!src) {
                let url = tool.path_join('api_v1/upload_file/');
                const props = {
                    action: url,
                    onChange: event => {
                        let file = event.file;
                        if (file.status === 'done') {
                            let cloudID = tool.getAttribute(file, ['response', 'cloud_id',]);
                            // let cloudUrl = tool.getAttribute(file, ['response', 'cloud_url',]);
                            let upload_url = tool.path_join('api_v1/upload_photo/', {}, photoID,);
                            tool.patch({
                                url: upload_url,
                                data: {"photo": cloudID,},
                                success: _ => {
                                    this.updateDataPhoto();
                                },
                                error: data => {
                                    tool.show_error(data);
                                },
                            });
                        }
                    },
                    defaultFileList: [],
                };


                react.push(<div
                    key={photoID}
                    style={style}
                    // onClick={() => this.setState({selectedAlbum: photoID,})}
                >
                    <Result
                        icon={<SmileOutlined/>}
                        title={reactEditCardName}
                        extra={<Upload {...props}>
                            <Button icon={<UploadOutlined/>}>轻触上传</Button>
                        </Upload>}
                    />
                    {deleteCard}
                </div>)
            }
        }

        // 增加按钮
        react.push(<div key={'add_button'} style={style}><Button
            onClick={() => this.setState({showEditAddPhoto: true,})}
            style={{width: '100%', height: '100%', border: 0, backgroundColor: 'blanchedalmond',}}
        >增加计划</Button></div>)

        // 增加计划编辑框
        react.push(<div key={'edit_plan'}><Modal
            open={this.state.showEditAddPhoto}
            visible={this.state.showEditAddPhoto}
            closeIcon={" "}
            onOk={this.onAddPhotoSubmit}
            onCancel={() => this.setState({showEditAddPhoto: false,})}
            okText={"提交"}
            cancelText={"取消"}
        >
            <Input
                addonBefore={'照片名称'}
                onChange={event => this.setState({inputPhotoName: event.target.value})}
            />

        </Modal></div>)

        return <div style={{textAlign: 'center',}}>
            {react}
        </div>;
    }

    // render ----------------------------------------
    render() {
        if (!this.props.username) return <Login/>;
        if (!this.stateInit) this.init();


        let reactKind = this.getReactKindList();
        let reactAlbum = this.getReactAlbumList();
        let reactPhotoCard = this.getReactPhotoCard();
        return <div>
            {reactKind}
            {reactAlbum}
            {reactPhotoCard}
        </div>
    }
}

function mapStateToAlbum(state) {
    let username = tool.getAttribute(state, ['setting', 'authenticate', 'username',]);
    let setting = tool.getAttribute(state, ['setting',]);
    return {
        username: username,
        setting: setting,
    }
}

Album = connect(mapStateToAlbum)(Album);

export {
    Album
};

