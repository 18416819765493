import React from "react";
import {Button, Divider, Modal, Popconfirm, Radio,} from "antd";

import {connect} from 'react-redux';
import {tool} from "../tools/tools";

import './right-click.css';
import {isMobile} from "../assistant/album/album_tool";
import {ToolOutlined} from "@ant-design/icons";


//右键点击 编辑 没有
class RightClick extends React.Component {
    constructor(props) {
        super(props);
        this.getRightMenu = this.getRightMenu.bind(this);
    }

    getSetRight() {
        let open_edit = tool.getStateSpace(['open_edit']);
        if (!open_edit) return null;

        let spacePrivatePath = tool.getAttribute(this, ['props', 'rightClick', 'spacePrivatePath',]);
        let spacePublicPath = tool.getAttribute(this, ['props', 'rightClick', 'spacePublicPath',]);

        // 详情
        let detailPath = spacePublicPath.concat(['columnsDetailLine',],);
        let hiddenDetailPath = spacePublicPath.concat(['columnsDetailLine', 'hidden']);
        let hiddenDetail = tool.getStateSpace(hiddenDetailPath);
        let textDetailPath = spacePublicPath.concat(['text__detail_right',]);
        let textDetail = tool.getStateSpace(textDetailPath);

        // 新建
        let createPath = spacePublicPath.concat(['columnsCreate',],);
        let hiddenCreatePath = spacePublicPath.concat(['disableCreate',]);
        let hiddenCreate = tool.getStateSpace(hiddenCreatePath);
        let textCreatePath = spacePublicPath.concat(['text__create_right',]);
        let textCreate = tool.getStateSpace(textCreatePath);

        // 编辑
        let updatePath = spacePublicPath.concat(['columnsUpdate',],);
        let hiddenUpdatePath = spacePublicPath.concat(['columnsUpdate', 'hidden']);
        let hiddenUpdate = tool.getStateSpace(hiddenUpdatePath);
        let textUpdatePath = spacePublicPath.concat(['text__update_right',]);
        let textUpdate = tool.getStateSpace(textUpdatePath);

        // 编辑1
        let update1Path = spacePublicPath.concat(['columnsUpdate_1',],);
        let hiddenUpdate1Path = spacePublicPath.concat(['columnsUpdate_1', 'hidden']);
        let hiddenUpdate1 = tool.getStateSpace(hiddenUpdate1Path);
        let textUpdate1Path = spacePublicPath.concat(['text__update_part_right',]);
        let textUpdate1 = tool.getStateSpace(textUpdate1Path);

        // 删除
        let deleteHiddenPath = spacePublicPath.concat(['disableDelete',],);
        let deleteHidden = tool.getStateSpace(deleteHiddenPath);
        let textDeletePath = spacePublicPath.concat(['text__delete',]);
        let textDelete = tool.getStateSpace(textDeletePath);

        // 拷贝
        let copyHiddenPath = spacePublicPath.concat(['disableCopy',],);
        let copyHidden = tool.getStateSpace(copyHiddenPath);
        let textCopyPath = spacePublicPath.concat(['text__copy_right',]);
        let textCopy = tool.getStateSpace(textCopyPath);

        let reacts = [];
        reacts.push(<div key={'x'}>
            <Divider>详情按钮</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            let value = event.target.value;
                            tool.setStateSpace(hiddenDetailPath, event.target.value,);
                            if (value) return;


                            let detail = tool.getStateSpace(detailPath);
                            if (!detail) {
                                tool.setStateSpace(detailPath, {});
                            }
                        }}
                        value={hiddenDetail}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 名称*/}
                <tr>
                    <th>名称:</th>
                    <th>
                        <input
                            value={textDetail}
                            onChange={event => {
                                let value = event.target.value;
                                tool.setStateSpace(textDetailPath, value);
                            }}
                        />
                    </th>
                </tr>

                </thead>
            </table>

            <Divider>新建按钮</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            let value = event.target.value;
                            tool.setStateSpace(hiddenCreatePath, event.target.value,);
                            if (value) return;


                            let create = tool.getStateSpace(createPath);
                            if (!create) {
                                tool.setStateSpace(createPath, {});
                            }
                        }}
                        value={hiddenCreate}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 名称*/}
                <tr>
                    <th>名称:</th>
                    <th>
                        <input
                            value={textCreate}
                            onChange={event => {
                                let value = event.target.value;
                                tool.setStateSpace(textCreatePath, value);
                            }}
                        />
                    </th>
                </tr>

                </thead>
            </table>

            <Divider>拷贝按钮</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            tool.setStateSpace(copyHiddenPath, event.target.value,);
                        }}
                        value={copyHidden}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group>
                    </th>
                </tr>

                {/*第2行 名称*/}
                <tr>
                    <th>名称:</th>
                    <th>
                        <input
                            value={textCopy}
                            onChange={event => {
                                let value = event.target.value;
                                tool.setStateSpace(textCopyPath, value);
                            }}
                        />
                    </th>
                </tr>

                </thead>
            </table>

            <Divider>编辑按钮</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            let value = event.target.value;
                            tool.setStateSpace(hiddenUpdatePath, event.target.value,);
                            if (value) return;

                            let update = tool.getStateSpace(updatePath);
                            if (!update) {
                                tool.setStateSpace(updatePath, {});
                            }
                        }}
                        value={hiddenUpdate}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 名称*/}
                <tr>
                    <th>名称:</th>
                    <th>
                        <input
                            value={textUpdate}
                            onChange={event => {
                                let value = event.target.value;
                                tool.setStateSpace(textUpdatePath, value);
                            }}
                        />
                    </th>
                </tr>

                </thead>
            </table>

            <Divider>编辑1按钮</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            let value = event.target.value;
                            tool.setStateSpace(hiddenUpdate1Path, event.target.value,);
                            if (value) return;

                            let update1 = tool.getStateSpace(update1Path);
                            if (!update1) {
                                tool.setStateSpace(update1Path, {});
                            }
                        }}
                        value={hiddenUpdate1}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 名称*/}
                <tr>
                    <th>名称:</th>
                    <th>
                        <input
                            value={textUpdate1}
                            onChange={event => {
                                let value = event.target.value;
                                tool.setStateSpace(textUpdate1Path, value);
                            }}
                        />
                    </th>
                </tr>

                </thead>
            </table>

            <Divider>删除按钮</Divider>
            <table style={{textAlign: 'left',}}>
                <thead>

                {/*第1行 是否显示*/}
                <tr>
                    <th>是否显示:</th>
                    <th><Radio.Group
                        onChange={event => {
                            let value = event.target.value;
                            tool.setStateSpace(deleteHiddenPath, value,);
                        }}
                        value={deleteHidden}
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Radio value={false}>显示</Radio>
                        <Radio value={true}>隐藏</Radio>
                    </Radio.Group></th>
                </tr>

                {/*第2行 名称*/}
                <tr>
                    <th>名称:</th>
                    <th>
                        <input
                            value={textDelete}
                            onChange={event => {
                                let value = event.target.value;
                                tool.setStateSpace(textDeletePath, value);
                            }}
                        />
                    </th>
                </tr>

                </thead>
            </table>
        </div>)

        // 搜索框
        let isSetRightShowPath = spacePrivatePath.concat(['isSetRightShow']);
        let isSetRightShow = tool.getStateSpace(isSetRightShowPath, false);

        let content = <Modal
            visible={isSetRightShow}
            open={isSetRightShow}
            onCancel={() => tool.setStateSpace(isSetRightShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        return <div>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetRightShowPath, true,)}
            > <ToolOutlined/> </Button>
        </div>;
    }

    getRightMenu() {
        let open_edit = tool.getStateSpace(['open_edit']);
        // if (!open_edit) return null;

        let spacePublicPath = tool.getAttribute(this, ['props', 'rightClick', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        // let textDeletePath = spacePublicPath.concat(['text__delete']);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'rightClick', 'spacePrivatePath',]);
        let spaceRowPath = tool.getAttribute(this, ['props', 'rightClick', 'spaceRowPath',]);
        let record = tool.getAttribute(this, ['props', 'rightClick', 'record',]);
        let fatherRecord = tool.getAttribute(this, ['props', 'rightClick', 'fatherRecord',]);
        let recordId = record.id;

        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 过滤器相关配置
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);

        //右键选项显示控制
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);

        // 计算分步编辑的路径
        let nowPage = tool.getAttribute(spacePrivate, ['updatePart', 'nowPage',]);
        if (!nowPage) nowPage = 1;
        let columnsUpdatePartPath = spacePublicPath.concat([`columnsUpdate_${nowPage}`]);


        let columnsDetailLinePath = spacePublicPath.concat(['columnsDetailLine']);
        let columnsCreateSpace = tool.getStateSpace(columnsCreatePath);
        let columnsUpdateSpace = tool.getStateSpace(columnsUpdatePath);
        let columnsUpdatePartSpace = tool.getStateSpace(columnsUpdatePartPath);
        let columnsDetailLineSpace = tool.getStateSpace(columnsDetailLinePath);
        let disableDeleteSpace = tool.getAttribute(spacePublic, 'disableDelete');
        let disableCopy = tool.getAttribute(spacePublic, 'disableCopy');
        let disableUpdate = tool.getAttribute(spacePublic, 'disableUpdate');
        let disableCreate = tool.getAttribute(spacePublic, 'disableCreate');
        // let disableUpdatePart = tool.getAttribute(spacePublic, 'disableUpdatePart');

        if (filterValue && filtersInfo && filtersChoices && filterNow) {
            let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
            let filterColumnsCreate = tool.getAttribute(filterNow, 'columnsCreate');
            let filterDisableDelete = tool.getAttribute(filterNow, 'disableDelete');
            let filterDisableCopy = tool.getAttribute(filterNow, 'disableCopy');
            let filterDisableUpdate = tool.getAttribute(filterNow, 'disableUpdate');

            if (filterColumnsUpdate) columnsUpdateSpace = filterColumnsUpdate;
            if (filterColumnsCreate) columnsCreateSpace = filterColumnsCreate;
            if (filterDisableDelete) disableDeleteSpace = filterDisableDelete;
            if (filterDisableCopy) disableCopy = filterDisableCopy;
            if (filterDisableUpdate) disableUpdate = filterDisableUpdate;
        }


        let showC, showCp, showU, showUPart, showD, showDelete, showInfo;

        if (columnsCreateSpace && (!disableCreate)) showC = true;
        if (columnsCreateSpace) showCp = true;
        if (disableCopy) showCp = false;
        if (columnsUpdateSpace && (!disableUpdate)) showU = true;
        if (columnsUpdatePartSpace) showUPart = true;
        if (columnsDetailLineSpace) showD = true; // 存在详情配置则显示详情
        if (!disableDeleteSpace) showDelete = true;

        // 详情隐藏
        let hiddenDetailPath = spacePublicPath.concat(['columnsDetailLine', 'hidden']);
        let hiddenDetail = tool.getStateSpace(hiddenDetailPath);
        if (hiddenDetail) showD = false;

        // 拷贝隐藏
        let hiddenCopyPath = spacePublicPath.concat(['disableCopy',]);
        let hiddenCopy = tool.getStateSpace(hiddenCopyPath);
        if (hiddenCopy) showCp = false;
        if (hiddenCopy === false) showCp = true;

        // 编辑隐藏
        let hiddenUpdatePath = spacePublicPath.concat(['columnsUpdate', 'hidden']);
        let hiddenUpdate = tool.getStateSpace(hiddenUpdatePath);
        if (hiddenUpdate) showU = false;

        // 编辑1隐藏
        let hiddenUpdate1Path = spacePublicPath.concat(['columnsUpdate_1', 'hidden']);
        let hiddenUpdate1 = tool.getStateSpace(hiddenUpdate1Path);
        if (hiddenUpdate1) showUPart = false;

        // 删除隐藏
        // let deleteHiddenPath = spacePublicPath.concat(['disableDelete',]);
        // let deleteHidden = tool.getStateSpace(deleteHiddenPath);
        // if (deleteHidden) showDelete = false;


        if (!showC && !showU && !showUPart && !showD && !showDelete && !open_edit) showInfo = true;

        if (showInfo) {
            setTimeout(() => tool.show_info('没有什么好显示的!'), 100);
            return null;
        }


        let textDetail = '详情';
        let text_detail = tool.getAttribute(spacePublic, 'text__detail_right');
        if (text_detail) textDetail = text_detail;

        let filterTextDetail = tool.getAttribute(filterNow, 'text__detail_right');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterTextDetail) {
            textDetail = filterTextDetail;
        }

        let textDelete = '删除';
        let text_delete = tool.getAttribute(spacePublic, 'text__delete');
        if (text_delete) textDelete = text_delete;

        let filterTextDelete = tool.getAttribute(filterNow, 'text__delete_right');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterTextDelete) {
            textDelete = filterTextDelete;
        }

        let textCreate = '新建';
        let text_create = tool.getAttribute(spacePublic, 'text__create_right');
        if (text_create) textCreate = text_create;

        let filterTextCreate = tool.getAttribute(filterNow, 'text__create_right');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterTextCreate) {
            textCreate = filterTextCreate;
        }

        let textCopy = '拷贝';
        let text_copy = tool.getAttribute(spacePublic, 'text__copy_right');
        if (text_copy) textCopy = text_copy;

        let filterTextCopy = tool.getAttribute(filterNow, 'text__copy_right');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterTextCopy) {
            textCopy = filterTextCopy;
        }

        let textUpdate = '编辑';
        let text_update = tool.getAttribute(spacePublic, 'text__update_right');
        if (text_update) textUpdate = text_update;

        let textUpdatePart = '编辑'; // 分步编辑
        let text_update_part = tool.getAttribute(spacePublic, 'text__update_part_right');
        if (text_update_part) textUpdatePart = text_update_part;

        let filterTextUpdate = tool.getAttribute(filterNow, 'text__update_right');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterTextUpdate) {
            textUpdate = filterTextUpdate;
        }


        let onClickDelete = () => {
            // tool.rightInfo = Object.assign({}, tool.rightInfo, {
            //     sign: true,
            //
            // });

            let urlParam = {};
            if (fatherRecord) urlParam['father_id'] = fatherRecord.id;

            let requestPathPath = spacePublicPath.concat(['path']);
            let requestPath = tool.getStateSpace(requestPathPath);
            let url = tool.get_url(requestPath, false, urlParam, recordId,);
            let requestParam = {
                url: url,
                success: (data, success_state, res) => {
                    if (res && res.status === 209) {
                        tool.show_success_299(data)
                    } else {
                        tool.show_success([`${textDelete}成功`]);
                    }

                    let resultsPath = spacePrivatePath.concat(['responseData', 'results',]);
                    let results = tool.getStateSpace(resultsPath);
                    let newResults = [];
                    results.map(result => (result.id !== recordId) ? newResults.push(result) : null);
                    tool.setStateSpace(resultsPath, newResults);

                    // 关闭右键菜单
                    let rightClickPath = ['rightClick'];
                    let rightClick = tool.getStateSpace(rightClickPath);
                    rightClick = Object.assign({}, rightClick, {
                        show: false,
                        onTableMenu: false,
                        sign: false,
                    });
                    tool.setStateSpace(['rightClick'], rightClick);
                },
                error: data => {

                    tool.show_error(data);

                    // 关闭右键菜单
                    let rightClickPath = ['rightClick'];
                    let rightClick = tool.getStateSpace(rightClickPath);
                    rightClick = Object.assign({}, rightClick, {
                        // show: false,
                        sign: false,
                    });
                    tool.setStateSpace(['rightClick'], rightClick);
                },
            };
            tool.delete(requestParam);
        };

        let onClickDetail = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'Detail',
                paramModal: {
                    detailOnload: true,
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let getColumnsUpdate = () => {
            let spacePrivate = tool.getStateSpace(spacePrivatePath);
            let spacePublic = tool.getStateSpace(spacePublicPath);

            //更新字段
            let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate',]);
            let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

            // 是否使用过滤器的字段
            let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
            if (!filterValue) {
                filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
            }
            let filtersInfo = tool.getAttribute(spacePublic, 'filters');
            let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
            let filterNow = tool.getAttribute(filtersChoices, filterValue);
            let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
            if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdate) {
                columnsUpdate = filterColumnsUpdate;
            }
            return columnsUpdate;
        };

        let getColumnsUpdatePart = () => {
            let spacePrivate = tool.getStateSpace(spacePrivatePath);
            let spacePublic = tool.getStateSpace(spacePublicPath);

            //更新字段
            let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate',]);
            let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

            // 是否使用过滤器的字段
            let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
            if (!filterValue) {
                filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
            }
            let filtersInfo = tool.getAttribute(spacePublic, 'filters');
            let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
            let filterNow = tool.getAttribute(filtersChoices, filterValue);
            let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
            if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdate) {
                columnsUpdate = filterColumnsUpdate;
            }
            return columnsUpdate;
        };

        let onClickU = () => {
            // let columnsUpdateSpace = tool.getStateSpace(columnsUpdatePath);
            let columnsUpdateSpace = getColumnsUpdate();
            let trigger = tool.getAttribute(columnsUpdateSpace, 'trigger__');
            if (trigger) {
                let param_trigger = {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                };
                return trigger(param_trigger);
            }

            // 是否跳转到其他资源
            let menu__ = tool.getAttribute(columnsUpdateSpace, 'menu__');
            let onClickMenu = tool.getStateSpace(['setting', 'onClickMenu',]);
            if (menu__) {
                let paramFather = {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                };
                onClickMenu(menu__, true, paramFather);

                // 关闭右键菜单
                let rightClickPath = ['rightClick'];
                let rightClick = tool.getStateSpace(rightClickPath);
                rightClick = Object.assign({}, rightClick, {
                    show: false,
                    // onTableMenu: false,
                    // sign: false,
                });
                tool.setStateSpace(['rightClick'], rightClick);
                return null;
            }

            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'Update',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let onClickUPart = () => {
            let columnsUpdateSpace = getColumnsUpdatePart();
            let trigger = tool.getAttribute(columnsUpdateSpace, 'trigger__');
            if (trigger) {
                let param_trigger = {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                };
                return trigger(param_trigger);
            }

            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'UpdatePart',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let onClickCreate = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'Create',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let onClickCopy = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'Copy',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    record: record,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let onMouseEnter = event => {
            // tool.rightInfo = Object.assign({}, tool.rightInfo);
            // tool.rightInfo['onTableMenu'] = true;

            let rightClickPath = ['rightClick'];
            let rightClick = tool.getStateSpace(rightClickPath);
            rightClick = Object.assign({}, rightClick, {
                sign: true,
            });
            tool.setStateSpace(['rightClick'], rightClick);
        };

        let onMouseLeave = event => {
            // tool.rightInfo = Object.assign({}, tool.rightInfo);
            // tool.rightInfo['onTableMenu'] = false;

            let rightClickPath = ['rightClick'];
            let rightClick = tool.getStateSpace(rightClickPath);
            rightClick = Object.assign({}, rightClick, {
                sign: false,
            });
            tool.setStateSpace(['rightClick'], rightClick);
        };

        let buttonStyle = {};
        if (isMobile) buttonStyle = {
            "fontSize": '50px',
            height: '80px',
        };

        return (
            <div
                onMouseEnter={event => onMouseEnter(event)}
                onMouseLeave={event => onMouseLeave(event)}
                className={'Buttons'}
            >
                {/*详情*/}
                {showD ? <div className={'Button'} onClick={() => onClickDetail()}><Button
                    style={buttonStyle}
                >{textDetail}</Button>
                </div> : null}

                {/*创建*/}
                {showC ? <div className={'Button'} onClick={() => onClickCreate()}><Button
                    style={buttonStyle}
                >{textCreate}</Button>
                </div> : null}

                {/*拷贝*/}
                {showCp ?
                    <div className={'Button'} onClick={() => onClickCopy()}><Button
                        style={buttonStyle}
                    >{textCopy}</Button></div> : null}

                {/*更新1*/}
                {showU ? <div className={'Button'} onClick={() => onClickU()}><Button
                    danger
                    style={buttonStyle}
                >{textUpdate}</Button>
                </div> : null}

                {/*更新2*/}
                {showUPart ?
                    <div className={'Button'} onClick={() => onClickUPart()}><Button
                        danger
                        style={buttonStyle}
                    >{textUpdatePart}</Button>
                    </div> : null}

                {/*删除*/}
                {showDelete ? <Popconfirm
                    title={'再次确认'}
                    okButtonProps={{danger: true}}
                    onConfirm={() => onClickDelete()}
                    okText={textDelete}
                    cancelText="取消"
                >
                    <div className={'Button'}><Button
                        danger
                        style={buttonStyle}
                    >{textDelete}</Button></div>
                </Popconfirm> : null}

                {this.getSetRight()}

            </div>
        );
    }

    render() {
        let show = tool.getAttribute(this, ['props', 'rightClick', 'show',]);
        if (!show) return null;

        let x = tool.getAttribute(this, ['props', 'rightClick', 'x',]);
        let y = tool.getAttribute(this, ['props', 'rightClick', 'y',]);

        let content = this.getRightMenu();

        return (
            <div className={'rightClick'} style={{
                left: x, top: y,
            }}>
                {content}
            </div>
        )
    }
}

function mapStateToTable(state) {
    return {
        rightClick: state.rightClick,
        setting: state.setting,
    }
}

RightClick = connect(mapStateToTable)(RightClick);

export {RightClick};
