import React from "react";
import {connect} from 'react-redux';
import {tool} from "../../tools/tools";
import {Button, Input, InputNumber, Modal,} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    CoffeeOutlined,
    HourglassOutlined,
    PauseOutlined,
    PlaySquareOutlined
} from "@ant-design/icons";

const artPath = "api_v1/art/"; // 作品
const subArtPath = "api_v1/sub_art/"; // 子作品

class ArtProgressComponent extends React.Component {
    constructor(props) {
        super(props);
        // 子作品创建窗口
        this.subArtCreateData = {
            showSubCreateWindow: false,

            'sub_art_name': null,
        }

        // 作品创建窗口
        this.artCreateData = {
            art_name: null,
            showCreateWindow: false,
        }

        //作品列表数据
        this.artListData = {
            artResponse: {},
            selectedID: null, // 当前选中的作品id
            selectedObj: null, // 当前选中的作品

            selectedSubID: null, // 当前选中的子作品id
            selectedSubObj: null, // 当前选中的子作品
            selectedStatus: null,
        }

        this.state = Object.assign(
            {},
            this.artListData,
            this.artCreateData,
            this.subArtCreateData,
        );
    }

    componentDidMount() {
        this.updateArt();
    }

    updateArt(params = {}, backFunc = null,) {
        const url = tool.path_join(artPath, params,);
        tool.get({
            url: url,
            success: data => {
                this.setState({artResponse: data,});
                if (backFunc) backFunc(data);
            },
            error: data => tool.show_error(data),
        });
    }

    // 作品列表
    reactArtList() {

        // 增加框
        let reactModal = <Modal
            title='增加作品'
            open={this.state.showCreateWindow}
            visible={this.state.showCreateWindow}
            okText={'增加'}
            cancelText={'关闭'}
            onOk={() => {
                tool.post({
                    url: tool.path_join(artPath,),
                    data: {
                        art_name: this.state.art_name,
                    },
                    success: _ => {
                        this.updateArt();
                        tool.show_success('添加成功');
                        this.setState(this.artCreateData); // 清空输入框
                        // this.setState({showCreateWindow: false,});
                    },
                    error: data => tool.show_error(data),
                });
            }}
            onCancel={() => {
                this.setState({showCreateWindow: false,})
            }}
        >
            <Input
                addonBefore={'作品名称'}
                value={this.state.art_name}
                onChange={event => this.setState({
                    art_name: event.target.value,
                })}
            />
        </Modal>;

        // 增加框 子作品
        let reactSubModal = <Modal
            title='增加子作品'
            open={this.state.showSubCreateWindow}
            visible={this.state.showSubCreateWindow}
            okText={'增加'}
            cancelText={'关闭'}
            onOk={() => {
                tool.post({
                    url: tool.path_join(subArtPath,),
                    data: {
                        art_name: this.state.sub_art_name,
                        art_obj: this.state.selectedID,
                    },
                    success: _ => {
                        this.updateArt();
                        tool.show_success('添加成功');
                        this.setState(this.subArtCreateData); // 清空输入框
                        this.setState({showSubCreateWindow: false,});
                    },
                    error: data => tool.show_error(data),
                });
            }}
            onCancel={() => {
                this.setState({showSubCreateWindow: false,})
            }}
        >
            <Input
                addonBefore={'作品名称'}
                value={this.state.sub_art_name}
                onChange={event => this.setState({
                    sub_art_name: event.target.value,
                })}
            />
        </Modal>;

        // 过滤器
        let iconMap = {
            1: <CoffeeOutlined style={{color: 'blue'}}/>,
            2: <PlaySquareOutlined style={{color: 'blue'}}/>,
            3: <HourglassOutlined style={{color: 'green'}}/>,
            4: <PauseOutlined style={{color: 'green'}}/>,
            5: <CheckOutlined style={{color: 'blue'}}/>,
            99: <CloseOutlined style={{color: 'red'}}/>,
        };
        let statusMap = {
            1: "已规划",
            2: "观看中",
            3: "待更新",
            4: "已暂停",
            5: "已看完",
            99: "已放弃",
        }
        let reactButton = [];
        for (const statusKey in statusMap) {
            const value = statusMap[statusKey];
            reactButton.push(<Button
                style={{
                    fontSize: '150%',
                    width: '50%',
                    height: '100px',
                }}
                type={this.state.selectedStatus === statusKey ? 'primary' : ''}
                key={statusKey}
                onClick={() => {

                    if (this.state.selectedStatus !== statusKey) {
                        this.updateArt({status: statusKey,});
                        this.setState({selectedStatus: statusKey,});
                    } else {
                        this.updateArt();
                        this.setState({selectedStatus: null,});
                    }

                }}
            >{value} {iconMap[statusKey]}</Button>);
        }


        // 子作品列表
        const getSubArtList = _line => {
            let reactSubLines = [<div
                key={-1}
                style={{
                    backgroundColor: '',
                    borderRadius: '5px',
                    marginLeft: '10%',
                    marginTop: '5px',
                    marginBottom: '5px',
                    border: 'gray 1px solid',
                    width: '80%',
                    textAlign: 'center',
                    fontSize: '100%',
                    cursor: 'pointer',
                    // position: 'absolute',
                }}
                onClick={() => this.setState({showSubCreateWindow: true,})}
            >
                +
            </div>];
            const sub_info = tool.getAttribute(_line, ['sub_info',], []);
            for (const sub_line of sub_info) {
                let subArtID = sub_line['sub_art_id'];
                let subArt_name = sub_line['art_name'];
                let status = sub_line['status'];

                reactSubLines.push(<div
                    key={subArtID}
                    style={{
                        backgroundColor: this.state.selectedSubID === subArtID ? 'lightgreen' : '',
                        borderRadius: '5px',
                        marginLeft: '10%',
                        marginTop: '5px',
                        marginBottom: '5px',
                        border: 'gray 1px solid',
                        width: '80%',
                        textAlign: 'center',
                        fontSize: '100%',
                        cursor: 'pointer',
                        // position: 'absolute',
                    }}
                    onClick={() => {

                        this.setState({
                            selectedSubID: subArtID,
                            selectedSubObj: sub_line,
                        })
                        // console.log('设置为:',subArtID)

                    }}
                >
                    {subArt_name}
                    <span onClick={() => {
                        let sign = false;
                        let targetStatus = -1;
                        for (const statusKey in iconMap) {
                            if (targetStatus === -1) targetStatus = statusKey;

                            if (sign) {
                                targetStatus = statusKey;
                                break;
                            }

                            if (statusKey.toString() === status.toString()) sign = true;

                        }


                        tool.patch({
                            url: tool.path_join(subArtPath, {}, subArtID,),
                            data: {status: targetStatus},
                            success: _ => {
                                this.updateArt();
                                tool.show_success('更新成功');
                            },
                            error: data => tool.show_error(data),
                        });

                    }}>{iconMap[status]}</span>
                </div>);
            }


            return reactSubLines;
        };

        // 作品列表
        let reactLines = [<div
            key={-1}
            style={{
                backgroundColor: '',
                borderRadius: '5px',
                marginLeft: '10%',
                marginTop: '5px',
                marginBottom: '5px',
                border: 'gray 1px solid',
                width: '80%',
                textAlign: 'center',
                fontSize: '200%',
                cursor: 'pointer',
                // position: 'absolute',
            }}
            onClick={() => this.setState({showCreateWindow: true,})}
        >
            +
        </div>];
        let results = tool.getAttribute(this, ['state', 'artResponse', 'results',], []);
        for (const line of results) {
            let artID = line['art_id'];
            let art_name = line['art_name'];
            let status = line['status'];


            reactLines.push(<div
                key={artID}
                style={{
                    // backgroundColor: this.state.selectedID === artID ? 'lightblue' : '',
                    borderRadius: '5px',
                    marginLeft: '10%',
                    marginTop: '5px',
                    marginBottom: '5px',
                    border: this.state.selectedID === artID ? 'black 2px solid' : 'gray 1px solid',
                    width: '80%',
                    textAlign: 'center',
                    fontSize: '200%',
                    cursor: 'pointer',
                    // position: 'absolute',
                }}
                onClick={() => this.setState({
                    selectedID: artID,
                    selectedObj: line,
                })}
            >
                {art_name}

                <span onClick={() => {


                    let sign = false;
                    let targetStatus = -1;
                    for (const statusKey in iconMap) {
                        if (targetStatus === -1) targetStatus = statusKey;

                        if (sign) {
                            targetStatus = statusKey;
                            break;
                        }

                        if (statusKey.toString() === status.toString()) sign = true;

                    }


                    tool.patch({
                        url: tool.path_join(artPath, {}, artID,),
                        data: {status: targetStatus},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');
                        },
                        error: data => tool.show_error(data),
                    });

                }}>{iconMap[status]}</span>


                {this.state.selectedID === artID ? getSubArtList(line) : null}
            </div>);
        }

        // 过滤器和作品列表
        reactLines = <div>

            {/*过滤器*/}
            <div style={{
                marginLeft: '10%',
                width: '80%',
            }}>
                {reactButton}
            </div>

            {/*列表*/}
            <div style={{
                position: 'absolute',
                display: 'auto',
                // border: 'red 1px solid',
                height: '80%',
                width: '100%',
            }}>
                {reactLines}
            </div>

        </div>;

        // 返回
        let react;
        react = <div>
            {reactModal}
            {reactSubModal}
            {reactLines}
        </div>;
        return react;

    }

    // 主界面
    reactMain() {
        if (!this.state.selectedSubID) return null;

        let react1;
        let react2;

        // 类型
        const typeMap = {
            1: "电视剧",
            2: "电影",
            3: "漫画",
            4: "书籍",
            5: "有声书",
        };
        const reactType = [];
        for (const typeKey in typeMap) {
            const typeName = typeMap[typeKey];
            const lineArtType = tool.getAttribute(this, ['state', 'selectedSubObj', 'art_type',], null).toString();
            reactType.push(<Button
                type={lineArtType === typeKey ? 'primary' : ''}
                key={typeKey}
                style={{
                    marginLeft: '5px',
                }}
                onClick={() => {
                    tool.patch({
                        url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                        data: {art_type: typeKey},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');

                            let selectedSubObj = Object.assign({}, this.state.selectedSubObj,);
                            selectedSubObj['art_type'] = typeKey;
                            this.setState({"selectedSubObj": selectedSubObj,});
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >{typeName}</Button>);
        }

        // 系列状态
        const sysStatusMap = {
            1: "已规划",
            2: "观看中",
            3: "待更新",
            4: "已暂停",
            5: "已看完",
            99: "已放弃",
        };
        const reactSysStatus = [];
        for (const statusKey in sysStatusMap) {
            const statusName = sysStatusMap[statusKey];
            const lineArtStatus = tool.getAttribute(this, ['state', 'selectedObj', 'status',], null).toString();
            reactSysStatus.push(<Button
                type={lineArtStatus === statusKey ? 'primary' : ''}
                key={statusKey}
                style={{
                    marginLeft: '5px',
                }}
                onClick={() => {
                    tool.patch({
                        url: tool.path_join(artPath, {}, this.state.selectedID,),
                        data: {status: statusKey},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');
                            let selectedObj = Object.assign({}, this.state.selectedObj,);
                            selectedObj['status'] = statusKey;
                            this.setState({"selectedObj": selectedObj,});
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >{statusName}</Button>);
        }

        // 状态
        const statusMap = {
            1: "已规划",
            2: "观看中",
            3: "待更新",
            4: "已暂停",
            5: "已看完",
            99: "已放弃",
        };
        const reactStatus = [];
        for (const statusKey in statusMap) {
            const statusName = statusMap[statusKey];
            const lineArtStatus = tool.getAttribute(this, ['state', 'selectedSubObj', 'status',], null).toString();
            reactStatus.push(<Button
                type={lineArtStatus === statusKey ? 'primary' : ''}
                key={statusKey}
                style={{
                    marginLeft: '5px',
                }}
                onClick={() => {
                    tool.patch({
                        url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                        data: {status: statusKey},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');
                            let selectedSubObj = Object.assign({}, this.state.selectedSubObj,);
                            selectedSubObj['status'] = statusKey;
                            this.setState({"selectedSubObj": selectedSubObj,});
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >{statusName}</Button>);
        }


        // 集数列表
        let total = tool.getAttribute(this, ['state', 'selectedSubObj', 'total',], 1);
        let now_index = tool.getAttribute(this, ['state', 'selectedSubObj', 'now_index',], 0);
        let reactTotal = [];
        for (let i = 0; i <= total; i++) {
            reactTotal.push(<Button
                style={{
                    marginLeft: '5px',
                }}
                type={now_index === i ? 'primary' : ''}
                key={i}
                onClick={() => {

                    tool.patch({
                        url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                        data: {now_index: i},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');
                            let selectedSubObj = Object.assign({}, this.state.selectedSubObj,);
                            selectedSubObj['now_index'] = i;
                            this.setState({"selectedSubObj": selectedSubObj,});
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >第{i}集</Button>);
        }

        // 小时
        let hour = tool.getAttribute(this, ['state', 'selectedSubObj', 'hour',], 0);
        let reactHour = [];
        for (let i = 0; i <= 10; i++) {
            reactHour.push(<Button
                style={{
                    marginLeft: '5px',
                }}
                type={hour === i ? 'primary' : ''}
                key={i}
                onClick={() => {
                    tool.patch({
                        url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                        data: {hour: i},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');
                            let selectedSubObj = Object.assign({}, this.state.selectedSubObj,);
                            selectedSubObj['hour'] = i;
                            this.setState({"selectedSubObj": selectedSubObj,});
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >第{i}个小时</Button>);
        }

        // 分钟
        let minute = tool.getAttribute(this, ['state', 'selectedSubObj', 'minute',], 0);
        let reactMinutes = [];
        for (let i = 0; i <= 60; i++) {
            reactMinutes.push(<Button
                style={{
                    marginLeft: '5px',
                }}
                type={minute === i ? 'primary' : ''}
                key={i}
                onClick={() => {
                    tool.patch({
                        url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                        data: {minute: i},
                        success: _ => {
                            this.updateArt();
                            tool.show_success('更新成功');
                            let selectedSubObj = Object.assign({}, this.state.selectedSubObj,);
                            selectedSubObj['minute'] = i;
                            this.setState({"selectedSubObj": selectedSubObj,});
                        },
                        error: data => tool.show_error(data),
                    });
                }}
            >第{i}个分钟</Button>);
        }

        // 名称修改
        let artName = tool.getAttribute(this, ['state', 'selectedObj', 'art_name',], null);
        let reactArtName = <Input
            addonBefore={'系列名称'}
            value={artName}
            onChange={event => {
                let value = event.target.value;
                let selectedObj = this.state.selectedObj;
                selectedObj['art_name'] = value;
                this.setState({selectedObj: selectedObj});
            }}
            onBlur={() => {
                let artName = tool.getAttribute(this, ['state', 'selectedObj', 'art_name',], null);
                if (!artName) return;
                tool.patch({
                    url: tool.path_join(artPath, {}, this.state.selectedID,),
                    data: {art_name: artName},
                    success: _ => {
                        this.updateArt();
                        tool.show_success('更新成功');
                    },
                    error: data => tool.show_error(data),
                });
            }}
        />

        // 子名称修改
        let subArtName = tool.getAttribute(this, ['state', 'selectedSubObj', 'art_name',], null);
        let reactSubArtName = <Input
            addonBefore={'子系列名称'}
            value={subArtName}
            onChange={event => {
                let value = event.target.value;
                let selectedSubObj = this.state.selectedSubObj;
                selectedSubObj['art_name'] = value;
                this.setState({selectedSubObj: selectedSubObj});
            }}
            onBlur={() => {
                let artName = tool.getAttribute(this, ['state', 'selectedSubObj', 'art_name',], null);
                if (!artName) return;
                tool.patch({
                    url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                    data: {art_name: artName},
                    success: _ => {
                        this.updateArt();
                        tool.show_success('更新成功');
                    },
                    error: data => tool.show_error(data),
                });
            }}
        />

        // 集数修改
        let reactTotalAlter = <InputNumber
            addonBefore={'集数'}
            value={total}
            onChange={value => {
                let selectedSubObj = this.state.selectedSubObj;
                selectedSubObj['total'] = value;
                this.setState({selectedSubObj: selectedSubObj});
            }}
            onBlur={() => {
                console.log('')
                let total = tool.getAttribute(this, ['state', 'selectedSubObj', 'total',], null);
                if (!total) return;
                tool.patch({
                    url: tool.path_join(subArtPath, {}, this.state.selectedSubID,),
                    data: {total: total},
                    success: _ => {
                        this.updateArt();
                        tool.show_success('更新成功');
                    },
                    error: data => tool.show_error(data),
                });
            }}
        />

        // 信息显示
        let reactInfo = <div
            style={{
                // border: 'red solid 1px',
                fontSize: '300%',
                textAlign: 'center',
            }}
        >
            《{artName}》 --> 《{subArtName}》 --> 第{now_index}集 --> {hour}:{minute}
        </div>;


        let lineStyle = {
            marginTop: '20px',
        };
        react1 = <div
            style={{
                position: 'absolute',
                width: "100%",
                height: "48%",
                border: "gray 1px solid",
            }}
        >

            {/*信息*/}
            <div style={lineStyle}>{reactInfo}</div>

            {/*状态*/}
            {/*<div style={lineStyle}>{reactStatus}</div>*/}

            {/*集数*/}
            <div style={lineStyle}>{reactTotal}</div>

            {/*第几个小时*/}
            <div style={lineStyle}>{reactHour}</div>

            {/*第几个分钟*/}
            <div style={lineStyle}>{reactMinutes}</div>

        </div>;


        react2 = <div
            style={{
                position: 'absolute',
                width: "100%",
                height: "48%",
                top: "51%",
                border: "gray 1px solid",
            }}
        >

            {/*系列名称*/}
            <div style={lineStyle}>{reactArtName}</div>

            {/*集数*/}
            <div style={lineStyle}>{reactTotalAlter}</div>


            {/*子系列名称*/}
            <div style={lineStyle}>{reactSubArtName}</div>


            {/*类型*/}
            <div style={lineStyle}>作品类型:{reactType}</div>

            {/*系列状态*/}
            {/*<div style={lineStyle}>系列状态:{reactSysStatus}</div>*/}


        </div>;


        let react;
        react = <div>
            {react1}
            {react2}

        </div>;
        return react;
    }

    render() {
        return <div style={{
            position: 'absolute',
            width: "100%",
            height: "100%",
        }}>

            {/*主界面*/}
            <div style={{
                position: 'absolute',
                width: "70%",
                left: "5%",
                top: "5%",
                height: "90%",
                // border: "red 1px solid",
            }}>{this.reactMain()}</div>

            {/*作品列表*/}
            <div style={{
                position: 'absolute',
                width: "20%",
                height: "100%",
                // border: "red 1px solid",
                right: 0,
            }}>{this.reactArtList()}</div>
        </div>;
    }
}

function mapStateToArt(state) {
    return {}
}

const ArtProgress = connect(mapStateToArt)(ArtProgressComponent);

export {
    ArtProgress,
};

