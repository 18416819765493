import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";

import './multiple-choices.css';
import {Tooltip} from "antd";


//多选类型的字段
class FieldMultipleChoices extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
    }

    updateChoices() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let record = tool.getAttribute(paramModal, 'record');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdatePath = columnsUpdatePath.concat([columnKey]);
        let columnCreatePath = columnsCreatePath.concat([columnKey]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);

        // 兼容获取columnCreate/columnUpdate
        let columnInfo = tool.getAttribute(this, ['props', 'param', 'columnInfo',]);
        if (columnInfo) columnCreate = columnInfo;
        if (columnInfo) columnUpdate = columnInfo;

        if (action === 'update') {
            let path = tool.getAttribute(columnUpdate, 'path');
            if (!path) return null;

            let url = tool.get_url(path, false, {
                line_id: record.id,
                father_id: tool.getAttribute(fatherRecord, 'id'),
                page_size: 10000,
            });
            let requestParam = {
                url: url,
                success: data => {
                    columnUpdate = Object.assign({}, columnUpdate);
                    columnUpdate['choices'] = data.results;
                    tool.setStateSpace(columnUpdatePath, columnUpdate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        } else if (action === 'create' || action === 'copy') {
            let path = tool.getAttribute(columnCreate, 'path');
            if (!path) return null;

            let url = tool.get_url(path, false, {
                page_size: 10000,
                father_id: tool.getAttribute(fatherRecord, 'id'),
            });

            let requestParam = {
                url: url,
                success: data => {
                    columnCreate = Object.assign({}, columnCreate);
                    columnCreate['choices'] = data.results;
                    tool.setStateSpace(columnCreatePath, columnCreate);
                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.get(requestParam);
        }

    }

    componentDidMount() {
        this.updateChoices();
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);

        // 如果是拷贝模式,需要提前写入数据,而不是等到变化
        if (action === 'copy') {
            let infoModalPath = ['infoModal'];
            let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
            let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);
            let newDefaultValue = [];
            for (let line of defaultValue) {
                let id = line.id;
                if (!id) id = line;
                newDefaultValue.push(id);
            }

            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = newDefaultValue;
            tool.setStateSpace(dataPath, data);
        }
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let paramModalPath = infoModalPath.concat(['paramModal']);
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let record = tool.getAttribute(paramModal, 'record');

        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);


        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let title = tool.getAttribute(columnUpdate, 'title');
        let help = tool.getAttribute(columnUpdate, 'help');
        if (action === 'create' || action === 'copy') {
            title = tool.getAttribute(columnCreate, 'title');
            help = tool.getAttribute(columnCreate, 'help');
        }

        let defaultValue = tool.getAttribute(this, ['props', 'param', 'defaultValue',]);

        if (!defaultValue) defaultValue = [];
        let choices = tool.getAttribute(columnCreate, 'choices');
        if (action === 'update') choices = tool.getAttribute(columnUpdate, 'choices');



        let choiceReacts = [];
        if (!choices) choices = [];

        // 初始化缺省值
        let newDefaultValue = [];
        for (let choice of choices) if (choice.selected) newDefaultValue.push(choice.id);
        if (newDefaultValue.length > 0) defaultValue = newDefaultValue;

        for (let choice of choices) {

            let dataKey = `create__x`;
            if (action === 'update') dataKey = `update__${record.id}`;
            let values = tool.getAttribute(paramModal, [dataKey, columnKey]);
            let selectedState = false;
            if (values) {
                let index = values.findIndex(id => id.toString() === choice.id.toString());
                if (index !== -1) selectedState = true;
            } else {
                let index = defaultValue.findIndex(line => {
                    let id = line.id;
                    if (id === undefined) id = line;
                    return id.toString() === choice.id.toString();
                });
                if (index !== -1) selectedState = true;
                if (action === 'create') selectedState = false;
            }


            choiceReacts.push(<div key={choice.id} className={'choice-line'}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                    // className={'choice-input'}
                    type={'checkbox'}
                    checked={selectedState}
                    onChange={event => {
                        if (action === 'update') {
                            let dataKey = `update__${record.id}`;
                            let valuePath = paramModalPath.concat([dataKey, columnKey]);
                            let value = tool.getStateSpace(valuePath);
                            if (!value) value = defaultValue.map(item => {
                                let id = item.id;
                                if (id === undefined) id = item;
                                return id;
                            });
                            if (!value) value = [];

                            let newValue = [];
                            for (let id of value) if (choice.id.toString() !== id.toString()) newValue.push(id);
                            if (event.target.checked) newValue.push(choice.id);
                            tool.setStateSpace(valuePath, newValue);
                        } else if (action === 'create' || action === 'copy') {
                            let dataKey = `create__x`;
                            let valuePath = paramModalPath.concat([dataKey, columnKey]);
                            let value = tool.getStateSpace(valuePath);
                            if (!value) value = [];

                            let newValue = [];
                            for (let id of value) if (choice.id.toString() !== id.toString()) newValue.push(id);
                            if (event.target.checked) newValue.push(choice.id);
                            tool.setStateSpace(valuePath, newValue);
                        }
                    }}
                />
                &nbsp;&nbsp;
                {choice.help}
            </div>);
        }


        return (
            <div>
                <Tooltip title={help}>
                    <div>
                        <div className={'title'}>
                            {title}
                        </div>
                    </div>
                </Tooltip>
                <br/>
                {choiceReacts}
            </div>

        )
    }

    render() {
        return (
            <div>
                {this.getContent()}
            </div>
        )
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldMultipleChoices = connect(mapStateToUpdate)(FieldMultipleChoices);

export {FieldMultipleChoices,};
