import React from 'react';
import {Affix, Avatar, Button, Divider, Input, Layout, Menu, Modal, Popconfirm, Radio,} from 'antd';
import {RootTable} from "../power-table/power-table";
import {CheckoutPage} from "./checkout_page/checkout_page";

import {connect} from 'react-redux';
import {tool} from "../tools/tools";
import {setting} from "../../setting";
import './layout.css';
import file from './1.jpeg';
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import Cropper from "react-cropper";
import $ from "jquery";
import {isMobile} from "../assistant/album/album_tool";
import {DeleteOutlined, ToolOutlined} from "@ant-design/icons";

let SubMenu = Menu.SubMenu;
const {Header, Content, Sider, Footer,} = Layout;

function clearAuthenticate() {
    document.cookie = "jwt_token='';Path=/";
    localStorage.removeItem('selectedCode');
    tool.setStateSpace(['setting'], setting);
}

// let menuKeyInt = 1; "react"' 'react' children
let pos = 0;

//资源菜单栏 换个头像 点击换图 提交 已登录 修改密码 退出 red close 关闭目录
class ComponentLayout extends React.Component {

    constructor(props) {
        super(props);
        // this.updateTableData = this.updateTableData.bind(this);
        this.onClickMenu = this.onClickMenu.bind(this);
        this.getMenus = this.getMenus.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getLoginOut = this.getLoginOut.bind(this);
        this.getChoiceButton = this.getChoiceButton.bind(this);
        this.fileChange = this.fileChange.bind(this);
        this.state = {
            // file: null,
            fileName: '默认.jpg',
            src: null,
        }
        this.getMenuKey = spacePrivatePath => {
            // console.log('line--', lineInfo)
            // menuKeyInt += 1;
            // return menuKeyInt;
            // console.log(lineInfo)
            // return lineInfo.name + lineInfo.path;
            // return lineInfo.name;
            // console.log('sp---------', spacePrivatePath)
            return spacePrivatePath.toString();
        };
    }

    onClickMenu(lineSpacePath, isPath, paramFather = false) {
        // lineSpacePath 选中的菜单空间
        // isPath 是否资源菜单
        // paramFather 非手动调用参数

        //选中的资源表格空间里面的表格数据加载状态(stateTableOnload)修改为true
        let spacePrivateKey = `spacePrivate__root`; //根表格的私有空间key
        let spacePrivatePath = lineSpacePath.concat(['table', spacePrivateKey,]);
        // console.log('sp', spacePrivatePath)
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        spacePrivate = Object.assign({}, spacePrivate, {
            stateTableOnload: true,
        });
        tool.setStateSpace(spacePrivatePath, spacePrivate);

        //更新选中的资源
        // let lineInfo = tool.getStateSpace(lineSpacePath);
        // let menuKey = this.getMenuKey(lineInfo);
        let menuKey = this.getMenuKey(spacePrivatePath);

        let setting = tool.getStateSpace(['setting',]);
        let selectedMenu = tool.getAttribute(setting, ['selectedMenu',]);
        if (isPath) {
            selectedMenu = Object.assign({}, selectedMenu, {
                selectedKey: menuKey,
                selectedSpacePath: lineSpacePath,
                paramFather: paramFather,
            });
        } else {
            selectedMenu = Object.assign({}, selectedMenu, {
                selectedGroup: menuKey,
                selectedGroupSpacePath: lineSpacePath,
            });
        }

        setting = Object.assign({}, setting, {
            selectedMenu: selectedMenu,
            onClickMenu: this.onClickMenu,
        });

        tool.setStateSpace(['setting',], setting);
    }

    //  开发模式 编辑head
    getReactHeadEditButton(spacePath) {
        let open_edit = tool.getStateSpace(['open_edit']);
        if (!open_edit) return null;

        // 编辑菜单
        let isSetHeadShowPath = spacePath.concat(['isSetHeadShow']);
        let isSetHeadShow = tool.getStateSpace(isSetHeadShowPath, false);

        let reacts = [];

        // 编辑框标题
        reacts.push(<div key={'edit_title'}>
            <Divider>编辑头部信息</Divider>
        </div>);

        // 头部标题
        let spaceTitlePath = spacePath.concat(['systemTitle']);
        let spaceTitle = tool.getStateSpace(spaceTitlePath);

        let webTitlePath = spacePath.concat(['webTitle']);
        let webTitle = tool.getStateSpace(webTitlePath);


        reacts.push(<table style={{textAlign: 'left',}} key={'table'}>
            <thead>

            {/*    /!*第1行 系统标题*!/*/}
            <tr>
                <th>系统标题:</th>
                <th>
                    <input
                        onBlur={event => {
                            let value = event.target.value;
                            tool.setStateSpace(spaceTitlePath, value);
                        }}
                        defaultValue={spaceTitle}
                    />
                </th>
            </tr>

            {/*    /!*第2行 web标题*!/*/}
            <tr>
                <th>浏览器标签标题:</th>
                <th>
                    <input
                        onBlur={event => {
                            let value = event.target.value;
                            tool.setStateSpace(webTitlePath, value);
                            tool.setTitle(value);
                        }}
                        defaultValue={webTitle}
                    />
                </th>
            </tr>


            </thead>
        </table>);


        let content = <Modal
            visible={isSetHeadShow}
            open={isSetHeadShow}
            onCancel={() => tool.setStateSpace(isSetHeadShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        let react;
        react = <div style={{
            textAlign: 'center',
        }}>
            {content}
            <Button
                onClick={() => {
                    tool.setStateSpace(isSetHeadShowPath, true,);
                }}
                style={{width: '100%',}}
            ><ToolOutlined/>设置头</Button>
        </div>;
        return react;
    }

    //  开发模式 编辑菜单
    getReactMenuEditButton(spacePath) {
        let space = tool.getStateSpace(spacePath);

        // 菜单位置移动
        const moveMenuPos = (menuKey, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let space = tool.getStateSpace(spacePath);
            let spaceArray = Object.keys(space);
            let newMenus = [];
            const sign = 'menu__';
            for (const key of spaceArray) {
                if (!key.startsWith(sign)) continue;
                newMenus.push(key);
            }
            let index = newMenus.indexOf(menuKey);

            let indexTarget = index + direction;
            if (indexTarget < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexTarget > newMenus.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }

            let temp = newMenus[indexTarget];
            newMenus[indexTarget] = newMenus[index];
            newMenus[index] = temp;

            let newSpace = {};
            for (const _key of newMenus)
                newSpace[_key] = space[_key];

            for (const _key of spaceArray)
                if (!_key.startsWith(sign)) newSpace[_key] = space[_key];
            tool.setStateSpace(spacePath, newSpace);
        };

        let reacts = [];

        // 菜单列表
        let reactMenus = [];
        for (const menuKey in space) {
            const sign = 'menu__';
            if (!menuKey.startsWith(sign)) continue;
            let menuSpace = space[menuKey];
            let menuSpacePath = spacePath.concat([menuKey]);
            let menuUrlPath = menuSpacePath.concat(['table', 'path']);
            let url = tool.getStateSpace(menuUrlPath);

            // 是否文件夹
            let isDir = false;
            let sub = tool.getAttribute(menuSpace, ['sub'], false);
            if (sub) isDir = true;

            let menuSpaceHiddenPath = spacePath.concat([menuKey, 'hidden',]);
            let menuSpaceHidden = tool.getStateSpace(menuSpaceHiddenPath);
            if (!menuSpaceHidden) menuSpaceHidden = false;

            reactMenus.push(<div key={menuKey}>
                <Divider>{menuSpace['name']}{isDir ? '(文件夹)' : ''}</Divider>


                {/*删除菜单*/}
                <div
                    style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个菜单吗?"
                        description="删除这个菜单吗?"
                        onConfirm={() => {
                            let newSpace = {};
                            for (const _menuKey in space) {
                                if (_menuKey === menuKey) continue;
                                newSpace[_menuKey] = space[_menuKey];
                            }
                            tool.setStateSpace(spacePath, newSpace);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm></div>


                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*第1行 是否显示*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                tool.setStateSpace(menuSpaceHiddenPath, event.target.value,);
                            }}
                            value={menuSpaceHidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第2行 修改名称*/}
                    <tr>
                        <th>
                            修改名称:
                        </th>
                        <th>
                            <Input
                                onBlur={event => {
                                    let menuNamePath = menuSpacePath.concat(['name']);
                                    tool.setStateSpace(menuNamePath, event.target.value,);
                                }}
                                defaultValue={menuSpace['name']}
                            />
                        </th>
                    </tr>

                    {/*第3行 url*/}
                    <tr>
                        <th>
                            修改url:
                        </th>
                        <th>
                            <Input
                                onBlur={event => {
                                    tool.setStateSpace(menuUrlPath, event.target.value,);
                                }}
                                defaultValue={url}
                            />
                        </th>
                    </tr>

                    {/*第4行 类型*/}
                    <tr>
                        <th>类型:</th>
                        <th><Radio.Group
                            onChange={event => {
                                let value = event.target.value;
                                if (value === isDir) return;

                                // 转化为文件夹
                                if (value) {
                                    let newMenuSpace = {sub: {},};
                                    for (const _key in menuSpace) newMenuSpace[_key] = menuSpace[_key];
                                    tool.setStateSpace(menuSpacePath, newMenuSpace);
                                } else {// 转化为菜单
                                    let newMenuSpace = {
                                        table: {
                                            path: 'xxx/yyy/',
                                            columns: {
                                                col: {title: '演示字段',},
                                            },
                                            columnsDetailLine: {
                                                col: {title: '演示字段',},
                                            },
                                        },
                                    };
                                    for (const _key in menuSpace) if (_key !== 'sub') newMenuSpace[_key] = menuSpace[_key];
                                    tool.setStateSpace(menuSpacePath, newMenuSpace);
                                }

                            }}
                            value={isDir}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>菜单</Radio>
                            <Radio value={true}>菜单夹</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第5行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => moveMenuPos(menuKey, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => moveMenuPos(menuKey, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>

                    </thead>
                </table>


            </div>);
        }

        // 增加菜单
        let random = Math.random() * 100000;
        random = Math.floor(random);
        reactMenus.push(<div key={`menu__${random}`} style={{width: '100%',}}>
            <Divider/>
            <Button
                onClick={() => {
                    let space = tool.getStateSpace(spacePath);
                    let newData = {};
                    let random = Math.random() * 100000;
                    random = Math.floor(random);
                    newData[`menu__${random}`] = {
                        name: '新菜单',
                        table: {
                            path: 'xxx/xxx/',
                            columns: {
                                field_test: {title: '新字段',},
                            },
                        },
                    };
                    let newSpace = Object.assign(space, newData);
                    tool.setStateSpace(spacePath, newSpace);
                }}
                style={{width: '100%',}}>增加菜单</Button>
            <Divider/>
        </div>);
        reacts.push(reactMenus);


        // 编辑菜单
        let isSetMenuShowPath = spacePath.concat(['isSetMenuShow']);
        let isSetMenuShow = tool.getStateSpace(isSetMenuShowPath, false);

        let content = <Modal
            visible={isSetMenuShow}
            open={isSetMenuShow}
            onCancel={() => tool.setStateSpace(isSetMenuShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reacts}
        </Modal>


        let style = {
            width: '100%',
            height: '100%',
        };
        let setLine;
        setLine = <div style={style}>
            {content}
            <Button
                onClick={() => tool.setStateSpace(isSetMenuShowPath, true,)}
                style={{width: '100%', height: '100%',}}
            ><ToolOutlined/>设置菜单</Button>
        </div>;
        return setLine;
    }

    // 开发模式 菜单设置
    getSetLine(spacePath) {
        let open_edit = tool.getStateSpace(['open_edit']);
        if (!open_edit) return null;

        let setLine;
        let style = {
            height: '50px',
        };
        setLine = <div style={style}>
            {/*菜单设置按钮*/}
            {this.getReactMenuEditButton(spacePath)}
        </div>;
        return setLine;
    }

    getMenus(spacePath) {
        if (!spacePath) {
            let rolePath = ['setting', 'authenticate', 'role',];
            let role = tool.getStateSpace(rolePath);
            if (!role) return null;

            spacePath = ['setting', role,];
        }

        let menuInfo = tool.getStateSpace(spacePath);
        // console.log('menuINfo', menuInfo)
        let getMenu = (lineSpacePath) => {
            let lineInfo = tool.getStateSpace(lineSpacePath);
            let react;

            let sub = tool.getAttribute(lineInfo, 'sub',);
            let name = tool.getAttribute(lineInfo, 'name',);
            let spacePrivateKey = `spacePrivate__root`; //根表格的私有空间key
            let spacePrivatePath = lineSpacePath.concat(['table', spacePrivateKey,]);
            // let key = this.getMenuKey(lineInfo);
            let key = this.getMenuKey(spacePrivatePath);

            // 菜单文字
            let reactName = <div>{name}</div>;
            if (isMobile) reactName = <div
                style={{fontSize: '240%',}}
            >{name}</div>;

            // 菜单style
            let menuStyle = {
                width: '100%',
                marginBottom: '20px',
            };

            if (sub) {
                react = <SubMenu
                    style={menuStyle}
                    key={key}
                    title={reactName}
                    onClick={() => this.onClickMenu(lineSpacePath)}
                >
                    {this.getMenus(lineSpacePath.concat(['sub']))}
                </SubMenu>;
            } else {
                // console.log('name-key----', name, key)
                react = <Menu.Item
                    key={key}
                    onClick={() => this.onClickMenu(lineSpacePath, true,)}
                    style={menuStyle}
                >
                    {reactName}
                </Menu.Item>
            }
            return react;
        };

        let menuReact = [];
        for (let lineKey in menuInfo) {
            if (!menuInfo.hasOwnProperty(lineKey)) continue;
            if (lineKey.slice(0, 6) !== 'menu__') continue;
            let hidden = tool.getAttribute(menuInfo[lineKey], 'hidden', false);
            if (hidden) continue;

            menuReact.push(getMenu(spacePath.concat([lineKey])));
        }
        menuReact.push(<div key={'setMenus'}>
            {this.getSetLine(spacePath)}
        </div>)
        return menuReact;
    }

    clearAuthenticate() {
        // document.cookie = "jwt_token='';Path=/";
        // tool.setStateSpace(['setting'], setting);
        clearAuthenticate();
    }

    getLoginOut() {
        let authenticatePath = ['setting', 'authenticate',];
        let authentication = tool.getStateSpace(authenticatePath);
        let username = tool.getAttribute(authentication, 'username');
        let name = tool.getAttribute(authentication, 'name');

        let onClick = () => {
            // document.cookie = "jwt_token='';Path=/";
            // tool.setStateSpace(['setting'], setting);
            this.clearAuthenticate();
            tool.show_info('已退出系统');
        };
        if (isMobile) {
            return <div>

                {/*名字*/}
                <div style={{
                    display: 'inline-block',
                    position: 'absolute',
                    top: '5%',
                    right: `${pos + 100}px`,
                    height: '90%',
                }}>
                    <div style={{
                        fontSize: '120%',
                        color: 'white',
                        border: 'white solid 3px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        width: '80px',
                        overflow: 'auto',
                    }}>{name}</div>
                </div>

                {/*退出*/}
                <div style={{
                    display: 'inline-block',
                    position: 'absolute',
                    top: '5%',
                    right: `${pos + 80}px`,
                    height: '80%',
                }}>
                    <Button
                        type={'link'}
                        onClick={onClick}
                        style={{
                            position: 'absolute',
                            height: '80%',

                            fontSize: '140%',
                            color: 'white',
                            border: 'white solid 3px',
                            borderRadius: '10px',
                        }}
                    >退出</Button>
                </div>
            </div>
        } else {
            return <div className={'login-out'}>
                <div className={'login-out-button'}><Button type={'link'}
                                                            onClick={onClick}>退出</Button>
                </div>
                <div className={'username-info'}>{name}({username})已登录</div>
            </div>
        }
    }

    getChangePassword() {
        let setPasswordPath = ['setting', 'setPassword',];
        let stateShowPath = ['setting', 'setPassword', 'stateShow',];
        let oldPasswordPath = ['setting', 'setPassword', 'oldPassword',];
        let newPasswordPath = ['setting', 'setPassword', 'newPassword',];

        let closeAndClear = () => {
            let setPassword = tool.getStateSpace(setPasswordPath);
            setPassword = Object.assign({}, setPassword, {
                stateShow: false,
                oldPassword: '',
                newPassword: '',
            });
            tool.setStateSpace(setPasswordPath, setPassword);
        };
        let handleOk = () => {
            let oldPassword = tool.getStateSpace(oldPasswordPath);
            let newPassword = tool.getStateSpace(newPasswordPath);
            let data = {
                old_password: oldPassword,
                new_password: newPassword,
            };
            let setPath = tool.getStateSpace(['setting', 'authenticate', 'setPath',]);
            // console.log('set---', setPath)
            let param_request = {
                path: setPath,
                data: data,
                success: data => {
                    tool.show_success(['修改成功！']);
                    closeAndClear();

                    // 清除登录信息
                    this.clearAuthenticate();

                },
                error: data => {
                    tool.show_error(data);
                },
            };
            tool.post(param_request);
        };


        // 显示状态
        let stateShow = tool.getStateSpace(stateShowPath);

        // 输入框内容
        let oldPassword = tool.getStateSpace(oldPasswordPath);
        if (oldPassword === undefined) oldPassword = '';
        let newPassword = tool.getStateSpace(newPasswordPath);
        if (newPassword === undefined) newPassword = '';

        // 修改密码组件
        let reactChangePassword;
        if (isMobile) {
            reactChangePassword = <div style={{
                // border: 'white 2px solid',
                position: 'absolute',
                height: '90%',
                top: '5%',
                right: `${pos + 200}px`,
                // borderRadius: '10px',
            }}>
                <Button
                    type={'link'}
                    onClick={() => tool.setStateSpace(stateShowPath, true)}
                    style={{
                        fontSize: '140%',
                        color: 'white',
                        border: 'white solid 3px',
                        borderRadius: '10px',
                    }}
                >
                    修改密码
                </Button></div>;
        } else {
            reactChangePassword = <div className={'login-out-button'}>
                <Button
                    type={'link'}
                    onClick={() => tool.setStateSpace(stateShowPath, true)}
                >
                    修改密码
                </Button></div>;
        }

        return (<div className={'login-out'}>
            {reactChangePassword}
            <Modal title="修改密码"
                   open={stateShow}
                   visible={stateShow}
                   onOk={handleOk}
                   onCancel={() => closeAndClear()}
                   okText={'提交'}
                   cancelText={'关闭'}
                   width={'300px'}
            >
                <Input.Password
                    placeholder="输入旧密码"
                    addonBefore={'旧密码'}
                    style={{width: '80%',}}
                    value={oldPassword}
                    // value={6}
                    onChange={event => {
                        let value = event.target.value;
                        tool.setStateSpace(oldPasswordPath, value);
                    }}
                />
                <br/><br/>
                <Input.Password
                    placeholder="输入新密码"
                    addonBefore={'新密码'}
                    style={{width: '80%',}}
                    value={newPassword}
                    onChange={event => {
                        let value = event.target.value;
                        tool.setStateSpace(newPasswordPath, value);
                    }}
                />
            </Modal>
        </div>);
    }

    getContent() {
        let lineSpacePath = ['setting', 'selectedMenu', 'selectedSpacePath',];
        let lineSpace = tool.getStateSpace(lineSpacePath);
        let react = tool.getAttribute(lineSpace, 'react');
        if (react) return react();
        if (!lineSpace) return null;
        return <RootTable/>;
    }

    fileChange = event => {
        const MAX_FILE_SIZE = 5 * 1024 * 1024 // 文件最大限制为5M 裁剪
        let files = event.target.files;
        let file = null;
        if (files.length > 0) file = event.target.files[0];
        if (!file) return null;
        if (file.size > MAX_FILE_SIZE) {
            tool.show_info('文件过大');
            return null;
        }

        const fileReader = new FileReader();
        fileReader.onload = event => {
            const dataURL = event.target.result
            this.setState({
                src: dataURL,
                fileName: file.name,
            })
        };
        fileReader.readAsDataURL(file);
    }

    getChoiceButton() {
        if (this.state.isShow) return null;

        let inputId = Math.ceil(Math.random() * 10).toString();
        // 点击换图的样式表
        let buttonStyle = {};
        if (isMobile) buttonStyle = {
            fontSize: '50px',
            height: '80px',
            marginTop: '10px',
        };
        return (

            <div className={'fields-img-path-button'}>
                <input
                    id={inputId}
                    className={'file-input'}
                    type={'file'}
                    onChange={this.fileChange}
                    accept={"image/gif, image/jpeg,image/jpg,image/png,image/bmp,"}
                />
                <div className={'fields-img-path-button-one'}>
                    <Button
                        type={'primary'}
                        onClick={() => $(`#${inputId}`).click()}
                        style={buttonStyle}
                    >
                        点击换图
                    </Button>
                </div>
            </div>
        );
    }

    getCover() {
        let coverPath = ['setting', 'authenticate', 'responseData', 'user_info', 'cover',];
        let cover = tool.getStateSpace(coverPath);

        let setCoverPath = ['setting', 'setCover',];
        let stateShowPath = ['setting', 'setCover', 'stateShow',];

        let closeAndClear = () => {
            let setCover = tool.getStateSpace(setCoverPath);
            setCover = Object.assign({}, setCover, {
                stateShow: false,
                cover: '',
            });
            tool.setStateSpace(setCoverPath, setCover);
        };

        let handleOk = () => {
            let canvas;
            canvas = this.cropper['getCroppedCanvas']();
            if (!canvas) {
                tool.show_info('没有内容.')
                return;
            }
            canvas.toBlob(async blob => {
                let file = new window.File([blob], this.state.fileName, {type: 'image/jpeg'});
                let data = new FormData();
                data.append('cover', file);
                let setPath = tool.getStateSpace(['setting', 'authenticate', 'setPath',]);
                let param_request = {
                    path: setPath,
                    data: data,
                    contentType: false,
                    processData: false,
                    success: data => {
                        tool.setStateSpace(coverPath, data['src']);
                        tool.show_success(['修改成功！']);
                        closeAndClear();
                    },
                    error: data => {
                        tool.show_error(data);
                    },
                };
                tool.post(param_request);
            })
        };

        // 显示状态
        let stateShow = tool.getStateSpace(stateShowPath);
        if (cover) cover = tool.path_join(cover);

        // 计算src
        let src = this.state.src;
        if (!src) src = cover;

        let divStyle = {};
        if (isMobile) divStyle = {
            // border: 'red solid 1px',
            // wight: '100px',
            height: '90%',
            top: '5%',
            position: "absolute",
            right: '5px',
            // borderRadius: '10px',
            // overflow: 'hidden',
        };

        // 头像
        let avatar = <div onClick={() => tool.setStateSpace(stateShowPath, true)}>
            <Avatar shape="square" src={cover} icon={<UserOutlined/>}/>
        </div>;
        if (isMobile) avatar = <img
            onClick={() => tool.setStateSpace(stateShowPath, true)}
            src={cover} alt={'头像'}
            style={{
                height: '100%',
                width: 'auto',
                position: "absolute",
                right: '0',
                borderRadius: '10px',
            }}/>;
        // 提交和取消的样式表
        let submitStyle = {};
        if (isMobile) submitStyle = {
            fontSize: '50px',
            height: '80px',
        };

        return <div className={isMobile ? '' : 'login-cover'} style={divStyle}>
            {avatar}
            <Modal
                title={<div style={{
                    fontSize: isMobile ? '240%' : '100%',
                    textAlign: 'center',
                }}>换个头像</div>}
                open={stateShow}
                visible={stateShow}
                onOk={handleOk}
                onCancel={() => tool.setStateSpace(stateShowPath, false)}
                okText={<div style={submitStyle}>提交</div>}
                cancelText={<div style={submitStyle}>关闭</div>}
                width={isMobile ? '80%' : '300px'}
                footer={<div>
                    <Button onClick={() => tool.setStateSpace(stateShowPath, false)} style={submitStyle}>取消</Button>
                    <Button onClick={handleOk} style={submitStyle} type={'primary'}>提交</Button>
                </div>}
            >
                <div className="cropper-container">
                    <Cropper
                        src={src}
                        ref={cropper => this.cropper = cropper}
                        viewMode={1}
                        zoomable={false}
                        aspectRatio={1}
                    />
                    {this.getChoiceButton()}
                </div>
            </Modal>

        </div>;
    }

    getAffixMenuReact() {
        let rolePath = ['setting', 'authenticate', 'role',];
        let role = tool.getStateSpace(rolePath);
        let spacePath = ['setting', role,];
        let menuReact = this.getMenus(spacePath);

        let stateAffixMenuGlobal;
        stateAffixMenuGlobal = tool.getStateSpace(['setting', 'stateAffixMenu',]);
        let stateAffixMenu = stateAffixMenuGlobal;
        let stateAffixMenuRole = tool.getStateSpace(spacePath.concat(['stateAffixMenu']));
        if (stateAffixMenuRole !== undefined) stateAffixMenu = stateAffixMenuRole;

        if (stateAffixMenu) {
            return <Affix>
                <Menu
                    mode="horizontal"
                    // mode="inline"
                    style={{
                        height: '100%',
                        // backgroundColor: 'red',
                        borderRight: 0,
                        'boxShadow': '2px 2px 10px #909090',
                    }}
                    selectedKeys={[tool.getStateSpace(['setting', 'selectedMenu', 'selectedKey',],)]}
                >
                    {menuReact}
                </Menu>
            </Affix>
        } else {
            let menu;
            if (isMobile) {
                if (!this.props.hiddenMenu) {
                    menu = <Menu
                        mode="inline"
                        style={{
                            width: '500px',
                            height: '100%',
                            // marginTop: '20px',
                            borderRight: 0,
                            'boxShadow': '2px 2px 10px #909090',

                        }}
                        selectedKeys={[tool.getStateSpace(['setting', 'selectedMenu', 'selectedKey',],)]}
                    >
                        {menuReact}
                    </Menu>;
                }
            } else {
                menu = <Menu

                    // 把{menuReact}替换成items可以解除警告[antd: Menu] `children` will be removed in next major version. Please use `items` instead.
                    // items={[
                    //     {label: '菜单项一', key: 'item-1'}, // 菜单项务必填写 key
                    //     {label: '菜单项二', key: 'item-2'},
                    //     {
                    //         label: '子菜单',
                    //         key: 'submenu',
                    //         children: [{label: '子菜单项', key: 'submenu-item-1'}],
                    //     },
                    // ]}
                    mode="inline"
                    style={{
                        borderRight: 0,
                        'boxShadow': '2px 2px 10px #909090',
                    }}
                    selectedKeys={[tool.getStateSpace(['setting', 'selectedMenu', 'selectedKey',],)]}
                >
                    {menuReact}
                </Menu>;
            }

            return <Sider
                width={200}
                className="site-layout-background"
                style={{
                    height: '100%',
                    width: '5px',
                    marginLeft: '20px',
                    marginTop: isMobile ? '40px' : '26px',
                    marginBottom: '100px',
                    // overflow:'hidden',

                    // width:'600px',
                    // backgroundColor: 'red',
                    zIndex: 100,
                }}
            >
                {menu}
            </Sider>
        }
    }

    render() {
        let rolePath = ['setting', 'authenticate', 'role',];
        let role = tool.getStateSpace(rolePath);
        if (!role) return null;

        let spacePath = ['setting', role,];
        let menuInfo = tool.getStateSpace(spacePath);

        // 向第3方页面跳转
        let path = tool.getAttribute(menuInfo, 'path');
        if (path) {
            path = tool.path_join(path);
            window.location.href = path;
        }

        // 向扩展页面跳转 共享store
        let nowPage = tool.getAttribute(menuInfo, 'nowPage');
        if (nowPage) {
            window.history.pushState({}, 'xxx', '?nowPage=s');
            return tool.getStateSpace(['setting', 'pageMap', nowPage, 'react',]);
        }


        let content = this.getContent();

        let systemTitle = tool.getStateSpace(['setting', role, 'systemTitle',]);

        let loginOut = this.getLoginOut();
        let changePassword = this.getChangePassword();
        let cover = this.getCover();
        let footer = tool.getStateSpace(['setting', role, 'footer',]);
        let affixMenuReact = this.getAffixMenuReact();

        let reactHead, layoutStyle;
        if (isMobile) {
            let hiddenMenu = this.props.hiddenMenu;
            let headHeight = 100;
            layoutStyle = {
                backgroundColor: 'rgba(255,255,255,0)',
                top: `${headHeight}px`,
                // border:'red 1px solid',
                position: "relative",
            };
            reactHead = <div style={{
                width: '100%',
                position: "fixed",
                zIndex: 200,
            }}>

                <div style={{
                    backgroundColor: 'black',
                    position: "relative",
                    width: '100%',
                    height: `${headHeight}px`,
                    // zIndex: 200,
                }}>
                    <div style={{
                        position: "absolute",
                        top: '25%',
                        height: '50%',
                        left: '5%',
                        width: '80%',
                    }}>
                        <div style={{
                            fontSize: '250%',
                            color: 'white',
                            position: "absolute",
                        }}>{systemTitle}</div>
                        {changePassword}
                        {loginOut}
                    </div>
                    {cover}
                </div>

                <div
                    style={{
                        backgroundColor: 'lightcyan',
                        zIndex: 200,
                        fontSize: '150%',
                        textAlign: 'center',
                    }}

                    onClick={() => {
                        tool.setStateSpace(['setting', 'hiddenMenu',], !this.props.hiddenMenu);
                    }}
                >
                    {hiddenMenu ? '打开目录' : '关闭目录'}
                </div>

            </div>
            ;
            // reactHead=null
        } else {
            layoutStyle = {};
            reactHead = <Header className="header">
                <div className="logo">{systemTitle}</div>
                {cover}
                {changePassword}
                {loginOut}
                <CheckoutPage/>
            </Header>
        }
        reactHead = <div>
            {this.getReactHeadEditButton(spacePath)}
            {reactHead}
        </div>;

        // 内容区style
        let contentStyle = {
            padding: 24,
            margin: 0,
            minHeight: 280,
        };
        if (isMobile) contentStyle = {
            padding: 24,
            margin: 0,
            minHeight: 280,
            // border: 'red solid 1px',
            position: 'absolute',
            left: '0',
            fontSize: '200px',

        };
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(${file})`,
                    position: 'absolute',
                }}
            >
                <Layout
                    style={{
                        backgroundImage: `url(${file})`,
                    }}
                >
                    {reactHead}
                    <Layout
                        style={layoutStyle}
                    >
                        {affixMenuReact}
                        <Layout style={{
                            padding: '0 24px 24px',
                            backgroundColor: 'rgba(255,255,255,0)',
                        }}>
                            <Content
                                className="site-layout-background"
                                style={contentStyle}
                            >
                                {content}
                            </Content>
                        </Layout>
                    </Layout>
                    <Footer
                        style={{
                            textAlign: 'center',
                            position: 'fixed',
                            width: '100%',
                            bottom: '0',
                            backgroundColor: 'rgba(200,200,200,255)',
                        }}>{footer}
                    </Footer>
                </Layout>
            </div>
        );
    }
}

function mapStateRoLayout(state) {
    return {
        selectedKey: state.setting.selectedMenu.selectedKey,
        setPassword: state.setting.setPassword,
        setCover: state.setting.setCover,
        hiddenMenu: state.setting.hiddenMenu,
        open_edit: state.open_edit,
        isSetMenuShow: state.isSetMenuShow,
        isSetHeadShow: state.isSetHeadShow,
        // stateShow: state.setting.setCover.stateShow,
        setting: state.setting,
    }
}

ComponentLayout = connect(mapStateRoLayout)(ComponentLayout);

export {ComponentLayout, clearAuthenticate,}
