import React from "react";

import {connect} from "react-redux";
import {tool} from "../../../tools/tools";


//React类型的字段
class FieldReact extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getInputInfo = this.getInputInfo.bind(this);
        this.getColumnsUpdate = this.getColumnsUpdate.bind(this);
        this.getColumnsCreate = this.getColumnsCreate.bind(this);
    }

    getInputInfo() {
        let resultValue = tool.getAttribute(this, ['props', 'param', 'resultValue',]);
        let record = tool.getAttribute(this, ['props', 'param', 'record',]);
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);

        if (action === 'create' || action === 'copy') {
            let columnCreatePath = tool.getAttribute(this, ['props', 'param', 'columnCreatePath',]);
            // let columnCreate = tool.getStateSpace(columnCreatePath);
            let columnCreate = this.getColumnsCreate();

            let title = tool.getAttribute(columnCreate, 'title');
            let help = tool.getAttribute(columnCreate, 'help');
            if (!help) help = title;

            return {
                defaultValue: resultValue,
                title: title,
                help: help,
                columnCreate: columnCreate,
                columnCreatePath: columnCreatePath,
                record: record,
                action: action,
            };
        } else if (action === 'update') {
            let columnUpdatePath = tool.getAttribute(this, ['props', 'param', 'columnUpdatePath',]);
            // let columnUpdate = tool.getStateSpace(columnUpdatePath);
            let columnUpdate = this.getColumnsUpdate();

            let title = tool.getAttribute(columnUpdate, 'title');
            let help = tool.getAttribute(columnUpdate, 'help');
            if (!help) help = title;

            return {
                defaultValue: resultValue,
                title: title,
                help: help,
                columnUpdate: columnUpdate,
                columnUpdatePath: columnUpdatePath,
                record: record,
                action: action,
            };
        }
    }

    componentDidMount() {
        //如果是拷贝模式,需要提前写入数据,而不是等到变化
        let inputInfo = this.getInputInfo();
        if (inputInfo.action === 'copy') {
            let valueKey = `value__x`;
            let columnCreate = Object.assign({}, inputInfo.columnCreate);
            columnCreate[valueKey] = inputInfo.defaultValue;
            tool.setStateSpace(inputInfo.columnCreatePath, columnCreate);
        }
    }

    onChange(value) {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, 'record');
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);

        if (action === 'update') {
            let dataKey = `update__${record.id}`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = value;
            tool.setStateSpace(dataPath, data);
        } else if (action === 'create' || action === 'copy') {
            let dataKey = `create__x`;
            let dataPath = infoModalPath.concat(['paramModal', dataKey]);
            let data = tool.getStateSpace(dataPath);
            data = Object.assign({}, data);
            data[columnKey] = value;
            tool.setStateSpace(dataPath, data);
        }
    }

    getColumnsUpdate() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //更新字段
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate',]);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdate) {
            columnsUpdate = filterColumnsUpdate;
        }
        return columnsUpdate;
    }

    getColumnsCreate() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //创建字段
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate',]);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsCreate = tool.getAttribute(filterNow, 'columnsCreate');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsCreate) {
            columnsCreate = filterColumnsCreate;
        }
        return columnsCreate;
    }

    render() {
        let columnsUpdate = this.getColumnsUpdate();
        let columnsCreate = this.getColumnsCreate();
        let action = tool.getAttribute(this, ['props', 'param', 'action',]);
        let columnKey = tool.getAttribute(this, ['props', 'param', 'columnKey',]);
        let columnUpdate = tool.getAttribute(columnsUpdate, columnKey);
        let columnCreate = tool.getAttribute(columnsCreate, columnKey);
        let getReact = tool.getAttribute(columnUpdate, 'react');
        if (action === 'create' || action === 'copy') getReact = tool.getAttribute(columnCreate, 'react');
        let param = Object.assign({}, this.props.param, {
            onChange: this.onChange,
        });
        return getReact(param);
    }
}

function mapStateToUpdate(state) {
    return {
        setting: state.setting,
    }
}

FieldReact = connect(mapStateToUpdate)(FieldReact);

export {FieldReact,};
