import {connect} from "react-redux";
import React from 'react';
import {tool} from "../../tools/tools";
import {Button, Input} from "antd";

const fontSize = '300%';
const moneyPath = 'api_v1/money/'; // 申请单列表
const uploadFilePath = 'api_v1/upload_file/'; // 上传文件
const moneyAppendPath = 'api_v1/money_append/'; // 申请单附件上传

//moneyID
class MoneyUnConnect extends React.Component {
    constructor(props) {
        super(props);
        this.refInput = React.createRef();
        this.state = {
            moneyResponseData: null,
            // amount
            // balance
            // id
            // money_id
            // month
            // step
            // year

        };
    }

    // 客户端及状态
    isHusbandFinish() {
        let href = window.location.href;
        let isHusband = href.includes('husband');
        let step = tool.getAttribute(this, ['state', 'moneyResponseData', 'step',], null);
        let isFinish = step === 6;
        return {
            isHusband: isHusband,
            isFinish: isFinish,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        let url = tool.path_join(moneyPath, {}, -1);
        tool.get({
            url: url,
            success: data => {
                this.setState({moneyResponseData: data,});
            },
            error: data => {
                tool.show_error(data);
            },
        });
    }

    // 日期
    getReactTime() {
        let year = tool.getAttribute(this, ['state', 'moneyResponseData', 'year',], null);
        let month = tool.getAttribute(this, ['state', 'moneyResponseData', 'month',], null);
        let step = tool.getAttribute(this, ['state', 'moneyResponseData', 'step',], null);

        let react;
        react =
            <div
                style={{
                    fontSize: fontSize,
                    textAlign: 'center',
                    backgroundColor: step === 2 ? 'lightyellow' : 'lightblue',
                    borderRadius: '10px',
                    width: '90%',
                    margin: '0 auto',
                }}
            >
                {year} 年 {month} 月 ({step === 2 ? '进行中' : '已完成'})
            </div>;
        return react;
    }

    // 申请
    getReactRequest() {
        let append_req = tool.getAttribute(this, ['state', 'moneyResponseData', 'append_req',], []);
        const {isFinish} = this.isHusbandFinish();

        // 是否需要显示上传
        // let needInput = true;
        // if (isFinish) needInput = false;


        let reactAppends = [];
        for (const appendReqElement of append_req) {
            const couldID = appendReqElement['could_id'];
            const couldPath = appendReqElement['could_path'];
            const appendType = appendReqElement['append_type'];
            if (appendType !== 1) continue;

            const couldUrl = tool.path_join(couldPath);
            reactAppends.push(<div
                key={couldID}
                style={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <img
                    style={{
                        width: '95%',
                        height: 'auto',
                        margin: '10px',
                    }}
                    src={couldUrl}
                    alt={''}
                />
            </div>);
        }

        // 上传按钮
        let reactUploadDiv = <div>
            <input
                type={'file'}
                ref={this.refInput}
                style={{display: 'none',}}
                onChange={event => {
                    let file = event.target.files[0];
                    if (!file) return;
                    let data = new FormData();
                    data.append('file', file,);

                    let moneyID = tool.getAttribute(this, ['state', 'moneyResponseData', 'money_id',], null);
                    if (!moneyID) return;


                    let url = tool.path_join(uploadFilePath);
                    tool.post({
                        url: url,
                        data: data,
                        contentType: false,
                        processData: false,
                        success: data => {
                            const couldID = data['could_id'];

                            let _data = {
                                money_obj: moneyID,
                                could_obj: couldID,
                                append_type: 1,
                            };

                            let url = tool.path_join(moneyAppendPath);
                            tool.post({
                                url: url,
                                data: _data,
                                success: data => {
                                    this.updateData();
                                },
                                error: data => {
                                    tool.show_error(data);
                                },
                            });

                        },
                        error: data => {
                            console.log('请求2')
                        },
                    });
                }}
            ></input>
            <button
                style={{width: '100%',}}
                onClick={() => this.refInput.current.click()}
            >上传余额截图
            </button>
        </div>;
        if (isFinish) reactUploadDiv = false;


        let react;
        react =
            <div
                style={{
                    fontSize: fontSize,
                    textAlign: 'center',
                    backgroundColor: 'lightblue',
                    borderRadius: '10px',
                    width: '90%',
                    margin: '5px auto',
                }}
            >
                {reactAppends}
                {reactUploadDiv}
            </div>;
        return react;
    }

    // 回执
    getReactResponse() {
        let append_req = tool.getAttribute(this, ['state', 'moneyResponseData', 'append_req',], []);
        let reactAppends = [];
        for (const appendReqElement of append_req) {
            const couldID = appendReqElement['could_id'];
            const couldPath = appendReqElement['could_path'];
            const appendType = appendReqElement['append_type'];
            if (appendType !== 2) continue;
            const couldUrl = tool.path_join(couldPath);
            reactAppends.push(<div
                key={couldID}
                style={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <img
                    style={{
                        width: '95%',
                        height: 'auto',
                        margin: '10px',
                    }}
                    src={couldUrl}
                    alt={''}
                />
            </div>);
        }

        // 上传按钮
        let reactUploadDiv = <div>
            <input
                type={'file'}
                ref={this.refInput}
                style={{display: 'none',}}
                onChange={event => {
                    let file = event.target.files[0];
                    if (!file) return;
                    let data = new FormData();
                    data.append('file', file,);

                    let moneyID = tool.getAttribute(this, ['state', 'moneyResponseData', 'money_id',], null);
                    if (!moneyID) return;


                    let url = tool.path_join(uploadFilePath);
                    tool.post({
                        url: url,
                        data: data,
                        contentType: false,
                        processData: false,
                        success: data => {
                            const couldID = data['could_id'];

                            let _data = {
                                money_obj: moneyID,
                                could_obj: couldID,
                                append_type: 2,
                            };

                            let url = tool.path_join(moneyAppendPath, {append_type: 2,});
                            tool.post({
                                url: url,
                                data: _data,
                                success: data => {
                                    this.updateData();
                                },
                                error: data => {
                                    tool.show_error(data);
                                },
                            });

                        },
                        error: data => {
                            console.log('请求2')
                        },
                    });
                }}
            ></input>
            <button
                style={{width: '100%',}}
                onClick={() => this.refInput.current.click()}
            >上传转账截图
            </button>
        </div>;
        const {isHusband,} = this.isHusbandFinish();
        // const {isHusband, isFinish} = this.isHusbandFinish();
        if (!isHusband) reactUploadDiv = null;

        let react;
        react =
            <div
                style={{
                    fontSize: fontSize,
                    textAlign: 'center',
                    backgroundColor: 'lightblue',
                    borderRadius: '10px',
                    width: '90%',
                    margin: '5px auto',
                }}
            >
                {reactAppends}
                {reactUploadDiv}
            </div>;
        return react;
    }

    // 回执
    getReactTextInfo() {
        const patchData = (key, value) => {
            let moneyID = tool.getAttribute(this, ['state', 'moneyResponseData', 'money_id',], null);
            if (!moneyID) return;

            let url = tool.path_join(moneyPath, {}, moneyID);
            let data = {};
            data[key] = value;
            tool.patch({
                url: url,
                data: data,
                success: data => {
                    this.updateData();
                },
                error: data => {
                },
            });
        };
        let balance = tool.getAttribute(this, ['state', 'moneyResponseData', 'balance',], null);
        let amount = tool.getAttribute(this, ['state', 'moneyResponseData', 'amount',], null);
        let step = tool.getAttribute(this, ['state', 'moneyResponseData', 'step',], null);
        const {isHusband, isFinish} = this.isHusbandFinish();

        // 是否需要显示输入框
        let needInput = true;
        if (!isHusband) needInput = false;
        if (isFinish) needInput = false;

        // 是否需要显示切换
        let needSwish = true;
        if (!isHusband) needSwish = false;

        let react;
        react =
            <div
                style={{
                    fontSize: fontSize,
                    // textAlign: 'center',
                    // backgroundColor: 'lightblue',
                    // borderRadius: '10px',
                    width: '90%',
                    margin: '50px auto',
                }}
            >
                {/*合计余额*/}
                <div>
                    合计余额:
                    {needInput ? <Input
                        style={{
                            display: 'inline-block',
                            fontSize: '100%',
                            width: '50%',
                        }}
                        onBlur={event => patchData('balance', event.target.value)}
                    /> : null}

                    {balance}元
                </div>

                {/*入账金额*/}
                <div>
                    入账金额:
                    {needInput ? <Input
                        style={{
                            display: 'inline-block',
                            fontSize: '100%',
                            width: '50%',
                        }}
                        onBlur={event => patchData('amount', event.target.value)}
                    /> : null}
                    {amount}元
                </div>

                {/*更改申请单状态*/}
                {needSwish ? <div>
                    <Button
                        type={step === 2 ? 'primary' : ''}
                        style={{
                            width: '100%',
                            height: '80px',
                            fontSize: '50px',
                            margin: '20px',
                        }}
                        onClick={() => {
                            let map = {
                                2: 6,
                                6: 2,
                            };
                            let stepTarget = map[step];
                            patchData('step', stepTarget);
                        }}
                    >更改申请单状态({step === 2 ? '进行中' : '已完成'})</Button>
                </div> : null}


                <div style={{
                    height: '200px',
                }}/>

            </div>;
        return react;
    }

    render() {
        return <div>

            {/*日期*/}
            {this.getReactTime()}

            {/*申请*/}
            <div>
                {this.getReactRequest()}
            </div>

            {/*回执*/}
            <div>
                {this.getReactResponse()}
            </div>

            {/*文字汇总*/}
            <div>
                {this.getReactTextInfo()}
            </div>

            <div style={{
                height: '200px',
            }}></div>

        </div>;
    }
}

function mapStateToMoney(state) {
    return {}
}

const MoneyComponent = connect(mapStateToMoney)(MoneyUnConnect);
export {MoneyComponent,}
