import {tool} from "../../tools/tools";

function patch_data(param, status) {
    let actionId = tool.getAttribute(param, ['record', 'id',]);
    tool.patch({
        url: tool.path_join('api_v1/back_action/', {}, actionId),
        data: {status: status,},
        success: _ => {
            tool.update(param);
        },
        error: data => tool.show_error(data),
    });
}

function triggerStatusSet1(param) {
    patch_data(param, 1);
}

function triggerStatusSet3(param) {
    patch_data(param, 3);
}

export {triggerStatusSet1, triggerStatusSet3,};
