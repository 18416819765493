import React from "react";
import {Button, Divider, Modal, Popconfirm, Radio, Select} from "antd";

import {tool} from "../tools/tools";
import {connect} from "react-redux";
import {DeleteOutlined, ToolOutlined} from "@ant-design/icons";
import {openEditPath} from "../../index";


//分步更新 stool.show_success d保存并  数据更新s
class UpdateTablePart extends React.Component {
    constructor(props) {
        super(props);
        this.getColumnsUpdatePart = this.getColumnsUpdatePart.bind(this);
        this.getOk = this.getOk.bind(this);
        this.getNowPage = this.getNowPage.bind(this);
        this.getNowPagePath = this.getNowPagePath.bind(this);
    }

    getNowPagePath() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let nowPagePath;
        nowPagePath = spacePrivatePath.concat(['updatePart', 'nowPage']);
        return nowPagePath;
    }

    getNowPage() {
        let nowPagePath = this.getNowPagePath();
        let nowPage = tool.getStateSpace(nowPagePath);
        if (!nowPage) nowPage = 1;
        return nowPage;
    }

    getOk() {
        let ok;
        // 检测是否存在下一页
        let nowPage = this.getNowPage();

        let nextPage = nowPage + 1;
        let nextPageColumns = this.getColumnsUpdatePart(nextPage);
        let last = false;
        if (!nextPageColumns) last = true;

        let onOk = () => this.onOk(false);
        if (last) onOk = () => this.onOk(true);

        let okText = '保存并继续';
        if (last) okText = '完成';

        ok = {
            onOk: onOk,
            okText: okText,
        };
        return ok;
    }

    getFieldReact(param) {
        let columnKey = tool.getAttribute(param, 'columnKey');
        let defaultValue = tool.getAttribute(param, 'defaultValue');

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let record = tool.getAttribute(infoModal, 'record');
        let columnsUpdatePart = this.getColumnsUpdatePart();
        let columnInfo = columnsUpdatePart[columnKey];


        //字段类型
        let fieldType = columnInfo.type;
        if (!fieldType) fieldType = 'char';


        let fieldMapping = tool.getStateSpace(['setting', 'fieldMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);

        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'UpdatePart') return null;


        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        // let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let nowPage;
        nowPage = tool.getAttribute(spacePrivate, ['updatePart', 'nowPage',]);

        if (!nowPage) nowPage = 1;
        // let updatePartSpaceKey = `columnsUpdate_${nowPage}`;

        //更新字段
        // let columnsUpdatePartPath = spacePublicPath.concat([updatePartSpaceKey,]);

        return (
            <div key={columnKey}>
                {func({
                    record: record,
                    action: 'update',
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                    // updateSpacePath: columnsUpdatePartPath,
                    spacePrivatePath: spacePrivatePath,
                    columnInfo: columnInfo,
                })}
                <Divider/>
            </div>
        );
    }

    getColumnsUpdatePart(page = null) {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'UpdatePart') return null;


        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 计算updatePartSpaceKey
        let nowPage;
        nowPage = page ? page : tool.getAttribute(spacePrivate, ['updatePart', 'nowPage',]);

        if (!nowPage) nowPage = 1;
        let updatePartSpaceKey = `columnsUpdate_${nowPage}`;

        //更新字段
        let columnsUpdatePartPath = spacePublicPath.concat([updatePartSpaceKey,]);
        let columnsUpdatePart = tool.getStateSpace(columnsUpdatePartPath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsUpdatePart = tool.getAttribute(filterNow, updatePartSpaceKey);
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdatePart) {
            columnsUpdatePart = filterColumnsUpdatePart;
        }
        return columnsUpdatePart;
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'UpdatePart') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, ['record']);


        //更新字段
        let columnsUpdate = this.getColumnsUpdatePart();

        //显示字段
        if (!columnsUpdate) return null;

        let fieldReacts = [];
        for (let columnKey in columnsUpdate) {
            if (!columnsUpdate.hasOwnProperty(columnKey)) continue;

            //具体的升级列信息
            let columnUpdate = tool.getAttribute(columnsUpdate, columnKey,);

            //默认值 defaultValue
            let defaultValue = tool.getAttribute(columnUpdate, 'defaultValue',);

            //默认值映射列mapColumnKey
            let mapColumnKey = tool.getAttribute(columnUpdate, 'map',);
            if (!mapColumnKey) mapColumnKey = columnKey;

            //映射值
            let mapValue = record[mapColumnKey];
            if (!defaultValue) {
                defaultValue = mapValue;
            }

            //决定是否添加这个字段
            let state = true;
            let fun_is_show = tool.getAttribute(columnUpdate, 'is_show');
            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath, mapColumnKey: mapColumnKey, record: record,};
                state = fun_is_show(param);
            }
            if (state) {
                fieldReacts.push(this.getFieldReact({
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                }));
            }
        }

        return fieldReacts;
    }

    onCancel() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    onOk(last = false) {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'UpdatePart') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, 'record');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);

        let dataKey = `update__${record.id}`;
        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
        let data = tool.getStateSpace(dataPath);
        if (!data) data = {};

        // 获取get参数
        let paramUrl = {};
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;

        let url = tool.get_url(requestPath, false, paramUrl, record.id);

        let stateSplitTwo = false;
        let dataObj;
        //把数据分成两部分
        let otherData = {};
        let filesData = {};
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            let value = data[key];
            if (value instanceof File) {
                filesData[key] = value;
            } else {
                otherData[key] = value;
            }

        }
        otherData = JSON.stringify(otherData);
        if (JSON.stringify(filesData) !== '{}') {
            stateSplitTwo = true;
            dataObj = new FormData();
            dataObj.append('stateSplitTwo', 'true');

            dataObj.append('otherData', otherData);
            for (let fileKey in filesData) dataObj.append(`files__${fileKey}`, filesData[fileKey]);
        }


        let requestParam = {
            url: url,
            data: otherData,
            contentType: 'application/json',
            success: (data, _, response,) => {
                let status = tool.getAttribute(response, 'status');
                if (status === 299) {
                    tool.show_success_299(data);
                } else if (status === 298) {
                    tool.show_success_298(data);
                } else {
                    tool.show_success(['更新成功',]);
                }
                // tool.show_success(['修改成功',]);

                // 更新表单数据
                if (last) {
                    let infoModalPath = ['infoModal'];
                    let infoModal = tool.getStateSpace(infoModalPath);
                    infoModal = Object.assign({}, infoModal, {
                        nowType: null,
                        paramModal: null,
                    });
                    tool.setStateSpace(infoModalPath, infoModal,);
                }


                // 更新表格数据
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: 'page',
                });

                // 设置当前页
                let updatePart = tool.getAttribute(spacePrivate, ['updatePart',]);
                let nowPage;
                if (!last) {
                    nowPage = tool.getAttribute(spacePrivate, ['updatePart', 'nowPage',]);
                    if (!nowPage) nowPage = 1;
                    nowPage += 1;
                } else {
                    nowPage = 1;
                }


                updatePart = Object.assign({}, updatePart, {nowPage: nowPage});
                spacePrivate = Object.assign({}, spacePrivate, {
                    updatePart: updatePart,
                });

                // 更新modal内容
                let infoModalPath = ['infoModal'];
                let infoModal = tool.getStateSpace(infoModalPath);
                infoModal = Object.assign({}, infoModal,);
                setTimeout(() => tool.setStateSpace(infoModalPath, infoModal,), 50);

                tool.setStateSpace(spacePrivatePath, spacePrivate);
            },
            error: data => {
                //左键触发检测程序允许正常运行
                // tool.rightInfo = Object.assign({}, tool.rightInfo, {
                //     sign: false,
                //     onTableMenu: false,
                // },);

                let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
                let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
                tool.show_error(data, columnsUpdate);
                // tool.show_error(data, columnsUpdate);
            },
        };

        //数据分成两部分
        if (stateSplitTwo) {
            requestParam['contentType'] = false;
            requestParam['processData'] = false;
            requestParam['data'] = dataObj;
        }
        tool.patch(requestParam);
    }

    onPrevious() {
        let nowPage = this.getNowPage();
        if (nowPage <= 1) return null;
        nowPage -= 1;
        let nowPagePath = this.getNowPagePath();
        tool.setStateSpace(nowPagePath, nowPage);

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal,);
        tool.setStateSpace(infoModalPath, infoModal,);
    }

    // 字段附加
    getReactFieldAppend(columnPath) {
        let react = null;
        let typePath = columnPath.concat(['type',]);
        let type = tool.getStateSpace(typePath, 'char');

        // 单选类型
        if (tool.isInArray([
            'singleSmallChoice',
            'multipleChoice',
            'singSearchChoice',
        ], type)) {
            let choicesPath = columnPath.concat(['choices',]);
            let choices = tool.getStateSpace(choicesPath, []);

            let lines = [];

            // 列表
            for (const choice of choices) {
                let value = choice['value'];
                let help = choice['help'];
                lines.push(
                    <tr key={value}>

                        {/*value*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let newChoices = [];
                                    for (const _choice of choices) {
                                        let _value = _choice['value'];
                                        let _help = _choice['help'];

                                        let userObj = {"value": _value, "help": _help,};
                                        if (_value === value) userObj = {
                                            "value": event.target.value,
                                            "help": _help,
                                        };
                                        newChoices.push(userObj);
                                    }
                                    tool.setStateSpace(choicesPath, newChoices);
                                }}
                                style={{width: '40px',}}
                                defaultValue={value}
                            />
                        </th>

                        {/*箭头*/}
                        <th>
                            {'<--->'}
                        </th>

                        {/*help*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let newChoices = [];
                                    for (const _choice of choices) {
                                        let _value = _choice['value'];
                                        let _help = _choice['help'];

                                        let userObj = {"value": _value, "help": _help,};
                                        if (_value === value) userObj = {
                                            "value": _value,
                                            "help": event.target.value,
                                        };
                                        newChoices.push(userObj);
                                    }
                                    tool.setStateSpace(choicesPath, newChoices);
                                }}
                                style={{width: '40px',}}
                                defaultValue={help}
                            />
                        </th>

                        {/*删除*/}
                        <th>
                            <Popconfirm
                                title="确定删除吗?"
                                description="确定删除吗?"
                                onConfirm={() => {
                                    let newChoices = [];
                                    for (const _choice of choices) {
                                        let _value = _choice['value'];
                                        let _help = _choice['help'];
                                        if (_value === value) continue;
                                        let userObj = {"value": _value, "help": _help,};
                                        newChoices.push(userObj);
                                    }
                                    tool.setStateSpace(choicesPath, newChoices);
                                }}
                                onCancel={() => {
                                }}
                                okText="删除"
                                cancelText="不要"
                            >
                                <DeleteOutlined style={{color: 'red', marginLeft: '5px',}}/>
                            </Popconfirm>

                        </th>
                    </tr>
                );

            }

            // 增加单选
            lines.push(<div key={'create'} style={{width: '180px',}}>
                <button
                    onClick={() => {
                        let newChoices = Object.assign([], choices);
                        let random = Math.random() * 1000;
                        random = Math.floor(random);
                        let userObj = {
                            "id": `value_${random}`,
                            "value": `value_${random}`,
                            "help": 'new_read',
                        };
                        newChoices.push(userObj);

                        tool.setStateSpace(choicesPath, newChoices);
                    }}
                    style={{width: '100%',}}
                >增加选项
                </button>
            </div>)


            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                    // border:'red 1px solid',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>

                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            {/*真值映射:*/}
                        </th>
                        <th>
                            {lines}
                        </th>
                    </tr>


                    </thead>
                </table>
            </div>
        }

        return react;
    }

    getColumnUpdatePartPath(page = null,) {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);


        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let nowPage;
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        nowPage = page ? page : tool.getAttribute(spacePrivate, ['updatePart', 'nowPage',]);

        if (!nowPage) nowPage = 1;
        let updatePartSpaceKey = `columnsUpdate_${nowPage}`;
        let columnsUpdatePartPath;
        columnsUpdatePartPath = spacePublicPath.concat([updatePartSpaceKey,]);
        return [columnsUpdatePartPath, nowPage];
    }

    // 开发模式
    getReactSetUpdate() {
        let openEdit = tool.getStateSpace(openEditPath, false);
        if (!openEdit) return null;

        let infoModalPath = ['infoModal'];
        let res = this.getColumnUpdatePartPath();
        let columnsUpdatePath = res[0];
        let nowPage = res[1];
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        // 字段位置移动
        const moveUpdatePos = (columnKey, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let columns = tool.getStateSpace(columnsUpdatePath);
            let columnsArray = Object.keys(columns);
            let index = columnsArray.indexOf(columnKey);

            let indexTarget = index + direction;
            if (indexTarget < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexTarget > columnsArray.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }


            let temp = columnsArray[indexTarget];
            columnsArray[indexTarget] = columnsArray[index];
            columnsArray[index] = temp;
            let newColumns = {};
            for (const _columnKey of columnsArray) newColumns[_columnKey] = columns[_columnKey];
            tool.setStateSpace(columnsUpdatePath, newColumns);
        };

        let reactColumns = [];

        // 新建页面
        reactColumns.push(<div key={'create_page'} style={{width: '100%',}}>
            <Divider/>
            当前第{nowPage}页
            <Button
                onClick={() => {
                    let infoModalPath = ['infoModal'];
                    let infoModal = tool.getStateSpace(infoModalPath);

                    let paramModal = tool.getAttribute(infoModal, 'paramModal');
                    let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
                    if (!nowPage) nowPage = 1;
                    let targetPage = nowPage + 1;

                    let columnsUpdatePartTargetPath;
                    while (true) {
                        let _updatePartSpaceKey = `columnsUpdate_${targetPage}`;
                        columnsUpdatePartTargetPath = spacePublicPath.concat([_updatePartSpaceKey,]);
                        let _space = tool.getStateSpace(columnsUpdatePartTargetPath);
                        if (!_space) break;
                        targetPage += 1;
                    }


                    let newData = {};
                    let random = Math.random() * 100000;
                    random = Math.floor(random);
                    newData[`field_${random}`] = {title: '新字段',};
                    tool.setStateSpace(columnsUpdatePartTargetPath, newData);

                    // 翻页
                    let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
                    let pagePath = spacePrivatePath.concat(['updatePart', 'nowPage',]);
                    tool.setStateSpace(pagePath, targetPage);
                }}
                style={{width: '100%',}}>增加页面</Button>
            <Divider/>
        </div>);

        // 列表
        for (const columnKey in columnsUpdate) {
            let columnPath = columnsUpdatePath.concat([columnKey]);
            let column = columnsUpdate[columnKey];

            let typePath = columnPath.concat(['type',]);
            let type = tool.getStateSpace(typePath, 'char');

            // 是否隐藏
            let hiddenPath = columnPath.concat(['hidden']);
            let hidden = tool.getStateSpace(hiddenPath,);


            // 字段附加
            let reactFieldAppend = this.getReactFieldAppend(columnPath);
            let border = reactFieldAppend ? '#BEBEBE 1px solid' : '';

            reactColumns.push(<div
                key={columnKey}
            >

                {/*分割线*/}
                <Divider>字段名:{column.title}</Divider>

                {/*删除字段*/}
                <div
                    style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个字段吗?"
                        description="删除这个字段吗?"
                        onConfirm={() => {
                            let newColumns = {};
                            let columns = tool.getStateSpace(columnsUpdatePath);
                            for (const _columnKey in columns) {
                                if (_columnKey === columnKey) continue;
                                newColumns[_columnKey] = columns[_columnKey];
                            }

                            tool.setStateSpace(columnsUpdatePath, newColumns);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm>
                </div>

                {/*列表*/}
                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*第1行 是否显示*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                // tool.setStateSpace(columnPath, Object.assign(column, {hidden: event.target.value,}));
                                tool.setStateSpace(hiddenPath, event.target.value,);
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第2行 修改字段名*/}
                    <tr>
                        <th>
                            修改字段名:
                        </th>
                        <th>

                            <input
                                defaultValue={column.title}
                                // value={column.title}
                                onBlur={event => {
                                    tool.setStateSpace(columnPath, Object.assign(column, {title: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第3行 字段类型*/}
                    <tr style={{border: border,}}>
                        <th>
                            字段类型:
                        </th>
                        <th>
                            <div>
                                <Select
                                    style={{width: '100%',}}
                                    // defaultValue={type}
                                    value={type}
                                    onChange={value => {
                                        tool.setStateSpace(columnPath, Object.assign(column, {type: value,}));
                                    }}
                                    options={[
                                        {
                                            value: 'bool',
                                            label: '布尔类型',
                                        },
                                        {
                                            value: 'char',
                                            label: '字符串(char)',
                                        },
                                        {
                                            value: 'text',
                                            label: '字符串(text)',
                                        },
                                        {
                                            value: 'file',
                                            label: '文件类型',
                                        },
                                        {
                                            value: 'multipleFile',
                                            label: '多文件类型',
                                        },
                                        {
                                            value: 'multipleChoice',
                                            label: '多项选择类型',
                                        },
                                        {
                                            value: 'singleSmallChoice',
                                            label: '单项选择(简单)',
                                        },
                                        {
                                            value: 'singSearchChoice',
                                            label: '单项选择(搜索)',
                                        },
                                        // {
                                        //     value: 'singDeepChoice',
                                        //     label: '单项选择(深度)',
                                        // },
                                        // {
                                        //     value: 'singNewDeepChoice',
                                        //     label: '单项选择(深度/新)',
                                        // },
                                        {
                                            value: 'imgPath',
                                            label: '图片类型(路径)',
                                        },
                                        {
                                            value: 'imgShow',
                                            label: '图片类型(展示)',
                                        },
                                        {
                                            value: 'imgHead',
                                            label: '图片类型(头像)',
                                        },
                                        {
                                            value: 'multipleImg',
                                            label: '图片类型(多项)',
                                        },
                                        // {
                                        //     value: 'dynamic',
                                        //     label: '动态类型',
                                        // },
                                        {
                                            value: 'time',
                                            label: '时间类型',
                                        },
                                        {
                                            value: 'timeYear',
                                            label: '时间类型(年份)',
                                        },
                                        {
                                            value: 'timeDay',
                                            label: '时间类型(日期)',
                                        },
                                        // {
                                        //     value: 'react',
                                        //     label: '渲染类型',
                                        // },
                                    ]}
                                />
                                <div style={{width: '100%',}}>
                                    {reactFieldAppend}
                                </div>

                            </div>
                        </th>
                    </tr>

                    {/*第4行 映射字段*/}
                    <tr>
                        <th>
                            修改映射字段:
                        </th>
                        <th>

                            <input
                                defaultValue={columnKey}
                                // value={columnKey}
                                onBlur={event => {
                                    let newColumnUpdates = {};

                                    let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
                                    for (const _columnKey in columnsUpdate) {
                                        let useKey = _columnKey;
                                        if (_columnKey === columnKey) useKey = event.target.value;
                                        newColumnUpdates[useKey] = columnsUpdate[_columnKey];
                                    }
                                    tool.setStateSpace(columnsUpdatePath, newColumnUpdates);

                                    // tool.setStateSpace(columnPath, Object.assign(column, {title: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第6行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => moveUpdatePos(columnKey, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => moveUpdatePos(columnKey, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>

                    </thead>
                </table>


            </div>);
        }

        // 增加字段
        reactColumns.push(<div key={'create_field'} style={{width: '100%',}}>
            <Divider/>
            <Button
                onClick={() => {
                    let columns = tool.getStateSpace(columnsUpdatePath);
                    let newData = {};
                    let random = Math.random() * 100000;
                    random = Math.floor(random);
                    newData[`field_${random}`] = {title: '新字段',};
                    let newColumns = Object.assign(columns, newData);
                    tool.setStateSpace(columnsUpdatePath, newColumns);
                }}
                style={{width: '100%',}}>增加字段</Button>
            <Divider/>
        </div>);

        // 新建框
        let isSetUpdateColShowPath = infoModalPath.concat(['isSetUpdateColShow']);
        let isSetUpdateColShow = tool.getStateSpace(isSetUpdateColShowPath, false);

        let content = <Modal
            // closeIcon={' '}
            visible={isSetUpdateColShow}
            open={isSetUpdateColShow}
            onCancel={() => tool.setStateSpace(isSetUpdateColShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reactColumns}
        </Modal>


        let react;
        react = <div>
            {content}
            <Button
                style={{width: '100%',}}
                onClick={() => tool.setStateSpace(isSetUpdateColShowPath, true,)}
            ><ToolOutlined/>编辑</Button>
            <Divider/>
        </div>
        return react;
    }

    // Update
    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'UpdatePart') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        // 是否自定义的触发器
        // let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        // let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsUpdate = this.getColumnsUpdatePart();
        let react = tool.getAttribute(columnsUpdate, 'react__');
        if (react) {
            let param;
            // console.log('??>>',this.props)
            param = Object.assign({}, this.props);
            return react(param);
        }


        let textTitlePath = spacePublicPath.concat(['text__update_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据更新';



        let content = this.getContent();

        // 计算onOk及okText
        let ok = this.getOk();

        // 自定义脚
        let nowPage = this.getNowPage();
        textTitle = `${textTitle}(当前第${nowPage}页)`;
        let leftButton;
        if (nowPage !== 1) leftButton = <Button onClick={() => this.onPrevious()}>上一页</Button>;

        let footer = <div>
            {leftButton}
            <Button onClick={() => ok.onOk()}>{ok.okText}</Button>
        </div>;
        let reactSetUpdate = this.getReactSetUpdate();
        return (

            <div>
                <Modal
                    title={textTitle}
                    open={true}
                    visible={true}
                    // okText={'提交'}
                    okText={ok.okText}
                    cancelText={'关闭'}
                    // onOk={() => this.onOk()}
                    onOk={() => ok.onOk()}
                    onCancel={() => this.onCancel()}
                    footer={footer}
                >
                    {reactSetUpdate}
                    {content}
                </Modal>
            </div>
        );
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
        setting: state.setting,
    }
}

UpdateTablePart = connect(mapStateToUpdate)(UpdateTablePart);

export {UpdateTablePart};
