import React from "react";
import {Button, Divider, Modal, Table, Tooltip,} from "antd";

import {tool} from "../tools/tools";
import {connect} from "react-redux";
import ExportJsonExcel from 'js-export-excel';
import * as XL from 'xlsx';
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";


// 批量数据创建
class CreateBatchTable extends React.Component {
    constructor(props) {
        super(props);
        this.count = null;
        this.count_error = null;
        this.ok = true;
    }

    onImportExcel(file) {
        // 得到创建列信息
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');

        let columnsCreatePath = spacePublicPath.concat(['columnsCreateBatch',]);


        // 更新状态
        let updateState = data => {

            // 制作映射表 help字段映射为key
            let mapping = {};
            let columnsCreate = tool.getStateSpace(columnsCreatePath);
            for (let columnKey in columnsCreate) {
                if (!columnsCreate.hasOwnProperty(columnKey)) continue;
                let column = columnsCreate[columnKey];
                mapping[column.title] = columnKey;
            }

            // 转换数据
            let mapData = [];
            for (let line of data) {
                let newLine = {};
                for (let key in line) {
                    if (!line.hasOwnProperty(key)) continue;
                    if (!mapping[key]) continue;
                    newLine[mapping[key]] = line[key];
                    newLine['info__state'] = 'wait';
                }
                mapData.push(newLine);
            }


            // 数据更新到私有空间
            let spacePrivate = tool.getStateSpace(spacePrivatePath);
            spacePrivate = Object.assign({}, spacePrivate, {
                readData: data,
                mapData: mapData,
            });
            tool.setStateSpace(spacePrivatePath, spacePrivate);
        };

        // 读取完毕的回调
        let fileOnload = event => {
            const {result} = event.target;
            const workbook = XL.read(result, {type: 'binary'});
            let data = [];
            for (const sheet in workbook.Sheets) {
                if (!workbook.Sheets.hasOwnProperty(sheet)) continue;
                data = data.concat(XL.utils.sheet_to_json(workbook.Sheets[sheet]));
                break;
            }
            updateState(data);
        };

        // 读取文件
        const {files} = file.target;
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                fileOnload(event);
            } catch (e) {
                tool.show_info('表格文件读取失败了!');
                return null;
            }
        };
        fileReader.readAsBinaryString(files[0]);
    }

    // Excel模板下载
    getDownloadExcelReact() {

        // 得到列
        let getData = () => {
            // 得到创建列信息
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            let paramModal = tool.getAttribute(infoModal, 'paramModal');
            let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
            let columnsCreatePath = spacePublicPath.concat(['columnsCreateBatch',]);
            let columnsCreate = tool.getStateSpace(columnsCreatePath);


            if (!columnsCreate) {
                tool.show_info('当前数据不支持批量创建!');
                return null;
            }

            let line = [];
            let lines = [line];
            for (let columnKey in columnsCreate) {
                if (!columnsCreate.hasOwnProperty(columnKey)) continue;
                let columnCreate = columnsCreate[columnKey];
                line.push(columnCreate.title);
            }

            return lines;
        };

        let onClick = () => {
            let sheetData = getData();

            let columnWidths = [];
            for (let i = 0; i < sheetData[0].length; i++) columnWidths.push(10);

            let option = {
                fileName: '下载模板',
                datas: [{
                    sheetData: sheetData,
                    columnWidths: columnWidths,
                }],
            };
            let excel_obj = new ExportJsonExcel(option);
            excel_obj.saveExcel();
            tool.show_info('已启动下载!');
        };

        return <Button onClick={onClick}>下载Excel模板</Button>;
    }

    // Excel数据提交
    getSubmitExcelReact() {
        return <input type='file' accept='.xlsx, .xls' onChange={this.onImportExcel}/>;
        // return <Button>提交Excel数据</Button>;
    }

    // 读取到数据展示
    getDataReact() {
        // 得到创建列信息
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreateBatch',]);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);


        // 数据更新到私有空间
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let readData = tool.getAttribute(spacePrivate, ['readData',]);
        if (!readData) readData = [];
        let mapData = tool.getAttribute(spacePrivate, ['mapData',]);
        if (!mapData) mapData = [];
        // console.log('数据readData', readData, spacePrivate);

        // {系统帐号: "xiaoming250", 名字: "小明250", 登录密码: "xiaoming250", __rowNum__: 1}

        let dataSource = [];

        let i = 0;
        for (let line of mapData) {
            i++;
            line = Object.assign({}, line, {
                key: i,
            });
            dataSource.push(line);
        }


        let columns = [];
        for (let columnKey in columnsCreate) {
            if (!columnsCreate.hasOwnProperty(columnKey)) continue;
            let column = columnsCreate[columnKey];
            columns.push({
                title: column.title,
                dataIndex: columnKey,
                key: columnKey,
            });

        }
        columns.push({
            title: '上传状态',
            // dataIndex: ,
            key: 'uploadState',
            render: (record) => {
                let info__state = record.info__state;
                let info__param = info__state.param;
                if (info__param) info__state = info__state.info__state;

                if (info__state === 'wait') return <ClockCircleOutlined/>;
                if (info__state === 'upload') return <LoadingOutlined/>;
                if (info__state === 'success') return <CheckCircleOutlined/>;
                if (info__state === 'error') {
                    let info = null;
                    let info_show = '';
                    let res_json = info__param['responseJSON'];

                    if (res_json) {
                        if (typeof (res_json) === 'string') {
                            info = {'info': res_json};
                        } else {
                            info = Object.assign({}, res_json);
                        }

                        Object.keys(info).map(key => {
                            // let temp = `${key}:${info[key]}    `;
                            let temp = `${info[key]}    `;
                            if (temp.length > 200) temp = '未知异常';
                            info_show += temp;
                            return null;
                        });
                    } else {
                        let info_show = '操作失败[' + info__param.responseText + ']';
                        if (info_show.length > 200) info_show = '未知异常';
                    }


                    return <Tooltip title={info_show}>
                        <CloseCircleOutlined/>
                    </Tooltip>;
                }
                return '未知错误';
            },
        });

        return <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
        />
    }

    getContent() {
        let downloadExcel = this.getDownloadExcelReact();
        let submitExcel = this.getSubmitExcelReact();
        let dataReact = this.getDataReact();

        return (<div>
            {downloadExcel}
            <Divider/>
            {submitExcel}
            <Divider/>
            {dataReact}
        </div>);
    }

    onCancel() {
        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    onOk() {
        if (!this.ok) {
            tool.show_info('你不要点这么快,数据还没提交完呢.');
            return null;
        }
        // 得到创建列信息
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');

        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);


        // 数据更新到私有空间
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let mapDataPath = spacePrivatePath.concat(['mapData',]);
        let mapData = tool.getAttribute(spacePrivate, ['mapData',]);
        if (!mapData) mapData = [];


        // 获取get参数
        let paramUrl = {};
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;

        let url = tool.get_url(requestPath, false, paramUrl);

        // 请求成功或失败都要计数,如果达到条件去做一些事
        let makeCount = () => {
            this.count += 1;
            if (this.count >= this.total) {
                setTimeout(() => {
                    this.ok = true;
                    tool.show_success('所有的数据都已经创建完毕了');
                    if (this.count_error !== 0) {
                        tool.show_info(`其中有${this.count_error}条数据创建失败`);
                    }

                    // 准备刷新页面
                    tool.show_success('准备刷新页面');
                    let spacePrivate = tool.getStateSpace(spacePrivatePath);
                    spacePrivate = Object.assign({}, spacePrivate, {
                        stateTableOnload: 'page',
                    });
                    tool.setStateSpace(spacePrivatePath, spacePrivate);


                }, 3000);
            }
        };

        let reg = line => {
            let newLine = {};
            for (let key in line) {
                if (!line.hasOwnProperty(key)) continue;
                if (key !== 'info__state') newLine[key] = line[key];
            }

            line.info__state = 'upload';
            let mapData = tool.getStateSpace(mapDataPath);
            tool.setStateSpace(mapDataPath, mapData);
            let requestParam = {
                url: url,
                data: newLine,
                success: data => {
                    makeCount();
                    tool.show_success([`第${this.count}条数据创建成功`]);
                    line.info__state = 'success';
                    let mapData = tool.getStateSpace(mapDataPath);
                    tool.setStateSpace(mapDataPath, mapData);
                },
                error: data => {
                    makeCount();
                    this.count_error += 1;
                    tool.show_info(`第${this.count}条数据创建失败`);
                    tool.show_error(data);
                    line.info__state = {
                        info__state: 'error',
                        param: data,
                    };
                    let mapData = tool.getStateSpace(mapDataPath);
                    tool.setStateSpace(mapDataPath, mapData);
                },
            };
            tool.post(requestParam);
        };
        let t = 0;
        this.count = 0;
        this.count_error = 0;
        this.total = 0;
        this.ok = false;
        for (let line of mapData) {
            let info__state = line.info__state;
            let param = info__state.param;
            if (param) info__state = info__state.info__state;

            if (info__state === 'success') continue;
            setTimeout(() => reg(line), t);
            t += 500;
            this.total += 1;
        }
        if (this.total === 0) {
            this.ok = true;
            tool.show_info('没有需要提交的数据,请检查数据');
        }

    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'CreateBatch') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreateBatchPath = spacePublicPath.concat(['columnsCreateBatch']);
        let columnsCreateBatch = tool.getStateSpace(columnsCreateBatchPath);
        if (!columnsCreateBatch) return null;

        let textTitlePath = spacePublicPath.concat(['text__create_batch-title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据批量创建';

        let content = this.getContent();

        return (

            <div>
                <Modal
                    title={textTitle}
                    open={true}
                    visible={true}
                    okText={'提交'}
                    cancelText={'关闭'}
                    onOk={() => this.onOk()}
                    onCancel={() => this.onCancel()}
                >
                    {content}
                </Modal>
            </div>
        );
    }
}

function mapStateToCreate(state) {
    return {
        infoModal: state.infoModal,
        setting: state.setting,
    }
}

CreateBatchTable = connect(mapStateToCreate)(CreateBatchTable);

export {CreateBatchTable};
