import React from 'react';
import {connect} from 'react-redux';
import * as echarts from 'echarts';
import {tool} from "../../tools/tools";
import {InputNumber, Modal, Radio,} from "antd";

const echartsWidth = '1000px';
// 一级
class WeightEcharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weight_nulls: [],
            filterLevel_1: "75",
            filterLevel_2: "75",
            filterLevel_3: "100",
        };
        this.updateData = this.updateData.bind(this);
        this.inputReact = this.inputReact.bind(this);
        this.ok = this.ok.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }

    updateData(param) {
        if (!param) param = {
            filterLevel_1: this.state.filterLevel_1,
            filterLevel_2: this.state.filterLevel_2,
            filterLevel_3: this.state.filterLevel_3,
        };
        let path = 'api_v1/echarts_data/';
        tool.get({
            url: tool.path_join(path, param,),
            success: data => {
                let dom = document.getElementById('main');
                if (dom) {
                    let chartObj = echarts.init(dom);
                    chartObj.setOption(data);
                }
            },
            error: data => {
                tool.show_error(data);
            },
        })

        let pathList = 'api_back/weight/';
        tool.get({
            url: tool.path_join(pathList, {weight_null: true,},),
            success: data => {
                this.setState({
                    weight_nulls: data.results,
                });
            },
            error: data => {
                tool.show_error(data)
            },
        });
    }

    inputReact() {
        const lines = [];
        for (const weight_null of this.state.weight_nulls) {
            lines.push(<div id={weight_null.id}>{weight_null.point}: <InputNumber
                addonBefore={"+"}
                addonAfter={"$"}
                step="0.1"
                onChange={value => {
                    weight_null['value'] = value;
                }}
            /> 斤</div>);
        }

        return lines;

    }

    ok() {
        let allDone = {};
        for (const weight_null of this.state.weight_nulls) {
            tool.patch({
                url: tool.path_join('api_back/weight/', {}, weight_null.id),
                data: {
                    weight: weight_null.value,
                },
                success: data => {
                    allDone[weight_null.id] = true;
                    let doneCount = Object.keys(allDone).length;
                    if (doneCount < this.state.weight_nulls) return;
                    this.updateData();
                },
                error: data => {
                    allDone[weight_null.id] = true;
                    let doneCount = Object.keys(allDone).length;
                    if (doneCount < this.state.weight_nulls) return;
                    this.updateData();
                },
            });
        }

    }

    filter() {
        return <div
            style={{
                width: echartsWidth,
                textAlign: "center",
                marginBottom: '20px',
            }}
        >

            {/*一级放大*/}
            <div>
                <Radio.Group value={this.state.filterLevel_1} onChange={event => {
                    let value = event.target.value;
                    if (value === '_') return;
                    let param = {
                        filterLevel_1: this.state.filterLevel_1,
                        filterLevel_2: this.state.filterLevel_2,
                        filterLevel_3: this.state.filterLevel_3,
                    };
                    param['filterLevel_1'] = value;
                    this.setState({filterLevel_1: value,});
                    this.updateData(param);
                }}>
                    <Radio.Button value="_">一级放大</Radio.Button>
                    <Radio.Button value="0">0-25%</Radio.Button>
                    <Radio.Button value="25">25%-50%</Radio.Button>
                    <Radio.Button value="50">50%-75%</Radio.Button>
                    <Radio.Button value="75">75%-100%</Radio.Button>
                    <Radio.Button value="100">0%-100%</Radio.Button>
                </Radio.Group>
            </div>
            {/*<div style={{height: '5px',}}/>*/}

            {/*二级放大*/}
            <div>
                <Radio.Group value={this.state.filterLevel_2} onChange={event => {
                    let value = event.target.value;
                    if (value === '_') return;
                    let param = {
                        filterLevel_1: this.state.filterLevel_1,
                        filterLevel_2: this.state.filterLevel_2,
                        filterLevel_3: this.state.filterLevel_3,
                    };
                    param['filterLevel_2'] = value;
                    this.setState({filterLevel_2: value,});
                    this.updateData(param);
                }}>
                    <Radio.Button value="_">二级放大</Radio.Button>
                    <Radio.Button value="0">0-25%</Radio.Button>
                    <Radio.Button value="25">25%-50%</Radio.Button>
                    <Radio.Button value="50">50%-75%</Radio.Button>
                    <Radio.Button value="75">75%-100%</Radio.Button>
                    <Radio.Button value="100">0%-100%</Radio.Button>
                </Radio.Group>
            </div>

            {/*三级放大*/}
            <div>
                <Radio.Group value={this.state.filterLevel_3} onChange={event => {
                    let value = event.target.value;
                    if (value === '_') return;
                    let param = {
                        filterLevel_1: this.state.filterLevel_1,
                        filterLevel_2: this.state.filterLevel_2,
                        filterLevel_3: this.state.filterLevel_3,
                    };
                    param['filterLevel_3'] = value;
                    this.setState({filterLevel_3: value,});
                    this.updateData(param);
                }}>
                    <Radio.Button value="_">三级放大</Radio.Button>
                    <Radio.Button value="0">0-25%</Radio.Button>
                    <Radio.Button value="25">25%-50%</Radio.Button>
                    <Radio.Button value="50">50%-75%</Radio.Button>
                    <Radio.Button value="75">75%-100%</Radio.Button>
                    <Radio.Button value="100">0%-100%</Radio.Button>
                </Radio.Group>
            </div>

        </div>
    }

    render() {
        let visible = this.state.weight_nulls.length !== 0;

        return <div style={{marginTop: '20px',}}>
            {this.filter()}
            <div
                id="main"
                style={{
                    width: echartsWidth,
                    height: '500px',
                }}
            ></div>
            <Modal
                title="以下日期的体重数据还没有录入"
                open={visible}
                visible={visible}
                onOk={() => {
                    this.ok();
                }}
                onCancel={() => {
                    this.setState({weight_nulls: []});
                }}
            >{this.inputReact()}</Modal>
        </div>


    }
}

function mapStateToEcharts(state) {
    return {}
}

WeightEcharts = connect(mapStateToEcharts)(WeightEcharts);

export {WeightEcharts};

