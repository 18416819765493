import React from 'react';
import {Select, Radio} from "antd";
import {connect} from 'react-redux';
import {tool} from "../../../tools/tools";

import './filters.css';

// 旧过滤器
class FilterOldUnConnected extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getFilters = this.getFilters.bind(this);
    }


    onChange(value) {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        // let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 修改静态过滤器的值
        let filterValuePath = spacePrivatePath.concat(['filterValue']);
        tool.setStateSpace(filterValuePath, value);

        // 修改更新状态
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
        tool.setStateSpace(stateTableOnloadPath, 'filter');

        // 重新载入字段信息
    }


    getFilters() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 过滤器信息
        let filterSpace = tool.getAttribute(spacePublic, 'filters');
        if (!filterSpace) return null;

        // 过滤器选项信息
        let filterChoices = tool.getAttribute(filterSpace, 'choices');

        let Option = Select.Option;
        let filterChoiceKeys = Object.keys(filterChoices);
        let options = [];
        for (let filterChoiceKey of filterChoiceKeys) {
            let filterInfo = filterChoices[filterChoiceKey];
            let filterText = tool.getAttribute(filterInfo, 'text');
            if (!filterText) filterText = '未定义';
            // console.log('--------', filterInfo)
            options.push(<Option value={filterChoiceKey} key={filterText}>{filterText}</Option>);
        }

        // 得到当前的值
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }

        return (<div className={'filters'}>
            <Select
                value={filterValue}
                onChange={value => this.onChange(value)}
                className={'filters_choices'}
            >
                {options}
            </Select>
        </div>);
    }

    render() {
        return this.getFilters();
    }
}

function state_to_old(state) {

    return {};
}

function old_to_state(_) {
    return {};
}

let FilterOld = connect(state_to_old, old_to_state)(FilterOldUnConnected);


// 行过滤器
class FilterRowUnConnected extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getFilters = this.getFilters.bind(this);
    }

    onChange(value, filterKey) {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        // let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 修改静态过滤器的值
        let filterValuePath = spacePrivatePath.concat(['params']);
        let params = tool.getStateSpace(filterValuePath, {});
        params[filterKey] = value;
        tool.setStateSpace(filterValuePath, params);

        // 修改更新状态
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
        tool.setStateSpace(stateTableOnloadPath, 'filter');
    }

    getFilters() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 过滤器信息
        let filtersInfo = {};
        let sign = 'filter__';
        const signLength = sign.length;
        for (const key in spacePublic) {
            if (!key.startsWith(sign)) continue;
            let filterInfo = tool.getAttribute(spacePublic, key);
            let type = tool.getAttribute(filterInfo, 'type');
            let hidden = tool.getAttribute(filterInfo, 'hidden');
            if (type !== 'row') continue;
            if (hidden) continue;
            filtersInfo[key] = filterInfo;
        }
        if (JSON.stringify(filtersInfo) === '{}') return null;

        // 所有列表
        let reactButtons = [];
        for (let key in filtersInfo) {
            const filterKey = key.substring(signLength);
            let value = filtersInfo[key];

            // 一个列表
            let reactChoices = [];
            const choices = tool.getAttribute(value, 'choices', []);
            for (const choiceKey in choices) {
                reactChoices.push(<Radio.Button value={choiceKey} key={choiceKey}>
                    {choices[choiceKey]['text']}
                </Radio.Button>)
            }

            // 当前选中
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
            let filterValuePath = spacePrivatePath.concat(['params']);
            let params = tool.getStateSpace(filterValuePath, {});
            let valueSelected = params[filterKey];
            if (valueSelected === undefined) valueSelected = tool.getAttribute(value, 'default', null);


            let reactList = <div
                style={{
                    marginLeft: '10px',
                    display: 'inline-block',
                }}
                key={key}
            ><Radio.Group
                value={valueSelected}
                onChange={event => this.onChange(event.target.value, filterKey)}
                key={filterKey}
            >
                {reactChoices}
            </Radio.Group></div>;

            reactButtons.push(reactList);
        }

        return <div
            style={{
                marginLeft: '10px',
                marginRight: '10px',
                display: 'inline-block',
            }}
        >{reactButtons}</div>;
    }

    render() {
        return this.getFilters();
    }
}

function state_to_row(state) {

    return {};
}

function row_to_state(_) {
    return {};
}

let FilterRow = connect(state_to_row, row_to_state)(FilterRowUnConnected);


// 列过滤器
class FilterColUnConnected extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getFilters = this.getFilters.bind(this);
    }

    onChange(value, filterKey) {
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        // let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 修改静态过滤器的值
        let filterValuePath = spacePrivatePath.concat(['params']);
        let params = tool.getStateSpace(filterValuePath, {});
        params[filterKey] = value;
        tool.setStateSpace(filterValuePath, params);

        // 修改更新状态
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
        tool.setStateSpace(stateTableOnloadPath, 'filter');
    }


    getFilters() {
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 过滤器信息
        let filtersInfo = {};
        let sign = 'filter__';
        const signLength = sign.length;
        for (const key in spacePublic) {
            if (!key.startsWith(sign)) continue;
            let filterInfo = tool.getAttribute(spacePublic, key);
            let type = tool.getAttribute(filterInfo, 'type');
            let hidden = tool.getAttribute(filterInfo, 'hidden');
            if (hidden) continue;
            if (type !== 'col') continue;
            filtersInfo[key] = filterInfo;
        }
        if (JSON.stringify(filtersInfo) === '{}') return null;

        // 所有列表
        let reactButtons = [];
        for (let key in filtersInfo) {
            const filterKey = key.substring(signLength);
            let value = filtersInfo[key];

            // 一个列表
            let reactChoices = [];
            const choices = tool.getAttribute(value, 'choices', []);
            for (const choiceKey in choices) {
                reactChoices.push(<Select.Option value={choiceKey} key={choiceKey}>
                    {choices[choiceKey]['text']}
                </Select.Option>)
            }

            // 当前选中
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
            let filterValuePath = spacePrivatePath.concat(['params']);
            let params = tool.getStateSpace(filterValuePath, {});
            let valueSelected = params[filterKey];
            if (valueSelected === undefined) valueSelected = tool.getAttribute(value, 'default', null);


            let reactList = <div
                style={{
                    marginLeft: '10px',
                    display: 'inline-block',
                }}
                key={key}
            ><Select
                value={valueSelected}
                onChange={value => this.onChange(value, filterKey)}
                className={'filters_choices'}
                key={filterKey}
            >
                {reactChoices}
            </Select></div>;


            reactButtons.push(reactList);
        }

        return <div
            style={{
                marginLeft: '10px',
                marginRight: '10px',
                display: 'inline-block',
            }}
        >{reactButtons}</div>;
    }


    render() {
        return this.getFilters();
    }
}

function state_to_col(state) {

    return {};
}

function col_to_state(_) {
    return {};
}

let FilterCol = connect(state_to_col, col_to_state)(FilterColUnConnected);


// 过滤器
class FilterUnConnected extends React.Component {
    render() {
        return <div>
            <FilterOld param={this.props['param']}/>
            <FilterCol param={this.props['param']}/>
            <FilterRow param={this.props['param']}/>
        </div>;
    }
}

function state_to_filter(state) {

    return {};
}

function filter_to_state(_) {
    return {};
}

let Filter = connect(state_to_filter, filter_to_state)(FilterUnConnected);

export {Filter}
