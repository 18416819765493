import {tool} from "../../tools/tools";
import {update} from "./trigger_tool";

function trigger(param, delta) {
    // const param = {
    //     record: record,
    //     columnName: columnName,
    //     triggerPublicSpacePath: triggerPublicSpacePath,
    //     spacePrivatePath: spacePrivatePath,
    //     triggerPrivateSpacePath: triggerPrivateSpacePath,
    // };
    // 操作逻辑
    // tool.patch({
    //
    // });
    let id = tool.getAttribute(param, ['record', 'id',],);
    let index = tool.getAttribute(param, ['record', 'index',], 9999);
    index = index + delta;
    tool.patch({
        url: tool.path_join('api_back/book/', {}, id),
        data: {index: index,},
        success: data => {
            update(param);
        },
        error: data => tool.show_error(data),
    });
}

function triggerUp(param) {
    trigger(param, -3);

}

function triggerDown(param) {
    trigger(param, 3);

}

function triggerSetCode(param) {
    tool.patch({
        url: tool.path_join('api_v1/self/x/'),
        data: {set_code: 1,},
        success: data => update(param),
        error: data => tool.show_error(data),
    });

}

export {triggerUp, triggerDown, triggerSetCode,}