import React from "react";
import {Button, Divider, Modal, Popconfirm, Progress, Radio, Select,} from "antd";

import $ from 'jquery';
import {tool} from "../tools/tools";
import {connect} from "react-redux";
import {openEditPath,} from "../../index";
import {DeleteOutlined, ToolOutlined} from "@ant-design/icons";

//数据创建 space
class CreateTable extends React.Component {
    getFieldReact(param) {
        let columnKey = tool.getAttribute(param, 'columnKey');
        let defaultValue = tool.getAttribute(param, 'defaultValue');

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        let columnInfo = columnsCreate[columnKey];


        //字段类型
        let fieldType = columnInfo.type;
        if (!fieldType) fieldType = 'char';


        let fieldMapping = tool.getStateSpace(['setting', 'fieldMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);

        return (
            <div key={columnKey}>
                {func({
                    action: 'create',
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                })}
                <Divider/>
            </div>
        );
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        //创建字段
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate',]);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        //显示字段
        if (!columnsCreate) return null;

        let fieldReacts = [];
        for (let columnKey in columnsCreate) {
            if (!columnsCreate.hasOwnProperty(columnKey)) continue;

            //具体的创建列信息
            let columnCreate = tool.getAttribute(columnsCreate, columnKey,);
            let hidden = tool.getAttribute(columnCreate, 'hidden', false);
            if (hidden) continue;
            //默认值defaultValue
            let defaultValue = tool.getAttribute(columnCreate, 'defaultValue',);

            //决定是否添加这个字段
            let state = true;
            let fun_is_show = tool.getAttribute(columnCreate, 'is_show');
            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath,};
                state = fun_is_show(param);
            }
            if (state) {
                fieldReacts.push(this.getFieldReact({
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                }));
            }
        }

        return fieldReacts;
    }

    getProgress() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let progressInfo = tool.getAttribute(spacePrivate, 'progressInfo');
        let stateCreateUpload = tool.getAttribute(spacePrivate, 'stateCreateUpload');
        if (!stateCreateUpload) return null;

        let percent = tool.getAttribute(progressInfo, 'percent');
        if (!percent) percent = 0;
        return <div>
            <Progress percent={percent} status="active"/>
        </div>;
    }

    onCancel() {
        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let stateCreateUploadPath = spacePrivatePath.concat(['stateCreateUpload']);

        // let stateCreateUploadPath = spacePrivatePath.concat(['stateCreateUpload']);
        let stateCreateUpload = tool.getStateSpace(stateCreateUploadPath);
        if (stateCreateUpload) {
            tool.show_info('请耐心等待数据创建完成.');
            return null;
        }

        // tool.setStateSpace(stateCreateUploadPath, false);

        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });
        tool.setStateSpace(infoModalPath, infoModal,);


    }

    onOk() {
        let infoModalPath = ['infoModal'];

        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);

        let dataKey = `create__x`;
        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
        let data = tool.getStateSpace(dataPath);
        if (!data) data = {};

        // 获取get参数
        let paramUrl = {};
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;

        let url = tool.get_url(requestPath, false, paramUrl);

        let stateSplitTwo = false;
        let dataObj;

        // 设置为正在上传
        let stateCreateUploadPath = spacePrivatePath.concat(['stateCreateUpload']);
        tool.setStateSpace(stateCreateUploadPath, true);
        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);

        //把数据分成两部分
        let otherData = {};
        let filesData = {};
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            let value = data[key];
            if (value instanceof File) {
                filesData[key] = value;
            } else {
                otherData[key] = value;
            }

        }
        otherData = JSON.stringify(otherData);
        if (JSON.stringify(filesData) !== '{}') {
            stateSplitTwo = true;
            dataObj = new FormData();
            dataObj.append('stateSplitTwo', 'true');

            dataObj.append('otherData', otherData);
            for (let fileKey in filesData) dataObj.append(`files__${fileKey}`, filesData[fileKey]);
        }

        // 置空进度条
        let percent = 0; //进度值（百分比制）
        let percentPath = spacePrivatePath.concat(['progressInfo', 'percent',]);
        tool.setStateSpace(percentPath, percent);
        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);

        let requestParam = {
            url: url,
            data: otherData,
            contentType: 'application/json',
            success: (data, _, response,) => {
                let status = tool.getAttribute(response, 'status');
                if (status === 299) {
                    tool.show_success_299(data);
                } else {
                    tool.show_success(['创建成功',]);
                }

                // 清空表单数据
                let infoModal = tool.getStateSpace(infoModalPath);
                infoModal = Object.assign({}, infoModal, {
                    nowType: null,
                    paramModal: null,
                });
                tool.setStateSpace(infoModalPath, infoModal,);

                // 更新表格数据
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: 'page',
                    stateCreateUpload: false,
                    selfProgress: false,// 重置进度条为活跃
                });
                tool.setStateSpace(spacePrivatePath, spacePrivate);
            },
            error: data => {
                let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
                let columnsCreate = tool.getStateSpace(columnsCreatePath);
                tool.show_error(data, columnsCreate);
                tool.setStateSpace(stateCreateUploadPath, false);
            },
            xhr: function () { //用以显示上传进度
                let xhr = $.ajaxSettings.xhr();
                if (xhr.upload) {
                    xhr.upload.addEventListener('progress', function (event) {
                        let percent = Math.floor(event.loaded / event.total * 100); //进度值（百分比制）
                        let percentPath = spacePrivatePath.concat(['progressInfo', 'percent',]);
                        tool.setStateSpace(percentPath, percent);
                        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);
                    }, false);
                }
                return xhr
            },
        };

        //数据分成两部分
        if (stateSplitTwo) {
            requestParam['contentType'] = false;
            requestParam['processData'] = false;
            requestParam['data'] = dataObj;
        }

        tool.post(requestParam);
    }

    isShowPart() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let createPartPath = spacePublicPath.concat(['columnsCreate_1']);
        let createPartSpace = tool.getStateSpace(createPartPath);
        if (!createPartSpace) return null;
        return true;
    }

    // 字段附加
    getReactFieldAppend(columnPath) {
        let react = null;
        let typePath = columnPath.concat(['type',]);
        let type = tool.getStateSpace(typePath, 'char');

        // 单选类型
        if (tool.isInArray([
            'singleSmallChoice',
            'multipleChoice',
            'singSearchChoice',
        ], type)) {
            let choicesPath = columnPath.concat(['choices',]);
            let choices = tool.getStateSpace(choicesPath, []);

            let lines = [];

            // 列表
            for (const choice of choices) {
                let value = choice['value'];
                let help = choice['help'];
                lines.push(
                    <tr key={value}>

                        {/*value*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let newChoices = [];
                                    for (const _choice of choices) {
                                        let _value = _choice['value'];
                                        let _help = _choice['help'];

                                        let userObj = {"value": _value, "help": _help,};
                                        if (_value === value) userObj = {
                                            "value": event.target.value,
                                            "help": _help,
                                        };
                                        newChoices.push(userObj);
                                    }
                                    tool.setStateSpace(choicesPath, newChoices);
                                }}
                                style={{width: '40px',}}
                                defaultValue={value}
                            />
                        </th>

                        {/*箭头*/}
                        <th>
                            {'<--->'}
                        </th>

                        {/*help*/}
                        <th>
                            <input
                                onBlur={event => {
                                    let newChoices = [];
                                    for (const _choice of choices) {
                                        let _value = _choice['value'];
                                        let _help = _choice['help'];

                                        let userObj = {"value": _value, "help": _help,};
                                        if (_value === value) userObj = {
                                            "value": _value,
                                            "help": event.target.value,
                                        };
                                        newChoices.push(userObj);
                                    }
                                    tool.setStateSpace(choicesPath, newChoices);
                                }}
                                style={{width: '40px',}}
                                defaultValue={help}
                            />
                        </th>

                        {/*删除*/}
                        <th>
                            <Popconfirm
                                title="确定删除吗?"
                                description="确定删除吗?"
                                onConfirm={() => {
                                    let newChoices = [];
                                    for (const _choice of choices) {
                                        let _value = _choice['value'];
                                        let _help = _choice['help'];
                                        if (_value === value) continue;
                                        let userObj = {"value": _value, "help": _help,};
                                        newChoices.push(userObj);
                                    }
                                    tool.setStateSpace(choicesPath, newChoices);
                                }}
                                onCancel={() => {
                                }}
                                okText="删除"
                                cancelText="不要"
                            >
                                <DeleteOutlined style={{color: 'red', marginLeft: '5px',}}/>
                            </Popconfirm>

                        </th>
                    </tr>
                );

            }

            // 增加单选
            lines.push(<div key={'create'} style={{width: '180px',}}>
                <button
                    onClick={() => {
                        let newChoices = Object.assign([], choices);
                        let random = Math.random() * 1000;
                        random = Math.floor(random);
                        let userObj = {
                            "id": `value_${random}`,
                            "value": `value_${random}`,
                            "help": 'new_read',
                        };
                        newChoices.push(userObj);

                        tool.setStateSpace(choicesPath, newChoices);
                    }}
                    style={{width: '100%',}}
                >增加选项
                </button>
            </div>)


            react = <div
                style={{
                    width: '185px',
                    overflow: 'auto',
                    // border:'red 1px solid',
                }}
            >
                <table style={{
                    textAlign: 'left',
                }}>
                    <thead>

                    {/*第1行 真值映射*/}
                    <tr>
                        <th>
                            {/*真值映射:*/}
                        </th>
                        <th>
                            {lines}
                        </th>
                    </tr>


                    </thead>
                </table>
            </div>
        }

        return react;
    }

    getReactSetCreate() {
        let openEdit = tool.getStateSpace(openEditPath, false);
        if (!openEdit) return null;

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);

        // 字段位置移动
        const moveCreatePos = (columnKey, direction = -1) => {
            // direction=-1 上移 direction=1 下移
            let columns = tool.getStateSpace(columnsCreatePath);
            let columnsArray = Object.keys(columns);
            let index = columnsArray.indexOf(columnKey);

            let indexTarget = index + direction;
            if (indexTarget < 0) {
                tool.show_info('已经在最顶部.');
                return;
            }
            if (indexTarget > columnsArray.length - 1) {
                tool.show_info('已经在最底部.');
                return;
            }


            let temp = columnsArray[indexTarget];
            columnsArray[indexTarget] = columnsArray[index];
            columnsArray[index] = temp;
            let newColumns = {};
            for (const _columnKey of columnsArray) newColumns[_columnKey] = columns[_columnKey];
            tool.setStateSpace(columnsCreatePath, newColumns);
        };

        let reactColumns = [];
        // 列表
        for (const columnKey in columnsCreate) {
            let columnPath = columnsCreatePath.concat([columnKey]);
            let column = columnsCreate[columnKey];

            let typePath = columnPath.concat(['type',]);
            let type = tool.getStateSpace(typePath, 'char');

            // 是否隐藏
            let hiddenPath = columnPath.concat(['hidden']);
            let hidden = tool.getStateSpace(hiddenPath,);


            // 字段附加
            let reactFieldAppend = this.getReactFieldAppend(columnPath);
            let border = reactFieldAppend ? '#BEBEBE 1px solid' : '';

            reactColumns.push(<div
                key={columnKey}
            >

                {/*分割线*/}
                <Divider>字段名:{column.title}</Divider>

                {/*删除字段*/}
                <div
                    style={{float: 'right',}}>
                    <Popconfirm
                        title="删除这个字段吗?"
                        description="删除这个字段吗?"
                        onConfirm={() => {
                            let newColumns = {};
                            let columns = tool.getStateSpace(columnsCreatePath);
                            for (const _columnKey in columns) {
                                if (_columnKey === columnKey) continue;
                                newColumns[_columnKey] = columns[_columnKey];
                            }

                            tool.setStateSpace(columnsCreatePath, newColumns);
                        }}
                        onCancel={() => tool.show_info('已取消')}
                        okText="删除"
                        cancelText="不要"
                    >
                        <DeleteOutlined style={{color: 'red',}}/>
                    </Popconfirm>
                </div>

                {/*列表*/}
                <table style={{textAlign: 'left',}}>
                    <thead>

                    {/*第1行 是否显示*/}
                    <tr>
                        <th>是否显示:</th>
                        <th><Radio.Group
                            onChange={event => {
                                // tool.setStateSpace(columnPath, Object.assign(column, {hidden: event.target.value,}));
                                tool.setStateSpace(hiddenPath, event.target.value,);
                            }}
                            value={hidden}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Radio value={false}>显示</Radio>
                            <Radio value={true}>隐藏</Radio>
                        </Radio.Group></th>
                    </tr>

                    {/*第2行 修改字段名*/}
                    <tr>
                        <th>
                            修改字段名:
                        </th>
                        <th>

                            <input
                                defaultValue={column.title}
                                // value={column.title}
                                onBlur={event => {
                                    tool.setStateSpace(columnPath, Object.assign(column, {title: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第3行 字段类型*/}
                    <tr style={{border: border,}}>
                        <th>
                            字段类型:
                        </th>
                        <th>
                            <div>
                                <Select
                                    style={{width: '100%',}}
                                    // defaultValue={type}
                                    value={type}
                                    onChange={value => {
                                        tool.setStateSpace(columnPath, Object.assign(column, {type: value,}));
                                    }}
                                    options={[
                                        {
                                            value: 'bool',
                                            label: '布尔类型',
                                        },
                                        {
                                            value: 'char',
                                            label: '字符串(char)',
                                        },
                                        {
                                            value: 'text',
                                            label: '字符串(text)',
                                        },
                                        {
                                            value: 'file',
                                            label: '文件类型',
                                        },
                                        {
                                            value: 'multipleFile',
                                            label: '多文件类型',
                                        },
                                        {
                                            value: 'multipleChoice',
                                            label: '多项选择类型',
                                        },
                                        {
                                            value: 'singleSmallChoice',
                                            label: '单项选择(简单)',
                                        },
                                        {
                                            value: 'singSearchChoice',
                                            label: '单项选择(搜索)',
                                        },
                                        // {
                                        //     value: 'singDeepChoice',
                                        //     label: '单项选择(深度)',
                                        // },
                                        // {
                                        //     value: 'singNewDeepChoice',
                                        //     label: '单项选择(深度/新)',
                                        // },
                                        {
                                            value: 'imgPath',
                                            label: '图片类型(路径)',
                                        },
                                        {
                                            value: 'imgShow',
                                            label: '图片类型(展示)',
                                        },
                                        {
                                            value: 'imgHead',
                                            label: '图片类型(头像)',
                                        },
                                        {
                                            value: 'multipleImg',
                                            label: '图片类型(多项)',
                                        },
                                        // {
                                        //     value: 'dynamic',
                                        //     label: '动态类型',
                                        // },
                                        {
                                            value: 'time',
                                            label: '时间类型',
                                        },
                                        {
                                            value: 'timeYear',
                                            label: '时间类型(年份)',
                                        },
                                        {
                                            value: 'timeDay',
                                            label: '时间类型(日期)',
                                        },
                                        // {
                                        //     value: 'react',
                                        //     label: '渲染类型',
                                        // },
                                    ]}
                                />
                                <div style={{width: '100%',}}>
                                    {reactFieldAppend}
                                </div>

                            </div>
                        </th>
                    </tr>

                    {/*第4行 映射字段*/}
                    <tr>
                        <th>
                            修改映射字段:
                        </th>
                        <th>

                            <input
                                defaultValue={columnKey}
                                // value={columnKey}
                                onBlur={event => {
                                    let newColumnCreates = {};

                                    let columnsCreate = tool.getStateSpace(columnsCreatePath);
                                    for (const _columnKey in columnsCreate) {
                                        let useKey = _columnKey;
                                        if (_columnKey === columnKey) useKey = event.target.value;
                                        newColumnCreates[useKey] = columnsCreate[_columnKey];
                                    }
                                    tool.setStateSpace(columnsCreatePath, newColumnCreates);

                                    // tool.setStateSpace(columnPath, Object.assign(column, {title: event.target.value,}));
                                }}
                            />
                        </th>
                    </tr>

                    {/*第6行 位置*/}
                    <tr>
                        <th>
                            位置:
                        </th>
                        <th>
                            <Button
                                onClick={() => moveCreatePos(columnKey, -1)}
                                style={{width: '50%',}}>上移</Button>
                            <Button
                                onClick={() => moveCreatePos(columnKey, 1)}
                                style={{width: '50%',}}>下移</Button>
                        </th>
                    </tr>

                    </thead>
                </table>


            </div>);
        }
        // 增加字段
        reactColumns.push(<div key={'create_field'} style={{width: '100%',}}>
            <Divider/>
            <Button
                onClick={() => {
                    let columns = tool.getStateSpace(columnsCreatePath);
                    let newData = {};
                    let random = Math.random() * 100000;
                    random = Math.floor(random);
                    newData[`field_${random}`] = {title: '新字段',};
                    let newColumns = Object.assign(columns, newData);
                    tool.setStateSpace(columnsCreatePath, newColumns);
                }}
                style={{width: '100%',}}>增加字段</Button>
            <Divider/>
        </div>);

        // 新建框
        let isSetCreateColShowPath = infoModalPath.concat(['isSetCreateColShow']);
        let isSetCreateColShow = tool.getStateSpace(isSetCreateColShowPath, false);

        let content = <Modal
            // closeIcon={' '}
            visible={isSetCreateColShow}
            open={isSetCreateColShow}
            onCancel={() => tool.setStateSpace(isSetCreateColShowPath, false,)}
            footer={null}
        >
            <br/><br/>
            {reactColumns}
        </Modal>


        let react;
        react = <div>
            {content}
            <Button
                style={{width: '100%',}}
                onClick={() => tool.setStateSpace(isSetCreateColShowPath, true,)}
            ><ToolOutlined/>编辑</Button>
            <Divider/>
        </div>
        return react;
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Create') return null;
        if (this.isShowPart()) return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        let textTitlePath = spacePublicPath.concat(['text__create_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据创建';

        let content = this.getContent();
        let progress = this.getProgress();

        // 是否正在提交数据
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let stateCreateUpload = tool.getAttribute(spacePrivate, 'stateCreateUpload');
        let disabled = false;
        if (stateCreateUpload) disabled = true;
        let reactSetCreate = this.getReactSetCreate();
        return (

            <div>
                <Modal
                    title={textTitle}
                    open={true}
                    visible={true}
                    // okText={'提交'}
                    // cancelText={'关闭'}
                    onOk={() => this.onOk()}
                    onCancel={() => this.onCancel()}
                    footer={<div>
                        <Button onClick={() => this.onCancel()}>关闭</Button>
                        <Button
                            onClick={() => this.onOk()}
                            type={'primary'}
                            disabled={disabled}
                        >提交</Button>
                    </div>}
                >
                    {reactSetCreate}
                    {content}
                    {progress}
                </Modal>
            </div>
        );
    }
}

function mapStateToCreate(state) {
    return {
        infoModal: state.infoModal,
        setting: state.setting,
    }
}

CreateTable = connect(mapStateToCreate)(CreateTable);

export {CreateTable};